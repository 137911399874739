import styled from "styled-components";
import { Link } from 'react-router-dom';

export const MenuDarkoutPage = styled.div`
	width: 100%;
	height: 100%;
	z-index: 9;
	left: 0px;
	top: 0px;
	background: rgba(0,0,0,0.8);
	position: fixed;
`;

export const BlueButton = styled.button`
	background: ${({ theme }) => theme.medBlue};
	color: white;
	padding: 15px;
	border-radius: 5px;
	width: 125px;
	border: none;
	margin-top: 20px;
	margin-bottom: 10px;
	max-height: 42px;
	cursor: pointer;

	&:active {
		opacity: 0.75;
		transform: scale(0.98);
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
	}
`

export const WhiteButton = styled.button`
	background: white;
	color: ${({ theme }) => theme.medBlue};
	padding: 15px;
	border-radius: 5px;
	border: 3px solid ${({ theme }) => theme.medBlue};
	width: 125px;
	border: none;
	margin-top: 20px;

	&:hover {
		cursor: pointer;
	}

	&:active {
		opacity: 0.75;
		transform: scale(0.98);
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
	}
`

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
	border-radius: 5px;
	transform: scale(1.4);
	margin-right: 10px;
	accent-color: ${({ theme }) => theme.medBlue};
	cursor: pointer;
`

export const Bold = styled.span`
	font-weight: 900;
	font-size: inherit;
`

export const StyledLink = styled(Link)`
	color: grey;
`

export const Table = styled.table`
	border-collapse: collapse;
	max-width: 600px;
	text-align: left;
	margin-bottom: 15px;
`

export const TR = styled.tr`
	
`

export const TH = styled.th`
	font-weight: bold;
	border: 1px solid black;
`

export const TD = styled.td`
	border: 1px solid black;
`

export const TextInputWithButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 90%;

	img {
		margin-left: -30px;
		cursor: pointer;

			&:active {
			opacity: 0.75;
			transform: scale(0.98);
			box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
		}
	}
`