import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { DataState, LineupMode } from "../../../interfaces/enums";
import { UserLineup, UserLineups, LineupSelection } from "../../../interfaces/settings";
import { ChannelVisibleOrderInfo, EditLineupChannel, EditLineupChannels } from "../../../interfaces/channels";
import { getBaseUrl } from "../../Global/GobalFunctions";
import { newUserSettings } from "./userSlice";
import { consoleLog, errorLog } from "../../Global/ConsoleLogging";


export interface lineupsFavoritesReminders {
	lineupsStatus: DataState;
	userLineups: UserLineups;
	lineupSelection: LineupSelection;
	lineupSelectionStatus: DataState;
	putNewLineupStatus: DataState;
	deleteLineupStatus: DataState;
	lineupToBeEdited: UserLineup;
	lineupNameToEdit: string;
	lineupTimeZoneEdit: number;
	lineupDSTEdit: boolean;
	lineupChannelsToEdit: EditLineupChannels;
	lineupToEditId: string;
	lineupChannelsToEditStatus: DataState;
	lineupPageMode: LineupMode;
	lineupResetStatus: DataState;
	channelToEdit: EditLineupChannel;
	lineupChannelsToAdd: EditLineupChannels;
	lineupChannelsToAddStatus: DataState;
	addChannelStatus: DataState;
	bulkChannelEdit: ChannelVisibleOrderInfo[];
	channelResetStatus: DataState;
	newLineupId: string;
}

const initialState: lineupsFavoritesReminders = {
	lineupsStatus: DataState.IDLE,
	userLineups: {} as UserLineups,
	lineupSelection: {} as LineupSelection,
	lineupSelectionStatus: DataState.IDLE,
	putNewLineupStatus: DataState.IDLE,
	deleteLineupStatus: DataState.IDLE,
	lineupToBeEdited: {} as UserLineup,
	lineupNameToEdit: '',
	lineupTimeZoneEdit: 0,
	lineupDSTEdit: true,
	lineupChannelsToEdit: {} as EditLineupChannels,
	lineupToEditId: '85fbb169-0d0a-48a5-8e41-6aa60e71f0fa',
	lineupChannelsToEditStatus: DataState.IDLE,
	lineupPageMode: LineupMode.LIST,
	lineupResetStatus: DataState.IDLE,
	channelToEdit: {} as EditLineupChannel,
	lineupChannelsToAdd: {} as EditLineupChannels,
	lineupChannelsToAddStatus: DataState.IDLE,
	addChannelStatus: DataState.IDLE,
	bulkChannelEdit: [],
	channelResetStatus: DataState.IDLE,
	newLineupId: '',
};

const baseUrl = getBaseUrl();

/**
 * 
 * @param userID {string} the userID of for the Lineup List
 * @returns sets the lineups Array with all of the Lineups for the user
 */
export const fetchUserLineups = createAsyncThunk('lineups/fetchUserLineups', async (userID: string) => {
	return fetch(baseUrl + 'lineup/' + userID)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`User Lineup Error: : ${err}`, true))
});

export const fetchProviderByZipCode = createAsyncThunk('lineups/fetchProviderByZipCode', async (zipCode: string) => {
	const url = `${baseUrl}provider/${zipCode}`;
	return fetch(url)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`fetch Provider By ZIP Code Error : ${err}`, true))
})

export const putNewLineup = createAsyncThunk('lineups/putNewLineup', async (data: { lineupName: string, utcOffset: number, observesDST: boolean, providerId: number, deviceTypeCode: string, userId: string }) => {
	const url = `${baseUrl}lineup/${data.userId}`;
	let reqData = { lineupName: data.lineupName, utcOffset: data.utcOffset, observesDST: data.observesDST, providerId: data.providerId, deviceTypeCode: data.deviceTypeCode }
	const req = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Put Favorites error: ${err}`, true))
})

/**
 * {userId}/{lineupId}
 * @param url {userId}/{lineupId}
 * @returns deletes Lineup from server
 */
export const deleteLineup = createAsyncThunk('lineup/deleteLineup', async (data: { userId: string, lineupId: string }) => {
	const req = {
		method: 'DELETE'
	}
	return fetch(`${baseUrl}lineup/${data.userId}/${data.lineupId}`, req)
		.then(res => res)
		.catch((err) => errorLog(`Lineup Delete Error: ${err}`, true))
})

/**
 * {userId}/{lineupId}
 * @param url {userId}/{lineupId}
 * @returns deletes Lineup from server
 */
export const postUpdateLineup = createAsyncThunk('lineup/postUpdateLineup', async (data: { userId: string, lineupName: string, utcOffSet: number, observesDST: boolean, providerId: number, deviceTypeCode: string, lineupId: string }) => {
	const reqData = {
		lineupName: data.lineupName,
		utcOffset: data.utcOffSet,
		observesDST: data.observesDST,
		providerId: data.providerId,
		deviceTypeCode: data.deviceTypeCode,
		lineupId: data.lineupId
	}

	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(`${baseUrl}lineup/${data.userId}`, req)
		.then(res => res.status)
		.then(status => { return { status: status, reqData: reqData } })
		.catch((err) => errorLog(`Lineup Post Error: ${err}`, true))
})

export const fetchLineupChannelsForEdit = createAsyncThunk('lineup/fetchLineupChannelsForEdit', async (data: { userId: string, lineupId: string }) => {
	return fetch(`${baseUrl}customization/channel/${data.userId}/${data.lineupId}`)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Fetch Lineup Channels for Edit Error: ${err}`, true))
})

/**
 * 
 * @returns changes the visiablity of the channel in the grid
 */
export const postSingleChannelVisible = createAsyncThunk('lineup/postSingleChannelVisible', async (data: { userId: string, lineupId: string, originalRfChannel: number, channelId: number, isVisible: boolean }) => {
	const reqData = {
		channels: [
			{
				originalRfChannel: data.originalRfChannel,
				channelId: data.channelId,
				isVisible: data.isVisible
			}
		]
	}
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(`${baseUrl}customization/${data.userId}/${data.lineupId}/visibility`, req)
		.then(resData => resData.json())
		.then(resData => {
			const dataWithChannelId = {
				data: resData,
				channelId: data.channelId,
				originalRfChannel: data.originalRfChannel,
				isVisible: data.isVisible
			}
			return dataWithChannelId
		})
		.catch((err) => errorLog(`postSingleChannelVisible Error: ${err}`, true))
})

export const postBulkListVisible = createAsyncThunk('lineup/postBulkListVisible', async (data: { userId: string, lineupId: string, isVisible: boolean, bulkChannels: ChannelVisibleOrderInfo[] }) => {
	const reqData = {
		channels: [
			{

			}
		]
	}
	data.bulkChannels.map((c) => {
		const channel = {
			originalRfChannel: c.originalRfChannel,
			channelId: c.channelId,
			isVisible: data.isVisible
		}
		reqData.channels.push(channel);
	})
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(`${baseUrl}customization/${data.userId}/${data.lineupId}/visibility`, req)
		.then(resData => resData.json())
		.then(resData => { return resData })
		.catch((err) => errorLog(`postBulkListVisible Error: ${err}`, true))
})

/**
 * 
 * @returns changes the sort order of the channel in the grid
 */
export const postSingleChannelSortOrder = createAsyncThunk('lineup/postSingleChannelSortOrder', async (data: { userId: string, lineupId: string, originalRfChannel: number, channelId: number, sortOrder: number }) => {
	const reqData = {
		channels: [
			{
				originalRfChannel: data.originalRfChannel,
				channelId: data.channelId,
				sortOrder: data.sortOrder
			}
		]
	}

	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(`${baseUrl}customization/${data.userId}/${data.lineupId}/sort`, req)
		.then(res => res.json())
		.then(resData => {
			let returnData = {
				resData: resData,
				channelId: data.channelId,
				originalRfChannel: data.originalRfChannel,
				sortOrder: data.sortOrder
			}
			return returnData;
		})
		.catch((err) => errorLog(`postSingleChannelSort Error: ${err}`, true))
})

export const deleteLineupCustomizations = createAsyncThunk('lineup/deleteLineupCustomizations', async (data: { userId: string, lineupId: string }) => {
	const req = {
		method: 'DELETE'
	}
	return fetch(`${baseUrl}customization/${data.userId}/${data.lineupId}`, req)
		.then(res => res.json())
		.then(res => {
			return {
				res,
				userId: data.userId,
				lineupId: data.lineupId
			}
		})
		.catch((err) => errorLog(`Lineup Delete Error: ${err}`, true))
})

export const searchForChannelToAdd = createAsyncThunk('lineup/searchForChannelToAdd', async (data: { searchText: string, maxResults: number }) => {

	return fetch(`${baseUrl}customization/channel/search/${data.searchText}/${data.maxResults}`)
		.then(res => res.json())
		.then(res => { return res })
		.catch((err) => errorLog(`Lineup Search Error: ${err}`, true))
})

export const fetchLineupChannelsToAdd = createAsyncThunk('lineup/fetchLineupChannelsToAdd', async (data: { userId: string, lineupId: string }) => {

	return fetch(`${baseUrl}customization/channel/${data.userId}/${data.lineupId}`)
		.then(res => res.json())
		.then(res => { return res })
		.catch((err) => errorLog(`Lineup Delete Error: ${err}`, true))
})

export const postUpdateChannelInfo = createAsyncThunk('lineup/updateChannelInfo', async (arg: { userId: string, lineupId: string, channel: EditLineupChannel }) => {
	const reqData = arg.channel
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(`${baseUrl}customization/channel/${arg.userId}/${arg.lineupId}`, req)
		.then(resData => resData.json())
		.then(resData => { return arg.channel })
		.catch((err) => errorLog(`postUpdateChannelInfo Error: ${err}`, true))
})

export const postAddChannel = createAsyncThunk('lineup/postAddNewChannel', async (arg: { userId: string, lineupId: string, channel: EditLineupChannel, newChannelSort: number }) => {
	const reqData = {
		channelId: arg.channel.channelId,
		originalRfChannel: arg.channel.originalRfChannel,
		rfChannel: arg.channel.rfChannel,
		majorChannel: arg.channel.majorChannel,
		minorChannel: arg.channel.minorChannel,
		callSign: arg.channel.callSign,
		description: arg.channel.description,
		network: arg.channel.network,
		sortOrder: arg.newChannelSort,
		providerTypeId: arg.channel.providerTypeId,
		lineupAction: 'A',
		errorCode: arg.channel.errorCode,
		errorMessage: arg.channel.errorMessage
	}
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(`${baseUrl}customization/channel/${arg.userId}/${arg.lineupId}`, req)
		.then(resData => resData.json())
		.then(resData => {
			resData.channel = arg.channel
			return resData
		})
		.catch((err) => errorLog(`postAddNewChannel Error: ${err}`, true))
})

export const putTokenLineup = createAsyncThunk('lineup/putTokenLineup', async (data: { userId: string, lineupToken: string }) => {
	const url = `${baseUrl}lineup/${data.userId}/token`;
	const reqData = {
		shareToken: data.lineupToken
	}
	const req = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Put Token Lineup Error: ${err}`, true))
})

export const putStationTokenLineup = createAsyncThunk('lineup/putStationTokenLineup', async (data: { userId: string, lineupToken: string, name: string }) => {
	const url = `${baseUrl}lineup/${data.userId}/import`;
	const reqData = {
		name: data.name,
		stationToken: data.lineupToken
	}
	const req = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Put Station Token Lineup Error: ${err}`, true))
})

export const putStationTokenLineupNewUser = createAsyncThunk('user/putStationTokenLineupNewUser', async (arg: { settings: newUserSettings, token: string }) => {
	return fetch(baseUrl + 'user/guest')
		.then(res => res.json())
		.then(data => {
			const userId = data.userId
			const lineupUrl = `${baseUrl}lineup/${userId}/import`
			const reqData = {
				name: 'Antennaweb',
				stationToken: arg.token
			}
			const req = {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(reqData),
			}
			return fetch(lineupUrl, req)
				.then(res => res.json())
				.then(
					data => {
						arg.settings.defaultLineupId = data.lineupId
						arg.settings.gridSettings.fixedCellHeight = true;
						arg.settings.gridSettings.gridStartHour = -1

						const settingsUrl = `${baseUrl}preference/${userId}`;
						const req = {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json'
							},
							body: JSON.stringify(arg.settings),
						}
						return fetch(settingsUrl, req)
							.then(res => res.json())
							.then(data => {
								localStorage.userId = userId;
								return data
							})
							.catch((err) => errorLog(`Fetch New User Error, Update Settings: ${err}`, true))
					}
				)
				.catch((err) => errorLog(`Fetch New User Error, New Lineup: ${err}`, true))
		})
		.catch((err) => errorLog(`Fetch New User Error: ${err}`, true))
});

export const postResetChannels = createAsyncThunk('lineup/postRestChannels', async (arg: { userId: string, lineupId: string, channels: ChannelVisibleOrderInfo[] }) => {
	const reqData = {
		channels: arg.channels
	}
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(`${baseUrl}customization/${arg.userId}/${arg.lineupId}/reset`, req)
		.then(resData => resData.json())
		.then(resData => { return resData })
		.catch((err) => errorLog(`postRestChannels Error: ${err}`, true))
})

export const lineupsSlice = createSlice({
	name: 'lineupsSlice',
	initialState,
	reducers: {
		setLineupSelectionStatus: (state, action) => {
			state.lineupSelectionStatus = action.payload
		},
		setNewLineupStatus: (state, action) => {
			state.putNewLineupStatus = action.payload
		},
		setDeleteLineupStatus: (state, action) => {
			state.deleteLineupStatus = action.payload
		},
		setLineupToBeEdited: (state, action) => {
			state.lineupToBeEdited = action.payload;
			state.lineupNameToEdit = state.lineupToBeEdited.lineupName;
			state.lineupTimeZoneEdit = state.lineupToBeEdited.utcOffset;
			state.lineupDSTEdit = state.lineupToBeEdited.observesDst;
		},
		setLineupNameToEdit: (state, action) => {
			state.lineupNameToEdit = action.payload;
		},
		setLineupTimeZoneEdit: (state, action) => {
			state.lineupTimeZoneEdit = action.payload;
		},
		setLineupDSTEdit: (state, action) => {
			state.lineupDSTEdit = action.payload;
		},
		setLineupPageMode: (state, action) => {
			state.lineupPageMode = action.payload;
		},
		setLineupChannelsToEditStatus: (state, action) => {
			state.lineupChannelsToEditStatus = action.payload
		},
		setLineupToEditId: (state, action) => {
			state.lineupToEditId = action.payload
		},
		setLineupResetStatus: (state, action) => {
			state.lineupResetStatus = action.payload
		},
		setChannelToEdit: (state, action) => {
			state.channelToEdit = action.payload
		},
		setLineupChannelsToAddStatus: (state, action) => {
			state.lineupChannelsToAddStatus = action.payload
		},
		setRFChannel: (state, action) => {
			state.channelToEdit.rfChannel = action.payload;
		},
		setMajorChannel: (state, action) => {
			state.channelToEdit.majorChannel = action.payload;
		},
		setMinorChannel: (state, action) => {
			state.channelToEdit.minorChannel = action.payload;
		},
		setChannelCallsign: (state, action) => {
			state.channelToEdit.callSign = action.payload;
		},
		setChannelNetwork: (state, action) => {
			state.channelToEdit.network = action.payload;
		},
		setChannelSource: (state, action) => {
			state.channelToEdit.providerTypeId = action.payload;
		},
		setAddChannelStatus: (state, action) => {
			state.addChannelStatus = action.payload
		},
		setAddChannelToBulkList: (state, action) => {
			state.bulkChannelEdit.push(action.payload);
		},
		setRemoveChannelFromBulkList: (state, action) => {
			state.bulkChannelEdit = state.bulkChannelEdit.filter((c) => {
				return (c.channelId !== action.payload.channelId && c.originalRfChannel !== action.payload.originalRfChannel)
			})
		},
		setAllChannelsToBulkList: (state) => {
			state.lineupChannelsToEdit.channels.map((c) => {
				state.bulkChannelEdit.push({ channelId: c.channelId, originalRfChannel: c.originalRfChannel });
			})
		},
		setBulkListEmpty: (state) => {
			state.bulkChannelEdit = [];
		},
		setBulkChannelEdit: (state, action) => {
			state.lineupChannelsToEdit.channels.map((c) => {
				if (c.lineupAction === action.payload) {
					state.bulkChannelEdit.push({ channelId: c.channelId, originalRfChannel: c.originalRfChannel });
				}
			})
		},
		setChannelResetStatus: (state, action) => {
			state.channelResetStatus = action.payload
		},
	},
	extraReducers: builder => {

		//#region fetchLineups
		builder.addCase(fetchUserLineups.pending, (state) => {
			state.lineupsStatus = DataState.LOADING;
		})
		builder.addCase(fetchUserLineups.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.lineupsStatus = DataState.FAILED;
			} else {
				state.userLineups = action.payload;
				state.lineupsStatus = DataState.SUCCEEDED;
			}
		})
		builder.addCase(fetchUserLineups.rejected, (state) => {
			state.lineupsStatus = DataState.FAILED;
		})
		//#endregion

		//#region fetchProvidersByZipCode
		builder.addCase(fetchProviderByZipCode.pending, (state) => {
			state.lineupSelectionStatus = DataState.LOADING
		})
		builder.addCase(fetchProviderByZipCode.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.lineupSelectionStatus = DataState.FAILED
			} else {
				state.lineupSelectionStatus = DataState.SUCCEEDED
				state.lineupSelection = action.payload;
			}
		})
		builder.addCase(fetchProviderByZipCode.rejected, (state) => {
			state.lineupSelectionStatus = DataState.FAILED
		})
		//#endregion

		//#region putNewLineup
		builder.addCase(putNewLineup.pending, (state) => {
			state.putNewLineupStatus = DataState.LOADING
		})
		builder.addCase(putNewLineup.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.putNewLineupStatus = DataState.FAILED
			} else {
				state.userLineups.lineups.push(action.payload);
				state.putNewLineupStatus = DataState.SUCCEEDED
				//fetchUserLineups(localStorage.userId);
			}
		})
		builder.addCase(putNewLineup.rejected, (state) => {
			state.putNewLineupStatus = DataState.FAILED
		})
		//#endregion

		//#region deleteLineup
		builder.addCase(deleteLineup.pending, (state) => {
			state.deleteLineupStatus = DataState.LOADING
		})
		builder.addCase(deleteLineup.fulfilled, (state) => {
			state.deleteLineupStatus = DataState.SUCCEEDED
			fetchUserLineups(sessionStorage.userId);
		})
		builder.addCase(deleteLineup.rejected, (state) => {
			state.deleteLineupStatus = DataState.FAILED
			alert('An error occurred, Lineup was not deleted')
		})
		//#endregion

		//#region postUpdateLineup
		builder.addCase(postUpdateLineup.pending, (state) => {

		})
		builder.addCase(postUpdateLineup.fulfilled, (state, action) => {
			let lineups = state.userLineups.lineups
			if (action.payload) {
				for (let i = 0; i < lineups.length; i++) {
					if (lineups[i].lineupId === action.payload.reqData.lineupId) {
						let data = action.payload?.reqData
						lineups[i].lineupName = data.lineupName;
						lineups[i].utcOffset = data.utcOffset;
						lineups[i].observesDst = data.observesDST;
						break;
					}
				}
			}
		})
		builder.addCase(postUpdateLineup.rejected, (state) => {

			alert('An error occurred, Lineup was not updated');
		})
		//#endregion

		//#region fetchLineupChannelsForEdit
		builder.addCase(fetchLineupChannelsForEdit.pending, (state) => {
			state.lineupChannelsToEditStatus = DataState.LOADING
		})
		builder.addCase(fetchLineupChannelsForEdit.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.lineupChannelsToEditStatus = DataState.FAILED
			} else {
				state.lineupChannelsToEdit = action.payload;
				state.lineupChannelsToEditStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(fetchLineupChannelsForEdit.rejected, (state) => {
			state.lineupChannelsToEditStatus = DataState.FAILED
		})
		//#endregion

		//#region postSingleChannelVisible
		builder.addCase(postSingleChannelVisible.pending, (state) => {

		})
		builder.addCase(postSingleChannelVisible.fulfilled, (state, action) => {
			if (action.payload === undefined) {

			} else {
				const channels = state.lineupChannelsToEdit.channels
				for (let i = 0; i < state.lineupChannelsToEdit.channels.length; i++) {
					if (channels[i].channelId === action.payload.channelId && channels[i].originalRfChannel === action.payload.originalRfChannel) {
						if (action.payload.isVisible) {
							channels[i].lineupAction = 'O'
						} else {
							channels[i].lineupAction = 'D'
						}
						break;
					}
				}
			}
		})
		builder.addCase(postSingleChannelVisible.rejected, (state) => {

		})
		//#endregion

		//#region postSingleChannelSortOrder
		builder.addCase(postSingleChannelSortOrder.pending, (state) => {

		})
		builder.addCase(postSingleChannelSortOrder.fulfilled, (state, action) => {
			if (action.payload === undefined) {

			} else {
				const channels = state.lineupChannelsToEdit.channels
				const channel = action.payload.resData.channels[0]
				for (let i = 0; i < channels.length; i++) {
					if (channels[i].channelId === channel.channelId && channels[i].originalRfChannel === action.payload.originalRfChannel) {
						channels[i].lineupAction = channel.lineupAction
						channels[i].sortOrder = channel.sortOrder
						break;
					}
				}
				// sort channel list
				channels.sort((a: EditLineupChannel, b: EditLineupChannel) => {
					return a.sortOrder - b.sortOrder;
				})
			}
		})
		builder.addCase(postSingleChannelSortOrder.rejected, (state) => {

		})
		//#endregion

		//#region deleteLineupCustomizations
		builder.addCase(deleteLineupCustomizations.pending, (state) => {
			state.lineupResetStatus = DataState.LOADING
		})
		builder.addCase(deleteLineupCustomizations.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.lineupResetStatus = DataState.FAILED
			} else {
				state.lineupResetStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(deleteLineupCustomizations.rejected, (state) => {
			state.lineupResetStatus = DataState.FAILED
		})
		//#endregion

		//#region searchForChannelToAdd
		builder.addCase(searchForChannelToAdd.pending, (state) => {
			state.lineupChannelsToAddStatus = DataState.LOADING
		})
		builder.addCase(searchForChannelToAdd.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.lineupChannelsToAddStatus = DataState.FAILED
				state.lineupChannelsToAdd = action.payload;
			} else {
				state.lineupChannelsToAdd = action.payload;
				state.lineupChannelsToAddStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(searchForChannelToAdd.rejected, (state) => {
			state.lineupChannelsToAddStatus = DataState.FAILED
		})
		//#endregion

		//#region fetchLineupChannelsToAdd
		builder.addCase(fetchLineupChannelsToAdd.pending, (state) => {
			state.lineupChannelsToAddStatus = DataState.LOADING
		})
		builder.addCase(fetchLineupChannelsToAdd.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.lineupChannelsToAddStatus = DataState.FAILED
				state.lineupChannelsToAdd = action.payload;
			} else {
				state.lineupChannelsToAdd = action.payload;
				state.lineupChannelsToAddStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(fetchLineupChannelsToAdd.rejected, (state, action) => {
			state.lineupChannelsToAddStatus = DataState.FAILED

		})
		//#endregion

		//#region postUpdateChannelInfo
		builder.addCase(postUpdateChannelInfo.pending, (state) => {

		})
		builder.addCase(postUpdateChannelInfo.fulfilled, (state, action) => {
			if (action.payload === undefined) {

			} else {
				let channels = state.lineupChannelsToEdit.channels
				for (let i = 0; i < channels.length; i++) {
					if (channels[i].channelId === action.payload.channelId) {
						state.lineupChannelsToEdit.channels[i].rfChannel = action.payload.rfChannel
						state.lineupChannelsToEdit.channels[i].majorChannel = action.payload.majorChannel
						state.lineupChannelsToEdit.channels[i].minorChannel = action.payload.minorChannel
						state.lineupChannelsToEdit.channels[i].callSign = action.payload.callSign
						state.lineupChannelsToEdit.channels[i].network = action.payload.network
						state.lineupChannelsToEdit.channels[i].providerTypeId = action.payload.providerTypeId
						break;
					}
				}
			}
		})
		builder.addCase(postUpdateChannelInfo.rejected, (state, action) => {

		})
		//#endregion

		//#region postAddNewChannel
		builder.addCase(postAddChannel.pending, (state) => {
			state.addChannelStatus = DataState.LOADING
		})
		builder.addCase(postAddChannel.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.addChannelStatus = DataState.FAILED
			} else {
				state.addChannelStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(postAddChannel.rejected, (state, action) => {
			state.addChannelStatus = DataState.FAILED
			alert('Channel not added, try again later')
		})
		//#endregion

		//#region postBulkListVisible
		builder.addCase(postBulkListVisible.pending, (state) => {

		})
		builder.addCase(postBulkListVisible.fulfilled, (state, action) => {
			if (action.payload === undefined) {

			} else {
				const channels = state.lineupChannelsToEdit.channels
				action.payload.channels.map((c: EditLineupChannel) => {
					for (let i = 0; i < channels.length; i++) {
						if (c.channelId === channels[i].channelId && c.originalRfChannel === channels[i].originalRfChannel) {
							channels[i].lineupAction = c.lineupAction;
							break;
						}
					}
				})
				state.bulkChannelEdit = [];
			}
		})
		builder.addCase(postBulkListVisible.rejected, (state, action) => {

		})
		//#endregion

		//#region putTokenLineup
		builder.addCase(putTokenLineup.pending, (state) => {
			state.putNewLineupStatus = DataState.LOADING
		})
		builder.addCase(putTokenLineup.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				alert('Lineup not added, try again later')
			} else {
				state.putNewLineupStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(putTokenLineup.rejected, (state, action) => {
			state.putNewLineupStatus = DataState.FAILED
			alert('Lineup not added, try again later')
		})
		//#endregion

		//#region putStationTokenLineup
		builder.addCase(putStationTokenLineup.pending, (state) => {
			state.putNewLineupStatus = DataState.LOADING
		})
		builder.addCase(putStationTokenLineup.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				alert('Lineup not added, try again later')
			} else {
				state.userLineups.lineups.push(action.payload)
				state.newLineupId = action.payload.lineupId;
				state.putNewLineupStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(putStationTokenLineup.rejected, (state, action) => {
			state.putNewLineupStatus = DataState.FAILED
			alert('Lineup not added, try again later')
		})
		//#endregion

		//#region putStationTokenLineupNewUser
		builder.addCase(putStationTokenLineupNewUser.pending, (state) => {
			state.putNewLineupStatus = DataState.LOADING
		})
		builder.addCase(putStationTokenLineupNewUser.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				alert('Lineup not added, try again later')
			} else {
				state.putNewLineupStatus = DataState.SUCCEEDED
				window.location.assign("../../");
			}
		})
		builder.addCase(putStationTokenLineupNewUser.rejected, (state, action) => {
			state.putNewLineupStatus = DataState.FAILED
			alert('Lineup not added, try again later')
		})
		//#endregion

		//#region postRestChannels
		builder.addCase(postResetChannels.pending, (state) => {
			state.channelResetStatus = DataState.LOADING
		})
		builder.addCase(postResetChannels.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.channelResetStatus = DataState.FAILED
			} else {
				state.channelResetStatus = DataState.SUCCEEDED
				state.bulkChannelEdit = []
			}
		})
		builder.addCase(postResetChannels.rejected, (state, action) => {
			state.channelResetStatus = DataState.FAILED
		})
		//#endregion
	},
});

export const {
	setLineupSelectionStatus, setNewLineupStatus, setDeleteLineupStatus, setLineupToBeEdited, setLineupNameToEdit,
	setLineupTimeZoneEdit, setLineupDSTEdit, setLineupPageMode, setLineupChannelsToEditStatus, setLineupToEditId,
	setLineupResetStatus, setChannelToEdit, setLineupChannelsToAddStatus, setRFChannel, setMajorChannel, setMinorChannel,
	setChannelCallsign, setChannelNetwork, setChannelSource, setAddChannelStatus, setAddChannelToBulkList,
	setRemoveChannelFromBulkList, setAllChannelsToBulkList, setBulkListEmpty, setBulkChannelEdit, setChannelResetStatus
} = lineupsSlice.actions;

export const selectLineups = (state: RootState) => state.lineupsSlice.userLineups;
export const selectLineupsStatus = (state: RootState) => state.lineupsSlice.lineupsStatus;

export const selectLineupSelection = (state: RootState) => state.lineupsSlice.lineupSelection;
export const selectLineupSelectionStatus = (state: RootState) => state.lineupsSlice.lineupSelectionStatus;

export const selectNewLineupStatus = (state: RootState) => state.lineupsSlice.putNewLineupStatus;
export const selectDeleteLineupStatus = (state: RootState) => state.lineupsSlice.deleteLineupStatus;

export const selectLineupToBeEdited = (state: RootState) => state.lineupsSlice.lineupToBeEdited;
export const selectLineupNameToEdit = (state: RootState) => state.lineupsSlice.lineupNameToEdit;
export const selectLineupTimeZoneEdit = (state: RootState) => state.lineupsSlice.lineupTimeZoneEdit;
export const selectLineupDSTEdit = (state: RootState) => state.lineupsSlice.lineupDSTEdit;

export const selectlineupChannelsToEdit = (state: RootState) => state.lineupsSlice.lineupChannelsToEdit;
export const selectLineupToEditId = (state: RootState) => state.lineupsSlice.lineupToEditId;
export const selectLineupChannelsToEditStatus = (state: RootState) => state.lineupsSlice.lineupChannelsToEditStatus;
export const selectLineupMode = (state: RootState) => state.lineupsSlice.lineupPageMode;
export const selectLineupResetStatus = (state: RootState) => state.lineupsSlice.lineupResetStatus;
export const selectChannelToEdit = (state: RootState) => state.lineupsSlice.channelToEdit;
export const selectLineupChannelsToAddStatus = (state: RootState) => state.lineupsSlice.lineupChannelsToAddStatus;
export const selectLineupChannelsToAdd = (state: RootState) => state.lineupsSlice.lineupChannelsToAdd;
export const selectAddChannelStatus = (state: RootState) => state.lineupsSlice.addChannelStatus;
export const selectBulkChannelEdit = (state: RootState) => state.lineupsSlice.bulkChannelEdit;
export const selectChannelResetStatus = (state: RootState) => state.lineupsSlice.channelResetStatus;
export const selectNewLineupId = (state: RootState) => state.lineupsSlice.newLineupId;

export default lineupsSlice.reducer;