import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import {
	selectFontSize, selectGenreDisplay, selectHDIndicator, selectMovieRating, selectNewRepeat, selectSeasonNumber, selectTvRating,
	selectYearProduction
} from '../../UserSettingsPages/SettingsState/gridSettingsSlice'
import { Event } from '../../../interfaces/schedule'
import {
	EventDisplayDetails, ShowHD
} from './GridStyles'
import { fontSizeCalculation } from '../../Global/GobalFunctions'

const EventAttributes = (props: any) => {
	const event: Event = props.Event
	const showAirDate: boolean = props.showAirDate;
	const hdIndicator = useAppSelector(selectHDIndicator);
	const showDisplayGenre = useAppSelector(selectGenreDisplay);
	const showYearProd = useAppSelector(selectYearProduction);
	const showTvRating = useAppSelector(selectTvRating);
	const showMovieRating = useAppSelector(selectMovieRating);
	const fontSizeChange = useAppSelector(selectFontSize);
	const newRepeatStatus = useAppSelector(selectNewRepeat);
	const showSeasonNumber = useAppSelector(selectSeasonNumber);

	const fontSize = fontSizeCalculation(parseFloat(fontSizeChange));

	const firstTVShow = 1928;
	let airDateString = ''
	if (showAirDate) {
		const airDate = new Date(event.originalAirDate);
		if (event.programType !== 'Movie') {
			airDateString = airDate.getFullYear() >= firstTVShow ? (', ' + (airDate.getMonth() + 1)) + '/' + airDate.getDate() + '/' + airDate.getFullYear() : ''
		} else {
			const date = new Date(event.originalAirDate);
			airDateString = ' ' + date.getFullYear();
		}
	} else {
		airDateString = ''
	}

	const newRepeat = () => {
		let newRepeatString = '';
		if (newRepeatStatus) {
			if (!event.newRepeat) { }
			if (event.newRepeat === 'N') { newRepeatString = 'New '; }
			if (event.newRepeat === 'R') { newRepeatString = 'Repeat ' }
		}
		return newRepeatString;
	}

	let seasonEps = event.seasonNumber > 0 && showSeasonNumber ? ', S' + event.seasonNumber + '/' : ''
	seasonEps = event.seasonEpisodeNumber > 0 ? seasonEps + 'E' + event.seasonEpisodeNumber : seasonEps + '';

	useEffect(() => {
		// if (!event.isHD && event.newRepeat === '' && seasonEps === '' && !event.mpaaRating && !event.tvRating) {
		// 	setDisplayParentheses(false)
		// }
		// if (!showAirDate && !hdIndicator && !showMovieRating && !newRepeatStatus && !showDisplayGenre &&
		// 	!showYearProd && !showSeasonNumber && !showTvRating) {
		// 	setDisplayParentheses(false)
		// }
	}, [])

	return (
		<EventDisplayDetails fontSize={fontSize + 'px'}>
			{(event.isHD && hdIndicator) ? <ShowHD>HD, </ShowHD> : ''}
			{newRepeat()}
			{(showDisplayGenre) ? event.displayGenre : ''}
			{showYearProd ? airDateString : ''}
			{showSeasonNumber ? seasonEps : ''}
			{(event.tvRating && showTvRating) ? ', ' + event.tvRating : ''}
			{(event.mpaaRating && showMovieRating) ? ', ' + event.mpaaRating : ''}
		</EventDisplayDetails>
	)
}

export default EventAttributes