// #region imports
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { DataState, ModalState } from '../../../interfaces/enums'
import GridLoading from '../../GridPages/components/GridLoading'
import {
	fetchLineupChannels, selectGridLineupID, selectLineupChannels, selectMainGridChannelStatus
} from '../../GridPages/mainGridSlice';
import { selectIsAnonymous, selectUserId } from '../SettingsState/userSlice';
import {
	SettingsContainer, Understanding, FavoriteContent, FavoriteContainer, FavoriteTitle, Favorite24Hours, FavoritesAllContainer,
	FavoriteUpcomingNone, StyledUpcomingFavoriteBox, UpcomingTimeDate, UpcomingChannelNumber, UpcomingEvent, UpcomingTitle,
	UpcomingEpisodeTitle, UpcomingInnerBox, SettingsHeader, BecomeMemberContainer, FavoriteHeader, FavoriteUpcomingContainer,
} from '../components/SettingsStyles'
import TabBar from '../components/TabBar'
import {
	deleteFavorite, fetchFavorites, fetchUpcomingFavorites, selectFavorites, selectFavoritesStatus, selectUpcomingFavorites, selectUpcomingFavoritesStatus,
	selectFavoriteDeleteStatus, setFavoriteDeleteId, setFavoriteDeleteStatus
} from '../SettingsState/favoritesRemidersSlice'
import Close from '../../../img/Icons/Red Icons/TitanTV Icon_Close_Red.png';
import { ModalIconSM } from '../../Modal/ModalStyles'
import { Favorite, UpcomingFavorite } from '../../../interfaces/settings'
import GridFailed from '../../GridPages/components/GridFailed'
import { CreateUUID, GetDefaultUser, eventFlyOverText, leadingZeroBuilder } from '../../Global/GobalFunctions'
import { Channel } from '../../../interfaces/channels';
import { Event } from '../../../interfaces/schedule';
import { selectColors } from '../../GridPages/gridColorsSlice'
import { ClickableChannelCard } from '../../Global/GobalComponents'
import { fetchEventDetails, setDetailsChannel } from '../../GridPages/detailsSlice'
import { setModalContent, setModalOpen } from '../../Modal/modalSlice'
import { DataDeleted } from '../components/SettingsStyles';
import BecomeMemberButton from '../components/BecomeMemberButton';
import EventAttributes from '../../GridPages/components/EventAttributes';
import BackToGridButton from '../components/BackToGridButton';
// #endregion

export interface Upcoming {
	channel: Channel;
	event: Event;
}

const MyFavorites = () => {
	const favoritesLoading = useAppSelector(selectFavoritesStatus);
	const favorites = useAppSelector(selectFavorites);
	const upcomingFav = useAppSelector(selectUpcomingFavorites);
	const upcomingStatus = useAppSelector(selectUpcomingFavoritesStatus);
	const channelStatus = useAppSelector(selectMainGridChannelStatus);
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const isAnon = useAppSelector(selectIsAnonymous);
	const deleteStatus = useAppSelector(selectFavoriteDeleteStatus);
	const dispatch = useAppDispatch();

	useEffect(() => {
		window.scrollTo(0, 0);
		if (channelStatus !== DataState.SUCCEEDED) {
			dispatch(fetchLineupChannels({ userId: userId, lineupId: lineupId }));
		}
		let now = new Date();
		now.setMinutes(0);
		now.setHours(now.getHours() + 1);
		let startTimeToken = now.getFullYear() + leadingZeroBuilder(now.getMonth() + 1) + leadingZeroBuilder(now.getDate()) + leadingZeroBuilder(now.getHours()) + '00'
		dispatch(fetchUpcomingFavorites({ userId: userId, lineupId: lineupId, startTime: startTimeToken, duration: 1440 }));
		dispatch(fetchFavorites(userId));
		dispatch(setFavoriteDeleteStatus(DataState.IDLE));
	}, [])

	const handleDelete = (fav: Favorite) => {
		dispatch(setFavoriteDeleteId(fav.favoriteId));
		dispatch(deleteFavorite({ userId: userId, favId: fav.favoriteId }));
	}

	return (
		<>
			<TabBar />
			<BackToGridButton />
			<SettingsContainer>
				{userId === GetDefaultUser() ?
					<BecomeMemberContainer>
						<SettingsHeader>You must Create a user to have favorites</SettingsHeader>
						<BecomeMemberButton />
					</BecomeMemberContainer> :
					<FavoriteContainer>
						{(favoritesLoading === DataState.FAILED || upcomingStatus === DataState.FAILED || channelStatus === DataState.FAILED) ? <GridFailed errorCode={favorites.errorCode} errorMessage={favorites.errorMessage} /> :
							(favoritesLoading === DataState.LOADING || upcomingStatus === DataState.LOADING || channelStatus === DataState.LOADING) ? <GridLoading /> :
								<>
									<Favorite24Hours>
										<FavoriteHeader>
											Favorite Programs Airing in the Next 24 Hours
										</FavoriteHeader>
										{(upcomingFav.events === undefined) ? '' :
											(upcomingFav.events.length < 1) ? <FavoriteUpcomingNone>No Upcoming Favorites</FavoriteUpcomingNone> :
												<UpcomingInnerBox>
													<FavoriteUpcomingContainer>
														{upcomingFav.events.map((fav) =>
															<UpcomingFavoriteBox fav={fav} key={`UCF${CreateUUID()}`} />
														)}
													</FavoriteUpcomingContainer>
												</UpcomingInnerBox>
										}
									</Favorite24Hours>
									<FavoriteHeader>
										Programs and Movies Selected as Favorites - Maximun {isAnon ? '200' : '500'}
									</FavoriteHeader>
									<FavoritesAllContainer>

										{favorites.favorites.length < 1 ? <FavoriteUpcomingNone>No Favorites</FavoriteUpcomingNone> :
											favorites.favorites.map((fav, i) =>
												<FavoriteContent key={fav.favoriteId}>
													<FavoriteTitle>{fav.title}</FavoriteTitle>
													<ModalIconSM src={Close} alt='Remove Favorite' title={'Remove ' + fav.title + ' from list'} onClick={() => handleDelete(fav)} />
												</FavoriteContent>
											)}
										{deleteStatus !== DataState.SUCCEEDED ? '' : <DataDeleted>Favorite Deleted</DataDeleted>}
									</FavoritesAllContainer>
								</>
						}
					</FavoriteContainer>
				}
				<Understanding>
					<span>
						<b>About This Page:</b>
						<p>This page maintains the list of programs and movies you have selected as a Favorite.</p>
						<p>Visitors may select up to 200 programs and movies as a Favorite. TitanTV members may select a maximum of 500.</p>
						<p>A program or movie may also be selected as a Favorite in the schedule grid by clicking the Favorite icon in its Details page.</p>
						<p>Programs and movies that have been selected as a Favorite appear in the schedule with a red heart in the top right corner of its cell.</p>
						<p>Favorite programs that air within the next 24 hours will appear in the ticker tape area on the header above the grid.</p>
						<p>To delete a Favorite, click on the red X on the right side of its box in this list.</p>
						<p>Click the TitanTV logo to return to the schedule page.</p>
					</span>
				</Understanding>
			</SettingsContainer>
		</>
	)
}

export default MyFavorites

const UpcomingFavoriteBox = (props: any) => {

	const event: UpcomingFavorite = props.fav;
	const channels = useAppSelector(selectLineupChannels);
	let channel: Channel = {} as Channel;
	const dispatch = useAppDispatch();
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID)
	const now = new Date();
	let todayTomorrow = 'Today';
	let AMPM = 'AM';
	let startTime = new Date(event.startTime);
	let hour = startTime.getHours();
	let min = leadingZeroBuilder(startTime.getMinutes());
	const { GridColors } = useAppSelector(selectColors);
	type key = keyof typeof GridColors;
	let genre = event.displayGenre;

	if (genre === 'Game/Quiz') { genre = 'Game_Quiz' }

	if (startTime.getDate() === now.getDate() + 1) {
		todayTomorrow = 'Tomorrow';
	}
	if (startTime.getHours() > 11) {
		AMPM = 'PM';
	}
	if (hour === 0) { hour = 12; }
	if (hour > 12) { hour = hour - 12; }

	if (channels.lineupCacheId) {
		for (let i = 0; i < channels.channels.length; i++) {
			if (channels.channels[i].channelIndex === event.channelIndex) {
				channel = channels.channels[i];
				break;
			}
		}
	} else {
		dispatch(fetchLineupChannels({ userId: userId, lineupId: lineupId }));
	}

	const handleEventCardClick = (userId: string, lineupId: string, channel: Channel, eventId: number) => {
		dispatch(setDetailsChannel(channel))
		dispatch(fetchEventDetails({ userId: userId, lineupId: lineupId, channelIndex: channel.channelIndex, eventId }));
		dispatch(setModalContent(ModalState.EVENTDETAILS));
		dispatch(setModalOpen());
	}

	return (
		<StyledUpcomingFavoriteBox>
			<UpcomingTimeDate>
				<div>{todayTomorrow}</div>
				<div>{`${hour}:${min} ${AMPM}`}</div>
			</UpcomingTimeDate>
			<ClickableChannelCard channel={channel} />
			<UpcomingChannelNumber>
				{channel.majorChannel > 0 ? channel.majorChannel + '.' + channel.minorChannel : channel.rfChannel}
			</UpcomingChannelNumber>
			<UpcomingEvent color={GridColors[genre as key]} onClick={() => handleEventCardClick(userId, lineupId, channel, event.eventId)} title={eventFlyOverText(event.title, event.episodeTitle, true)}>
				<UpcomingTitle>{event.title}</UpcomingTitle>
				{event.title !== event.episodeTitle ?
					<UpcomingEpisodeTitle>{event.episodeTitle}</UpcomingEpisodeTitle>
					: ''
				}
				<EventAttributes Event={event} showAirDate={false} />
			</UpcomingEvent>
		</StyledUpcomingFavoriteBox>
	)
}
