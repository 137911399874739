import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { fetchUserLineups, selectLineups, selectLineupsStatus } from '../SettingsState/lineupsSlice'
import { DataState, TransferPage } from '../../../interfaces/enums';
import GridFailed from '../../GridPages/components/GridFailed';
import GridLoading from '../../GridPages/components/GridLoading';
import {
	postLegacyTitanTVMigrate, selectUserId, selectLineupTransferList, selectOldAccountLoadingStatus, selectOldTitanAccount,
	setTransferPage, selectAccountMigrationStatus, selectIsAnonymous, selectUserError, selectOldTitanPassword,
	fetchUserInfo
} from '../SettingsState/userSlice';
import { useEffect } from 'react';
import { fetchUserSettings } from '../SettingsState/gridSettingsSlice';
import { fetchFavorites } from '../SettingsState/favoritesRemidersSlice';
import { useNavigate } from 'react-router-dom';

const TransferLineups = () => {
	const oldAccount = useAppSelector(selectOldTitanAccount);
	const oldAccountLoadingStatus = useAppSelector(selectOldAccountLoadingStatus);
	const accountMigrationStatus = useAppSelector(selectAccountMigrationStatus);
	const lineups = useAppSelector(selectLineups);
	const lineupStatus = useAppSelector(selectLineupsStatus)
	const transferList = useAppSelector(selectLineupTransferList);
	const userId = useAppSelector(selectUserId)
	const isAnon = useAppSelector(selectIsAnonymous);
	const dispatch = useAppDispatch();
	const errorMessage = useAppSelector(selectUserError);
	const oldTitanPassword = useAppSelector(selectOldTitanPassword)
	const nav = useNavigate();

	const handleTransfer = () => {
		if (accountMigrationStatus === DataState.IDLE) {
			dispatch(postLegacyTitanTVMigrate({ userId, legacyUserId: oldAccount.userId, newPassword: oldTitanPassword }))
		}
		// if (!TooManyLineups(lineups.lineups.length, isAnon)) {
		// 	dispatch(postLegacyTitanTVMigrate({ userId, legacyUserId: oldAccount.userId, selectedLineupIdList: transferList }))
		// } else {
		// 	alert(`You may only have a total of ${getMaxLineups()} lineups. You have ${lineups.lineups.length} lineups, you may only transfer ${getMaxLineups() - lineups.lineups.length} lineups`)
		// }
	}

	if (accountMigrationStatus === DataState.SUCCEEDED) {
		dispatch(fetchUserInfo(userId))
		dispatch(fetchUserLineups(userId));
		dispatch(fetchUserSettings(userId));
		dispatch(fetchFavorites(userId));
		dispatch(setTransferPage(TransferPage.LOGIN))
		nav('/');
	}

	if (accountMigrationStatus === DataState.FAILED) {
		if (oldAccount.errorCode !== 0 && oldAccount.errorMessage === '') {
			alert(`Migration Failed: ${errorMessage}`);
		} else if (oldAccount.errorCode !== 0) {
			alert(`Migration Failed: ${oldAccount.errorMessage}`);
		}
		dispatch(setTransferPage(TransferPage.LOGIN));
		nav('/AccountTransfer');
	}

	if (oldAccountLoadingStatus === DataState.SUCCEEDED) {
		if (oldAccount.errorCode === 1004 || oldAccount.errorCode === -2 || oldAccount.loginNameIsUsed || oldAccount.emailIsUsed) {
			dispatch(setTransferPage(TransferPage.LOGIN));
		} else if (accountMigrationStatus === DataState.IDLE) {
			handleTransfer()
		}
	}

	useEffect(() => {
		// if (lineupStatus !== DataState.SUCCEEDED) {
		// 	dispatch(fetchUserLineups(userId));
		// }

	}, [])

	return (
		<>
			<GridLoading />
		</>
	)
}

export default TransferLineups