import { useEffect, useState } from 'react'
import RedHeart from '../../../img/Icons/Red Icons/TitanTV Icon_Heart_Red.svg';
import ReminderBell from '../../../img/Icons/Yellow Icons/TitanTV Icon_Reminder_Yellow.png';
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import { selectGridLineupID, selectLineupChannels, selectMainGridChannelStatus } from '../mainGridSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { MarqueeIcon } from '../../Modal/ModalStyles';
import { DataState } from '../../../interfaces/enums';
import {
	fetchUpcomingReminders, fetchUpcomingFavorites, selectUpcomingFavorites, selectUpcomingFavoritesStatus,
	selectUpcomingRemindersStatus, selectUpcomingReminders
} from '../../UserSettingsPages/SettingsState/favoritesRemidersSlice';
import {
	StyledMarquee, MarqueeItem, MarqueeSpacer, MarqueeEndSpacer
} from './GridStyles';
import { CreateUUID, leadingZeroBuilder } from '../../Global/GobalFunctions';
import { Channel } from '../../../interfaces/channels';
import { Reminder } from '../../../interfaces/reminders';
import { fetchMessage, selectMessages, selectMessageStatus } from '../../OtherLegal/otherLegalSlice';
import { Message, Messages } from '../../../interfaces/settings';

// npm package use for Marquee
// https://www.npmjs.com/package/react-fast-marquee

const Marquee = () => {
	interface MarqueeObject {
		title: string;
		startTime: Date;
		starTimeString: string;
		channel: string;
		icon: string;
		type: string;
	}



	let [marqueeMessage, setMarqueeMessage] = useState('No upcoming Favorites or Reminders');
	let [isServerMessage, setIsServerMessage] = useState(false);
	let [serverMessage, setServerMessage] = useState('Hello World');
	const messageStatus = useAppSelector(selectMessageStatus);
	const messages = useAppSelector(selectMessages);

	const marqueeSpeed = 45;
	const marqueeGradient = false;
	let marqueeLoop = 0;
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const upcomingFav = useAppSelector(selectUpcomingFavorites);
	const upcomingFavStatus = useAppSelector(selectUpcomingFavoritesStatus);
	const channels = useAppSelector(selectLineupChannels);
	const channelsStatus = useAppSelector(selectMainGridChannelStatus);
	const upcomingReminders = useAppSelector(selectUpcomingReminders);
	const upcomingReminderStatus = useAppSelector(selectUpcomingRemindersStatus);
	const date = new Date()
	const dateTime = date.getFullYear() + leadingZeroBuilder(date.getMonth() + 1) + leadingZeroBuilder(date.getDate()) + leadingZeroBuilder(date.getHours()) + '00';
	const dispatch = useAppDispatch();
	let marqueeData: MarqueeObject[] = [];

	const favReminderTimeStringBuilder = (favRemindDate: string) => {
		let todayTomorrow = ''
		let hour = ''
		let min = ''
		let AMPM = 'AM'
		const now = new Date();
		const airTime = new Date(favRemindDate);
		if (airTime.getDate() === now.getDate()) {
			todayTomorrow = 'Today'
		} else {
			todayTomorrow = 'Tomorrow'
		}
		if (airTime.getHours() > 12) {
			AMPM = 'PM'
			hour = airTime.getHours() - 12 + ''
		} else if (airTime.getHours() === 0) {
			hour = '12'
		} else {
			hour = airTime.getHours() + ''
		}
		if (airTime.getHours() === 12) { AMPM = 'PM' }
		min = leadingZeroBuilder(airTime.getMinutes());
		return `${todayTomorrow} at ${hour}:${min} ${AMPM}`
	}

	const favChannelString = (channeIndex: number) => {
		let channel: Channel = {} as Channel;
		let channelString = '';
		for (let i = 0; i < channels.channels.length; i++) {
			if (channels.channels[i].channelIndex === channeIndex) {
				channel = channels.channels[i];
			}
		}
		if (channel.majorChannel > 0) {
			channelString = `${channel.majorChannel}.${channel.minorChannel}`
		} else {
			channelString = `${channel.rfChannel}`;
		}
		return channelString
	}

	const reminderChannelString = (rem: Reminder): string => {
		let channelString = ''
		if (rem.majorChannel > 0) {
			channelString = `${rem.majorChannel}.${rem.minorChannel}`
		} else {
			channelString = `${rem.rfChannel}`;
		}
		return channelString
	}

	useEffect(() => {
		// if (messageStatus === DataState.IDLE) {
		// 	dispatch(fetchMessage());
		// }
		if (upcomingReminderStatus === DataState.IDLE || upcomingReminderStatus === DataState.FAILED) {
			dispatch(fetchUpcomingReminders({ userId, lineupId }));
		}
		if (upcomingFavStatus === DataState.IDLE || upcomingFavStatus === DataState.FAILED) {
			dispatch(fetchUpcomingFavorites({ userId, lineupId, startTime: dateTime, duration: 1440 }));
		}
		if (isServerMessage) {
			marqueeLoop = 5;
			setMarqueeMessage(serverMessage);
		}
	}, []);

	// if (messageStatus === DataState.SUCCEEDED && messages.messages.length > 0) {
	// 	setIsServerMessage(true);
	// 	for (let i = 0; i++; i > messages.messages.length) {
	// 		setServerMessage(`${serverMessage} ${messages.messages[i].messageText}`)
	// 	}
	// }

	if (channelsStatus === DataState.SUCCEEDED && upcomingFavStatus === DataState.SUCCEEDED && upcomingReminderStatus === DataState.SUCCEEDED) {
		if (upcomingFav.events.length < 1 && upcomingReminders.reminders.length < 1) {
			marqueeLoop = 5;
		}

		if (upcomingFav.events.length > 0) {
			upcomingFav.events.forEach((fav) => {
				marqueeData.push(
					{
						title: fav.title,
						startTime: new Date(fav.startTime),
						starTimeString: favReminderTimeStringBuilder(fav.startTime),
						channel: favChannelString(fav.channelIndex),
						icon: RedHeart,
						type: 'Favorite'
					}
				)
			});
		}

		if (upcomingReminders.reminders.length > 0) {
			upcomingReminders.reminders.forEach((rem) => {
				marqueeData.push(
					{
						title: rem.title,
						startTime: new Date(rem.startTime),
						starTimeString: favReminderTimeStringBuilder(rem.startTime),
						channel: reminderChannelString(rem),
						icon: ReminderBell,
						type: 'Reminder'
					}
				)
			});
		}
		marqueeData.sort((a: MarqueeObject, b: MarqueeObject): any => {
			return a.startTime.getTime() - b.startTime.getTime();
		});
	}


	return (
		<StyledMarquee speed={marqueeSpeed} gradient={marqueeGradient} loop={marqueeLoop} onFinish={() => setMarqueeMessage('')} >
			<MarqueeEndSpacer></MarqueeEndSpacer>
			{(upcomingFavStatus === DataState.FAILED || channelsStatus === DataState.FAILED || upcomingReminderStatus === DataState.FAILED) ? 'Upcoming Shows Failed to Load' :
				(upcomingFavStatus === DataState.LOADING || channelsStatus === DataState.LOADING || upcomingReminderStatus === DataState.LOADING) ? 'Loading' :
					(upcomingFav.events.length === 0 && upcomingReminders.reminders.length === 0) || isServerMessage ? marqueeMessage :
						marqueeData.map((data) => {
							return (
								<span key={`${data.type}${CreateUUID()}`}>
									<MarqueeItem>
										<MarqueeSpacer><MarqueeIcon src={data.icon} /></MarqueeSpacer>
										{`${data.title}: ${data.starTimeString} on channel ${data.channel}`}
									</MarqueeItem>
								</span>
							)
						})
			}
		</StyledMarquee>
	)
}

export default Marquee