import { useAppSelector } from '../../../../app/hooks'
import { Channel } from '../../../../interfaces/channels'
import { selectFilterGrid } from '../../../Modal/filterDisplaySlice'
import { selectCompactMode, selectFixedCellHeight } from '../../../UserSettingsPages/SettingsState/gridSettingsSlice'
import { selectMainGridDuration } from '../../mainGridSlice'
import { StyledChannelRow } from '../GridStyles'
import ShowMissing from './ShowMissing'
import { getChannelRowHeight } from '../../../Global/GobalFunctions'

const DayMissing = (props: any) => {
	const chl: Channel = props.channel;
	const mainGridDuration = useAppSelector(selectMainGridDuration);
	const fixedHeight = useAppSelector(selectFixedCellHeight);
	const filterGrid = useAppSelector(selectFilterGrid);
	let height = '105px';
	const compactMode = useAppSelector(selectCompactMode);
	height = getChannelRowHeight(compactMode);

	return (
		<StyledChannelRow shouldDisplay={!filterGrid} rowHeight={height} fixcellheight={fixedHeight} key={'missingDay:' + chl.channelId}>
			{[...Array(mainGridDuration * 2)].map((ele, index) => <ShowMissing key={'missingDay:' + index + chl.channelId} duration={30} />)}
		</StyledChannelRow>
	)
}

export default DayMissing