import TVY from '../../../../img/StatsFlags/TVGuidlines/TV-Y.svg';
import TVY7 from '../../../../img/StatsFlags/TVGuidlines/TV-Y7.svg';
import TVPG from '../../../../img/StatsFlags/TVGuidlines/TV-PG.svg';
import TV14 from '../../../../img/StatsFlags/TVGuidlines/TV-14.svg';
import TVMA from '../../../../img/StatsFlags/TVGuidlines/TV-MA.svg';
import { ProgramFlag } from './EventDetailsStyles';

const TVRating = (props: any) => {
	const tvRating: string = props.tvRating
	switch (tvRating) {
		case 'TV-Y':
			return <ProgramFlag src={TVY} />
		case 'TV-Y7':
			return <ProgramFlag src={TVY7} />
		case 'TV-PG':
			return <ProgramFlag src={TVPG} />
		case 'TV-14':
			return <ProgramFlag src={TV14} />
		case 'TV-MA':
			return <ProgramFlag src={TVMA} />
		default:
			return <span></span>
	}
}

export default TVRating