import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { DataState, ModalState } from '../../../interfaces/enums';
import {
	SettingsContainer, Understanding, FavoriteUpcomingContainer, FavoriteContainer, OtherAiringsContainer, FavoriteHeader
} from '../../UserSettingsPages/components/SettingsStyles'
import GridFailed from '../components/GridFailed';
import GridLoading from '../components/GridLoading';
import { selectGridLineupID, selectLineupChannels } from '../mainGridSlice';
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import { selectOtherAirings, selectOtherAiringsStatus } from '../searchAdditionAiringsSlice';
import {
	StyledOtherAiringsBox, UpcomingChannelNumber, UpcomingEpisodeTitle, UpcomingEvent, UpcomingTimeDate, UpcomingTitle, UpcomingDetails
} from '../../UserSettingsPages/components/SettingsStyles'
import { ShowHD } from '../../GridPages/components/GridStyles'
import { Channel } from '../../../interfaces/channels'
import { SearchItem } from '../../../interfaces/search'
import { eventFlyOverText, leadingZeroBuilder } from '../../Global/GobalFunctions';
import { selectColors } from '../gridColorsSlice';
import { ClickableChannelCard } from '../../Global/GobalComponents';
import { fetchEventDetails, setDetailsChannel } from '../detailsSlice';
import { setModalContent, setModalOpen } from '../../Modal/modalSlice';
import { useEffect } from 'react';

const OtherAirings = () => {
	const otherAiringsStatus = useAppSelector(selectOtherAiringsStatus);
	const otherAirings = useAppSelector(selectOtherAirings);
	const channels = useAppSelector(selectLineupChannels);

	const findChannel = (channelIndex: number): Channel => {
		let index = {} as Channel
		channels.channels.forEach((channel) => {
			if (channel.channelIndex === channelIndex) {
				index = channel;
			}
		})
		return index;
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	})

	return (
		<>
			<SettingsContainer>
				<FavoriteContainer>
					{(otherAiringsStatus === DataState.FAILED) ? <GridFailed errorCode={otherAirings.errorCode} errorMessage={otherAirings.errorMessage} /> :
						(otherAiringsStatus === DataState.LOADING) ? <GridLoading /> :
							(otherAirings.searchItems === undefined) ? <FavoriteHeader>No Program selected</FavoriteHeader> :
								<>
									<FavoriteHeader>Upcoming Airings of {otherAirings.searchItems[0].title}</FavoriteHeader>
									<OtherAiringsContainer>
										{otherAirings.searchItems.map((event => {
											return <OtherAiringsBox key={event.eventId} event={event} channel={findChannel(event.channelIndex)} />
										}))}
									</OtherAiringsContainer>
								</>
					}
				</FavoriteContainer>
				<Understanding>
					<span>
						This list contains the additional airings of the selected program during the next 14 days.
						<p>The list is opened by clicking the View Additional Airings link in a program's Details box.</p>
						The list is sorted with the airing nearest to the current time first.
						<p>Click on a program's cell in the list to view its Details box.</p>
						Click on a station's logo/call sign to view that channel's Daily View page.
						<p>Click on the TitanTV logo in the top left corner to return to the schedule grid.</p>
					</span>
				</Understanding>
			</SettingsContainer>
		</>
	)
}

export default OtherAirings

const OtherAiringsBox = (props: any) => {
	const event: SearchItem = props.event;
	const channel: Channel = props.channel;
	const dispatch = useAppDispatch()

	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID)

	const handleEventCardClick = () => {
		dispatch(setDetailsChannel(channel))
		dispatch(fetchEventDetails({ userId: userId, lineupId: lineupId, channelIndex: channel.channelIndex, eventId: event.eventId }));
		dispatch(setModalContent(ModalState.EVENTDETAILS));
		dispatch(setModalOpen());
	}


	let AMPM = 'AM';
	let startTime = new Date(event.startTime);
	let hour = startTime.getHours();
	let min = leadingZeroBuilder(startTime.getMinutes());
	const { GridColors } = useAppSelector(selectColors);
	type key = keyof typeof GridColors;
	let genre = event.displayGenre;
	if (genre === 'Game/Quiz') { genre = 'Game_Quiz' }

	if (startTime.getHours() > 11) { AMPM = 'PM'; }
	if (hour === 0) { hour = 12; }
	if (hour > 12) { hour = hour - 12; }

	const newRepeat = (str: string): string => {
		if (str === 'N') { return 'New' }
		if (str === 'R') { return 'Repeat' }
		return ''
	}

	const dayOfTheWeek = (day: number): string => {
		const daysOfWeek = [
			'Sun',
			'Mon',
			'Tue',
			'Wed',
			'Thur',
			'Fri',
			'Sat'
		]
		return daysOfWeek[day];
	}

	return (
		<StyledOtherAiringsBox>
			<UpcomingTimeDate>
				<div>{`${dayOfTheWeek(startTime.getDay())} ${leadingZeroBuilder(startTime.getMonth() + 1)}/${leadingZeroBuilder(startTime.getDate())}`}</div>
				<div>{`${hour}:${min} ${AMPM}`}</div>
			</UpcomingTimeDate>
			<ClickableChannelCard channel={channel} />
			<UpcomingChannelNumber>
				{channel.majorChannel > 0 ? channel.majorChannel + '.' + channel.minorChannel : channel.rfChannel}
			</UpcomingChannelNumber>
			<UpcomingEvent color={GridColors[genre as key]} onClick={() => handleEventCardClick()} title={eventFlyOverText(event.title, event.episodeTitle, true)} >
				<UpcomingTitle>{event.title}</UpcomingTitle>
				{event.title !== event.episodeTitle ?
					<UpcomingEpisodeTitle>{event.episodeTitle}</UpcomingEpisodeTitle>
					: ''
				}
				<UpcomingDetails>
					{event.isHD ? <ShowHD>HD </ShowHD> : ''}
					{`${newRepeat(event.newRepeat)} `}
					{event.tvRating === '' ? '' : event.tvRating}
					{(event.tvRating === '' && event.mpaaRating === '' ? ` ` : '')}
					{event.mpaaRating === '' ? '' : event.mpaaRating}
				</UpcomingDetails>
			</UpcomingEvent>
		</StyledOtherAiringsBox>
	)
}