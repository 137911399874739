import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Channel } from '../../../interfaces/channels'
import { ModalState } from '../../../interfaces/enums'
import { Event } from '../../../interfaces/schedule'
import {
	FilterCritria, eventFlyOverText, filterEventShouldDisplay, fontSizeCalculation, getWindowDimensions
} from '../../Global/GobalFunctions'
import { setModalContent, setModalOpen } from '../../Modal/modalSlice'
import {
	selectCellDescription, selectDisplayShowCards, selectEpsTitle, selectFixedCellHeight, selectFontSize, selectMovieRating,
	selectAudioDescription, selectSocialMediaLinks, selectCompactMode, selectNewRepeat
} from '../../UserSettingsPages/SettingsState/gridSettingsSlice'
import { selectColors } from '../gridColorsSlice'
import {
	selectGridDate, selectMainGridDuration, selectGridLineupID, selectGridTime
} from '../mainGridSlice'
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice'
import { fetchEventDetails, setDetailsChannel } from '../detailsSlice';
import {
	EventDescription, EventTitle, EventContainer, EventDetailsContainer, EventCard, EpsTitle, TitleContainer, FavIcon,
	EventContainerDetailsClick
} from './GridStyles'
import { selectFavorites } from '../../UserSettingsPages/SettingsState/favoritesRemidersSlice'
import RedHeart from '../../../img/Icons/Red Icons/TitanTV Icon_Heart_Red.svg';
import {
	selectMoviesDisplay, selectMyFavoritesDisplay, selectNewProgramDisplay, selectGenreFilter, selectFilterGrid
} from '../../Modal/filterDisplaySlice'
import { ProgramFlag } from './eventDetails/EventDetailsStyles'
import AD from '../../../img/StatsFlags/AudioDes.svg';
import StarRating from './StarRating'
import SocialMediaLinks from './SocialMediaLinks'
import EventAttributes from './EventAttributes'
import EventShowCard from './EventShowCard'

const handleEventCardClick = (dispatch: any, userID: string, lineupID: string, channelIndex: number, eventID: number, channel: Channel, display: boolean) => {
	if (display) {
		dispatch(setDetailsChannel(channel))
		dispatch(fetchEventDetails({ userId: userID, lineupId: lineupID, channelIndex: channelIndex, eventId: eventID }));
		dispatch(setModalContent(ModalState.EVENTDETAILS));
		dispatch(setModalOpen());
	} else {
		return;
	}
}

const EventContent = (props: any) => {
	const dispatch = useAppDispatch();
	const userID = useAppSelector(selectUserId);
	const lineupID = useAppSelector(selectGridLineupID);
	const mainGridDuration = useAppSelector(selectMainGridDuration);
	const displayShowCard = useAppSelector(selectDisplayShowCards);
	const showMovieRating = useAppSelector(selectMovieRating);
	const showDescription = useAppSelector(selectCellDescription);
	const showEpsTitle = useAppSelector(selectEpsTitle);
	const showAudioDes = useAppSelector(selectAudioDescription);
	const showMediaLinks = useAppSelector(selectSocialMediaLinks);
	const fixedCellHeight = useAppSelector(selectFixedCellHeight);
	const fontSizeChange = useAppSelector(selectFontSize);
	const favs = useAppSelector(selectFavorites);
	const compactMode = useAppSelector(selectCompactMode)
	const { width } = getWindowDimensions();
	const newRepeatDisplay = useAppSelector(selectNewRepeat);

	const filterGrid = useAppSelector(selectFilterGrid);
	const displayNewProg = useAppSelector(selectNewProgramDisplay);
	const displayFav = useAppSelector(selectMyFavoritesDisplay);
	const displayMovies = useAppSelector(selectMoviesDisplay);
	const genreFilter = useAppSelector(selectGenreFilter);
	let display = true;

	let isFav = false;

	const channelIndex: number = props.ChannelIndex
	const event: Event = props.Show
	let duration = event.duration / 60;
	let channel: Channel = props.Channel;

	let visableDuration = duration;

	const { GridColors } = useAppSelector(selectColors);
	type key = keyof typeof GridColors;
	let genre = event.displayGenre;
	if (genre === 'Game/Quiz') { genre = 'Game_Quiz' }
	if (genre === 'Sci-Fi') { genre = 'SciFi' }
	const gridDate = useAppSelector(selectGridDate);
	const gridHour = useAppSelector(selectGridTime);
	const eventStartTime = new Date(event.startTime);
	const eventEndTime = new Date(event.endTime);
	const gridStartTime = new Date(gridDate + 'T' + gridHour + ':00:00');
	let gridEndTime = new Date(gridStartTime.getTime());
	gridEndTime.setHours(gridStartTime.getHours() + mainGridDuration);

	if (gridStartTime.getTime() > eventStartTime.getTime()) {
		let diff = ((gridStartTime.getTime() - eventStartTime.getTime()) / 1000) / 60;
		visableDuration = visableDuration - diff;
	}

	if (gridEndTime.getTime() < eventEndTime.getTime()) {
		let diff = ((eventEndTime.getTime() - gridEndTime.getTime()) / 1000) / 60;
		visableDuration = visableDuration - diff;
	}

	let atLeast30Mins = true;
	if (visableDuration < 30) { atLeast30Mins = false; }

	let toShortForShowCard = true;
	if (visableDuration < 45 && width < 900) { toShortForShowCard = false; }

	for (let i = 0; i < favs.favorites.length; i++) {
		if (favs.favorites[i].title === event.title) {
			isFav = true;
			break;
		}
	}

	// code here is for the filter when we want the filter to act as an "and" instead of "or"
	if (filterGrid) {
		let filterCritria: FilterCritria = {
			displayGenre: genreFilter,
			displayNewProgram: displayNewProg,
			displayMovies: displayMovies,
			displayFavorites: displayFav
		}

		display = filterEventShouldDisplay(filterCritria, isFav, event);
	}

	visableDuration = Math.abs((visableDuration / (mainGridDuration * 60)) * 100);
	const fontSize = fontSizeCalculation(parseFloat(fontSizeChange));
	const titleFontSize = fontSizeCalculation(parseFloat(fontSizeChange), 14);

	let isAudioDes = false;
	if (event.audioStreams[0]) {
		event.audioStreams.forEach(as => {
			if (as.audioServiceType === 'visually_impaired') { isAudioDes = true; }
		});
	}

	const newRepeat = () => {
		let newRepeatString = '';
		if (newRepeatDisplay) {
			if (event.newRepeat) { }
			if (event.newRepeat === 'N') { newRepeatString = 'New'; }
			if (event.newRepeat === 'R') { newRepeatString = 'Repeat' }
		}
		return newRepeatString;
	}

	return (
		<EventContainer duration={'' + visableDuration} color={display ? GridColors[genre as key] : 'Other'}>
			<EventContainerDetailsClick
				title={eventFlyOverText(event.title, event.episodeTitle, display)}
				//title={`startTime: ${eventStartTime.toString()} endTime: ${eventEndTime.toString()}`}
				onClick={() => { handleEventCardClick(dispatch, userID, lineupID, channelIndex, event.eventId, channel, display) }}>
				<EventDetailsContainer display={display} id={'Details containter'}>
					{displayShowCard && atLeast30Mins && toShortForShowCard && !compactMode ?
						<EventShowCard Event={event} /> : ''}

					<EventDescription fontSize={fontSize + 'px'}>
						<TitleContainer>
							<EventTitle fontSize={titleFontSize + 'px'}>
								{event.title}
							</EventTitle>
							{isFav && atLeast30Mins ? <FavIcon src={RedHeart} /> : ''}
						</TitleContainer>
						{showEpsTitle && atLeast30Mins ? <EpsTitle fontSize={fontSize + 'px'}>{event.episodeTitle}</EpsTitle> : ''}
						{compactMode ? <span>{newRepeat()}</span> : ''}
						{atLeast30Mins ?
							!compactMode ?
								<EventAttributes Event={event} showAirDate={true} />
								: ''
							: ''}
						{(event.description && showDescription && atLeast30Mins && !compactMode) ? event.description : ''}
					</EventDescription>
					{!fixedCellHeight && showMovieRating && !compactMode ? <StarRating StarRating={event.starRating} /> : ''}
					{isAudioDes && showAudioDes && !compactMode ? <ProgramFlag title='Audio Description' src={AD} /> : ''}
				</EventDetailsContainer>
			</EventContainerDetailsClick>
			{!fixedCellHeight && showMediaLinks && !compactMode ? <SocialMediaLinks links={event.links} /> : ''}
		</EventContainer>
	)
}

export default EventContent