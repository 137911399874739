import styled from "styled-components";
// https://www.react-fast-marquee.com/
import Marquee from 'react-fast-marquee'
import { Link } from "react-router-dom";

export const GridContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: fit-content;
	width: 100%;
	overflow-anchor: none;
`

export const NoChannelsMessage = styled.div`
	margin-top: 100px;
	font-size: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
`

export const NoChannelLink = styled.span`
	font-size: 20px;
	cursor: pointer;
	text-decoration: underline;
	margin-left: 5px;
`

export const Spacer = styled.div`
	background-color: white;
	height: 10px;
	width: 100%;
`

export const ChannelGridSelectorContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
`

export const ChannelGridIconContainer = styled(ChannelGridSelectorContainer)`
	justify-content: flex-end;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		display: none;
	}
`

export const GridSelectorsContainer = styled.div`
	color: white;
	background: ${({ theme }) => theme.medBlue};
	height: 75px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 5px;

	@media print {
		display: none;
	}

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		height: fit-content;
	}
	
	@media (max-width: ${({ theme }) => theme.mobile}) {
		flex-wrap: wrap;
		justify-content: center;
	}
`

export const MainGridSelectorContainer = styled(GridSelectorsContainer)`
	@media (max-width: ${({ theme }) => theme.medScreen}) {
		flex-direction: column;
		align-items: center;
		height: fit-content;
	}
`

export const GridDayTime = styled.div`
	display: flex;
	flex-direction: row;
`

export const GridLineup = styled.div`
	display: flex;
	flex-direction: row;
`

export const GridPullDown = styled.select`
	border-radius: 5px;
	padding: 5px 12px;
	margin: 15px;
	width: fit-content;
	background-color: white;
`

export const GridLineupPulDown = styled(GridPullDown)`
	min-width: 165px;
`

export const GridPullDownDay = styled(GridPullDown)`
	/* @media (max-width: ${({ theme }) => theme.medScreen}) {
		display: none;
	} */
`

export const GridPullDownDayAB = styled(GridPullDown)`
	display: none;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		display: inline-block;
	}
`

export const GridSelectorImgContainer = styled(Link)`
	justify-self: center;
	align-self: center;
	cursor: pointer;
`

export const GridSelectorImg = styled.img`
	height: 17px;
	width: 17px;
	justify-self: center;
	align-self: center;
	margin: 0 7px;
	cursor: pointer;
`

export const AddLineupIcon = styled(GridSelectorImg)`
	transform: rotate(45deg);
	height: 13px;
	width: 13px;
`

export const Printer = styled(GridSelectorImg)`
	margin: 0 10px;
	@media (max-width: ${({ theme }) => theme.medScreen}) {
		display: none;
	}
`

export const BackButton = styled(Link)`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 5px;
	font-size: 14px;
	cursor: pointer;
	-webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
	color: white;
	text-decoration: none;

	span {
		margin-left: 5px;
	}
`

export const BlueBackButton = styled(BackButton)`
	color: ${({ theme }) => theme.darkBlue};
	align-self: start;
	margin-top: 10px;
`

export const GridRadioGroup = styled.div`
min-height: 45px;
@media (max-width: 820px) {
	label:nth-child(n + 1) {
			display: none
		}
	label:nth-child(n + 2) {
		display: none
	}
}
@media (max-width: 1180px) {
	label:nth-child(n + 3) {
		display: none
	}
}
	@media (max-width: 1500px) {
		label:nth-child(n + 4) {
			display: none
		}
	}
`

export const GridRadio = styled.input`
	width: 22px;
	height: 22px;
	accent-color: ${({ theme }) => theme.medBlue};
`

export const GridRadioLabel = styled.label`
	padding: 10px 10px 10px 0;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	-webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const StyledMarquee = styled(Marquee)`
	margin-right: 3px;
	font-weight: bold;
	user-select: none;
`

export const MarqueeItem = styled.div`
	color: white;
`

export const MarqueeSpacer = styled.span`
	margin: 0 2px 0 10px;
`
export const MarqueeEndSpacer = styled.span`
	padding-right: 75px;
`

export const GridContent = styled.div`
	border: ${({ theme }) => theme.gridBorders};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	overflow-anchor: none;
`

export const StyledGrid = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(100% - 170px);

	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 100%
	}

	@media print {
		width: 100%;
	}
`

export const DateTimeBar = styled.div<{ isLast: boolean, isFirst: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 18px;
	font-weight: bold;
	width: 100%;
	border-top: ${p => p.isLast || p.isFirst ? 'none' : ({ theme }) => theme.gridBorders};
	border-bottom: ${p => p.isLast ? 'none' : ({ theme }) => theme.gridBorders};
	background: rgb(231,231,231);

	@media (max-width: ${({ theme }) => theme.mobile}) {
		font-size: 16px;
		width: 100vw;
	}
`

export const SelectedDate = styled.span <{ fixcellheight: boolean, fontSize: string }>`
	user-select: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	min-width: ${p => (p.fixcellheight) ? '95px' : '116px'};
	width: ${p => (p.fixcellheight) ? '95px' : '116px'};
	padding: 5px;
	border-right: ${({ theme }) => theme.gridBorders};

	@media print {
		justify-content: center;
	}
`

export const TimeSegment = styled.span <{ duration: string, fontSize: string }>`
	user-select: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	border-right: ${({ theme }) => theme.gridBorders};
	padding: 5px;
	flex: ${p => (p.duration)}%;
	box-sizing: border-box;
`

export const DateTimeDisplay = styled.span`
	padding-top: 4px;
`

export const DateTimeBack = styled.span`
	justify-self: start;
	font-size: 18px;

	&:hover {
		cursor: pointer;
	}

	@media print {
		display: none;
	}
`

export const DateTimeForward = styled.span`
	justify-self: start;
	font-size: 18px;

	&:hover {
		cursor: pointer;
	}

	@media print {
		display: none;
	}
`

export const DateTimeSpacer = styled.span`
	@media print {
		display: none;
	}
`

export const StyledMainGridComponent = styled.div`
	width: 100%;
	overflow-anchor: none;
`

export const StyledChannelRow = styled.div <{ fixcellheight: boolean, shouldDisplay: boolean, rowHeight: string }>`
	display: ${p => (p.shouldDisplay) ? 'flex' : 'none'};
	flex-direction: row;
	width: 100%;
	margin: 0;
	box-sizing: border-box;
	height: ${p => (p.fixcellheight) ? p.rowHeight : 'auto'};

	@media print {
		//border-bottom: ${({ theme }) => theme.gridBordersSmall};
	}
`

export const ChannelCardLink = styled(Link)`
	text-decoration: none;
`

export const ChannelContainer = styled(Link) <{ fixcellheight: boolean, active: boolean, rowHeight: string, compact: boolean }>`
	text-decoration: none;
	user-select: none;
	display: flex;
	flex-direction: column;
	justify-content: ${p => (p.compact) ? 'center' : 'flex-start'};
	border-bottom: ${({ theme }) => theme.gridBorders};
	border-right: ${({ theme }) => theme.gridBorders};
	padding: 7px;
	box-sizing: border-box;
	min-height: ${p => (p.fixcellheight) ? p.rowHeight : 'auto'};
	height: ${p => (p.fixcellheight) ? p.rowHeight : 'auto'};
	min-width: ${p => (p.fixcellheight) ? '95px' : '116px'};
	width: ${p => (p.fixcellheight) ? '95px' : '116px'};
	background: rgb(231,231,231);
	cursor: ${p => (p.active) ? 'pointer' : 'auto'};

	/* @media print {
		align-items: center;
		justify-content: center;
	} */
`

export const ChannelInfo = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
`
export const AdWrapper = styled.div`
  padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media print {
		display: none;
	}
`
export const ChannelID = styled.span <{ compact: boolean }>`
	color: black;
	font-weight: bold;

	@media print {
		font-size: ${p => (p.compact) ? '12px' : '24px'};
	}
`

export const ExpandIcon = styled.img <{ compact: boolean }>`
	height: ${p => (p.compact) ? '8px' : '15px'};
	width: ${p => (p.compact) ? '8px' : '15px'};
	@media print {
		visibility: hidden;
	}
`

export const ChannelCard = styled.img`
	align-self: center;
	height: 50px;
	width: 50px;

	/* @media print {
		display: none;
	} */
`

export const ChannelCardSM = styled(ChannelCard)`
	height: 25px;
	width: 25px;
`

export const ChannelCallsign = styled.div <{ compact: boolean }>`
	padding: 5px;
	color: ${({ theme }) => theme.darkBlue};
	text-align: center;
	font-size: ${p => (p.compact) ? '10px' : '14px'};
	word-break: break-all;

	@media print {
		color: black;
		font-size: 14px;
	}
`

export const ChannelCallsignSM = styled(ChannelCallsign)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: clip;
`

export const NextGenLogo = styled.img <{ compact: boolean }>`
	width: ${p => (p.compact) ? '50px' : '80px'};
	height: auto;
	margin-left: ${p => (p.compact) ? '20px' : '0'};

	@media print {
		display: none;
	}
`

export const NextGenText = styled.div`
	display: none;
	
	@media print {
		display: block;
		color: black;
	}
`

export const Events = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;

	@media print {
		border-bottom: 1px black solid;
		overflow: hidden;
	}
`

export const TimeBarUnit = styled(Events)`
	border: none;
`

export const EventContainer = styled.div <{ duration: string, color: string }>`
	flex: ${p => (p.duration)}%;
	min-width: 0;
	background: ${p => (p.color)};
	//user-select: none;
	border-bottom: ${({ theme }) => theme.gridBorders};
	border-right: ${({ theme }) => theme.gridBorders};
	padding: 10px 7px;
	box-sizing: border-box;
	margin: 0;
	white-space: pre-wrap;
	overflow: hidden;
	/* display: flex;
	flex-direction: column; */

	@media print {
		border-bottom: ${({ theme }) => theme.gridBordersSmall};
		overflow: hidden;
	}

	@media (max-width: ${({ theme }) => theme.mobile}) {
		border-bottom: ${({ theme }) => theme.gridBordersSmall};
		border-right: ${({ theme }) => theme.gridBordersSmall};
	}
`

export const EventContainerDetailsClick = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	cursor: pointer;
	white-space: pre-wrap;
	overflow: hidden;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
`

export const EventCard = styled.img`
	width: 60px;
	height: 80px;
	margin: 0 5px 0 0;
	padding-bottom: 3px;
	float: left;

	@media (max-width: ${({ theme }) => theme.smallScreen}) {
		display: none;
	}

	@media print {
		display: none;
	}
`

export const TitleContainer = styled.div`
	display: flex;
	flex: row;
	overflow: hidden;
`

export const EventTitle = styled.div <{ fontSize: string }>`
	height: auto;
	font-size: ${p => (p.fontSize)};
	font-weight: bold;
	user-select: none;
	//box-sizing: border-box;
	width: 100%;
	height: 100%;
	overflow: hidden;
`

export const FavIcon = styled.img`
	height: 12px;
	width: 12px;

	@media print {
		display: none;
	}
`

export const EventDetailsContainer = styled.div <{ display: boolean }>`
	display: ${p => (p.display) ? 'block' : 'none'};
	//user-select: none;
	cursor: ${p => (p.display) ? 'pointer' : 'auto'};
	width: 100%;
`

export const EventDisplayDetails = styled.div <{ fontSize: string }>`
	font-size: ${p => (p.fontSize)};
	box-sizing: border-box;
	user-select: none;
	
	@media print {
		overflow: hidden;
	}
`

export const EventDescription = styled.div <{ fontSize: string }>`
	font-size: ${p => (p.fontSize)};
	overflow: hidden;
	user-select: none;
`

export const ShowHD = styled.span`
	color: red;
	text-transform: uppercase;
	user-select: none;

	@media print {
		color: black;
	}
`

export const EpsTitle = styled.div <{ fontSize: string }>`
	font-size: ${p => (p.fontSize)};
	font-style: italic;
	box-sizing: border-box;
`