import React from 'react'
import { useAppSelector } from '../../app/hooks';
import { Channel } from '../../interfaces/channels';
import { ChannelCallsign, ChannelCard } from '../GridPages/components/GridStyles'
import { UpcomingChannelContainter } from '../UserSettingsPages/components/SettingsStyles'
import { selectDisplayChannelLogo } from '../UserSettingsPages/SettingsState/gridSettingsSlice';

export const ClickableChannelCard = (props: any) => {
	const channel: Channel = props.channel;
	const displayChannelLogo = useAppSelector(selectDisplayChannelLogo);

	return (
		<UpcomingChannelContainter to={`../dailygrid/${channel.channelIndex}`}>
			{displayChannelLogo ? <ChannelCard src={channel.logo} />
				:
				<ChannelCallsign compact={false}>{channel.callSign} <br />{channel.network}</ChannelCallsign>
			}
		</UpcomingChannelContainter>
	)
}
