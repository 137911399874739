import React, { useState } from 'react'
import { SettingsHeader } from '../../../UserSettingsPages/components/SettingsStyles'
import {
	FAQHeaderText, StyledAccordionItem, AccordionClose, AccordionContent, AccordingStatment, AccordionHeader
} from '../FAQHelpStyles'

const FAQGeneral = () => {
	const [whatIs, setWhatIs] = useState(false);
	const [preference, setPreference] = useState(false);
	const [refresh, setRefresh] = useState(false);


	return (
		<>
			<SettingsHeader>General Information</SettingsHeader>
			<StyledAccordionItem>
				<AccordionHeader onClick={() => setWhatIs(!whatIs)}>
					<FAQHeaderText>What is TitanTV.com?</FAQHeaderText>
					{whatIs ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={whatIs ? 'block' : 'none'}>
					<AccordingStatment>
						TitanTV.com is a free, online television programming guide.  It will tell you what is on from your chosen broadcast source (local broadcasters via an antenna, your cable or satellite subscription or through an Over-the-top box like SlingTV. As such, there are no fees or subscriptions to view the schedule information TitanTV.com provides.
					</AccordingStatment>
					<AccordingStatment>
						It is not a mechanism through which you may watch, stream, or record programs.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>

			<StyledAccordionItem>
				<AccordionHeader onClick={() => setPreference(!preference)}>
					<FAQHeaderText>Why are Preference and Login Settings are Not Being Retained Between Visits?</FAQHeaderText>
					{preference ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={preference ? 'block' : 'none'}>
					<AccordingStatment>
						When you visit TitanTV, information about your visit, such as login status, lineup selections, and display preferences are stored under the browser in a small text file called a cookie. When that file is present on later visits, that information is recalled from the cookie and displayed onscreen accordingly.
					</AccordingStatment>
					<AccordingStatment>
						However, if that cookie file is not present, either by it expiring or by being manually deleted, TitanTV has no information about any previous visits available to recall and treats this visit as if it were the first to the site.
					</AccordingStatment>
					<AccordingStatment>
						To heighten their security, some browsers have changed their default security settings to automatically delete all cookie files and history information whenever the browser is closed. This setting may be changed in the browser's Settings menu to exempt the cookies for certain domains, such as TitanTV.com, from being deleted when the browser is closed.
					</AccordingStatment>
					<AccordingStatment>
						To further secure your lineups and preference settings, we recommend becoming a TitanTV.com member.  Membership is free and only requires creating a user name and password.  With a TitanTV.com membership, your account's settings and preferences are stored on TitanTV's servers and may be recalled by simply logging in on any machine.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>

			<StyledAccordionItem>
				<AccordionHeader onClick={() => setRefresh(!refresh)}>
					<FAQHeaderText>How Do I Refresh My Browser's Cache of Stored Data?</FAQHeaderText>
					{refresh ? <AccordionClose> - </AccordionClose> : <AccordionClose> + </AccordionClose>}
				</AccordionHeader>
				<AccordionContent isOpen={refresh ? 'block' : 'none'}>
					<AccordingStatment>
						Files and information about visited web sites are stored by the browser in a repository called a cache.  Over time, this cache can become clogged with old, outdated files that can interfere with a site's performance.
					</AccordingStatment>
					<AccordingStatment>
						Clearing the browser's cache routinely is a recommended maintenance practice.
					</AccordingStatment>
					<AccordingStatment>
						On a PC, go to TitanTV's grid page and press the Ctrl + F5 keys at the same time.
					</AccordingStatment>
					<AccordingStatment>
						On a Mac, press the Command + R key combination.
					</AccordingStatment>
					<AccordingStatment>
						These keystrokes command the browser to remove the old data files from the cache and download all new code from TitanTV's server.  Once the download is complete, the page will refresh, and all controls on the page should function normally again.
					</AccordingStatment>
				</AccordionContent>
			</StyledAccordionItem>
		</>
	)
}

export default FAQGeneral