import styled from "styled-components";

export const CaCBox = styled.div`
	background: white;
	border-radius: 5px 5px 0 0;
	//padding-bottom: 10px;

	@media (max-width: 645px) {
		max-width: 90%;
	}
`

export const CaCContainter = styled.div`
	display: flex;
	flex-direction: row;
	//margin: 10px 15px;
	overflow: hidden;
	overflow-y: auto;
	height: 100%;
	max-width: 850px;
	min-width: 350px;
	max-height: 450px;
	height: 450px;
	margin-bottom: 10px;

	@media (max-width: ${({ theme }) => theme.largeScreen}) {
		flex-direction: column;
		align-items: center;
	}
`

export const CaCBody = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	flex: 3;
	padding: 10px;

	@media (max-width: ${({ theme }) => theme.largeScreen}) {
		flex-direction: column;
		align-items: center;
	}
`

export const CaCImage = styled.img`
	width: 150px;
	height: auto;

	@media (max-width: ${({ theme }) => theme.largeScreen}) {
		align-self: center;
	}
`

export const CaCContent = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 15px;
	//height: 120%;

	@media (max-width: ${({ theme }) => theme.largeScreen}) {
		align-items: center;
	}
`

export const CaCAD = styled.div`
	min-width: 300px;
	min-height: 300px;
	max-width: 300px;
	max-height: 300px;
	flex: 1;
	padding-top: 10px;
`

export const CaCLine = styled.div`
	border-top: solid 2px black;
	//width: 45vh;
`

export const CaCName = styled.div`
	padding-top: 5px;
	font-weight: bold;
	font-size: 18px;
	text-align: center;
`

export const CaCBirthdate = styled.span`
	font-size: 16px;
	font-weight: normal;
`

export const CaCRoles = styled.div`
	color: ${({ theme }) => theme.medBlue};
	text-transform: uppercase;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
`

export const Role = styled.span`
	font-size: 12px;
`

export const CaCSections = styled.div`
`

export const CaCSectionTitle = styled.div`
	font-size: 15px;
	font-weight: bold;
	margin-bottom: 5px;
`

export const CaCSection = styled.div`
	font-weight: normal;
	margin-left: 16px;

	@media (max-width: ${({ theme }) => theme.largeScreen}) {
		align-self: start;
	}
`

export const CaCSectionItem = styled.div`
	font-size: 13px;
`

export const CaCBottomPush = styled.div`
	margin-bottom: 10px;
`

export const CaCMiddleDot = styled.span`
	font-size: 20px;
	font-weight: bolder;
	padding-right: 5px;
`

export const CaCLinks = styled.div`
	padding-top: 5px;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
`

export const CaCLink = styled.a`
	font-size: 14px;
	color: black;
`