import {
	WhiteClose, ModalIconSM
} from "../ModalStyles";
import { setModalOpen } from "../modalSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import { fetchNewGuestUser, newUserSettings, selectUserId } from "../../UserSettingsPages/SettingsState/userSlice";
import {
	selectPostalCode, selectDefaultLineup, selectStartTime, selectSettingMainGridDuration, selectFontSize, selectHideNGChannels,
	selectWrapCellText, selectFixedCellHeight, selectDisplayChannelLogo, selectDisplayShowCards, selectDailyGridWidth, selectGridColorScheme,
	selectCellDescription, selectEpsTitle, selectSeasonNumber, selectHDIndicator, selectNewRepeat, selectGenreDisplay, selectMovieRating,
	selectYearProduction, selectTvRating, selectProgramType, selectNextGenIndicator, selectAudioDescription, selectSocialMediaLinks,
	selectDetailsSynopsis, selectDetailsShowCard, selectCastBio, selectEnableWatch, selectEnableRecord, selectEnableBurnToDVD,
	selectEnableRemoteScheduling, selectPVRChannel,
	selectCompactMode
} from "../../UserSettingsPages/SettingsState/gridSettingsSlice";
import { selectChannelSort } from "../../GridPages/mainGridSlice";
import { GetDefaultUser } from "../../Global/GobalFunctions";
import { BaseLineup } from "../../../interfaces/settings";
import { selectLineups } from "../../UserSettingsPages/SettingsState/lineupsSlice";

const CloseButtonNewUser = () => {

	const settings: newUserSettings = {
		postalCode: useAppSelector(selectPostalCode),
		defaultLineupId: useAppSelector(selectDefaultLineup),
		gridSettings: {
			gridStartHour: parseInt(useAppSelector(selectStartTime)),
			gridDuration: useAppSelector(selectSettingMainGridDuration),
			channelSortOrder: useAppSelector(selectChannelSort),
			gridFontSize: useAppSelector(selectFontSize),
			hideNextGen: useAppSelector(selectHideNGChannels),
			wrapCellText: useAppSelector(selectWrapCellText),
			fixedCellHeight: useAppSelector(selectFixedCellHeight),
			includeLogos: useAppSelector(selectDisplayChannelLogo),
			includeShowCards: useAppSelector(selectDisplayShowCards),
			gridDailyWidth: useAppSelector(selectDailyGridWidth),
			gridColorScheme: useAppSelector(selectGridColorScheme),
			compactMode: useAppSelector(selectCompactMode)
		},
		cellSettings: {
			includeDescription: useAppSelector(selectCellDescription),
			includeEpisodeTitle: useAppSelector(selectEpsTitle),
			includeSeasonNumbers: useAppSelector(selectSeasonNumber),
			includeHDIndicator: useAppSelector(selectHDIndicator),
			includeNewRepeatIndicator: useAppSelector(selectNewRepeat),
			includeGenres: useAppSelector(selectGenreDisplay),
			includeMovieYear: useAppSelector(selectYearProduction),
			includeMovieRatings: useAppSelector(selectMovieRating),
			includeTVRatings: useAppSelector(selectTvRating),
			includeOriginalAirDate: true,
			includeProgramType: useAppSelector(selectProgramType),
			includeNextGenIndicator: useAppSelector(selectNextGenIndicator),
			includeAudioStreams: useAppSelector(selectAudioDescription),
			includeSocialMediaLinks: useAppSelector(selectSocialMediaLinks),
		},
		detailSettings: {
			includeSeriesDescription: useAppSelector(selectDetailsSynopsis),
			includeShowCards: useAppSelector(selectDetailsShowCard),
			includeCastAndCrewLinks: useAppSelector(selectCastBio)
		},
		pvrSettings: {
			enableWatch: useAppSelector(selectEnableWatch),
			enableRecord: useAppSelector(selectEnableRecord),
			enableBurnToDVD: useAppSelector(selectEnableBurnToDVD),
			enableRemoteScheduling: useAppSelector(selectEnableRemoteScheduling),
			channel: useAppSelector(selectPVRChannel)
		}
	}

	const userId = useAppSelector(selectUserId);
	const dispatch = useAppDispatch();
	const lineups = useAppSelector(selectLineups);
	const handleClose = () => {
		const defaultLineup = lineups.lineups[0]
		if (userId === GetDefaultUser()) {
			const eastcostlineup: BaseLineup = {
				providerId: defaultLineup.providerId,
				deviceTypeCode: defaultLineup.deviceType,
				providerName: defaultLineup.providerName,
				utcOffset: defaultLineup.utcOffset,
				observesDst: defaultLineup.observesDst,
			}
			dispatch(fetchNewGuestUser({ settings, lineup: eastcostlineup, setToPrimeTime: true }));
		}
		dispatch(setModalOpen());
	}

	return (
		<WhiteClose onClick={() => handleClose()}>
			Close <ModalIconSM src={Close} alt="" />
		</WhiteClose>
	)
}

export default CloseButtonNewUser