import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Channel } from '../../../interfaces/channels';
import { DataState, ModalState } from '../../../interfaces/enums';
import { SearchItem } from '../../../interfaces/search';
import { ClickableChannelCard } from '../../Global/GobalComponents';
import { eventFlyOverText, leadingZeroBuilder } from '../../Global/GobalFunctions';
import { setModalContent, setModalOpen } from '../../Modal/modalSlice';
import {
	FavoriteContainer, FavoriteUpcomingContainer, SettingsContainer, StyledUpcomingFavoriteBox,
	Understanding, UpcomingChannelNumber, UpcomingDetails, UpcomingEpisodeTitle, UpcomingEvent, UpcomingInnerBox,
	UpcomingTimeDate, UpcomingTitle, SettingsLabel, SettingsCheckBoxContainer
} from '../../UserSettingsPages/components/SettingsStyles';
import GridFailed from '../components/GridFailed';
import GridLoading from '../components/GridLoading';
import { ShowHD } from '../components/GridStyles';
import { selectColors } from '../gridColorsSlice';
import {
	fetchLineupChannels, selectGridLineupID, selectLineupChannels, selectMainGridChannelStatus
} from '../mainGridSlice';
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import {
	fetchSimpleSearchJSON, postSearchJSON, selectSearchTerms, selectSimpleSearchResults, selectSimpleSearchResultsStatus, setSearchTerm
} from '../searchAdditionAiringsSlice'
import { fetchEventDetails, setDetailsChannel } from '../detailsSlice';
import { BlueButton, CheckBox } from '../../Global/GobalStyledComponents';
import styled from 'styled-components';
import EventAttributes from '../components/EventAttributes';

const SearchResults = () => {
	const dispatch = useAppDispatch()
	const searchResults = useAppSelector(selectSimpleSearchResults);
	const searchStatus = useAppSelector(selectSimpleSearchResultsStatus);
	const channelStatus = useAppSelector(selectMainGridChannelStatus);
	const channels = useAppSelector(selectLineupChannels);
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const searchText: string = useAppSelector(selectSearchTerms);
	const [isAdvSearch, setIsAdvSearch] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (channelStatus !== DataState.SUCCEEDED) {
			dispatch(fetchLineupChannels({ userId: userId, lineupId: lineupId }))
		}

		return () => { dispatch(setSearchTerm('')) }
	}, [])

	const findChannel = (channelIndex: number): Channel => {
		let index = {} as Channel
		channels.channels.forEach((channel) => {
			if (channel.channelIndex === channelIndex) {
				index = channel;
			}
		})
		return index;
	}

	const handleSearch = () => {
		dispatch(postSearchJSON({ userId: userId, lineupId: lineupId, searchTerm: searchText, numberOfDays: 14, titleOnly: !isAdvSearch }));
	}


	return (
		<>
			<SettingsContainer>
				<SearchContainer>
					<SearchHeaderContainer>
						<SearchHeader>Search Results</SearchHeader>
						<SearchControls>
							<SettingsCheckBoxContainer>
								<CheckBox id='advSearch' defaultChecked={isAdvSearch} onChange={(e) => setIsAdvSearch(e.target.checked)} />
								<SearchLabel htmlFor='advSearch'>Expand search to title, description and cast</SearchLabel>
							</SettingsCheckBoxContainer>
							<SearchButton onClick={() => handleSearch()}>Search</SearchButton>
						</SearchControls>
					</SearchHeaderContainer>
					{(searchStatus === DataState.FAILED) ? <GridFailed errorCode={searchResults.errorCode} errorMessage={searchResults.errorMessage} /> :
						(searchStatus === DataState.SUCCEEDED) ?
							<UpcomingInnerBox>
								<FavoriteUpcomingContainer>
									{searchResults.searchItems.map((result => {
										return (<SearchItemDisplay key={result.eventId} SearchItem={result} channel={findChannel(result.channelIndex)} />)
									}))}
								</FavoriteUpcomingContainer>
							</UpcomingInnerBox>
							: <GridLoading />}
				</SearchContainer>
				<Understanding>
					<span>
						<b>About This Page:</b>
						<p>
							This page lists the programs in the current channel lineup that contain the word or name you searched for.
						</p>
						<p>
							A basic search looks in the programs' title, descriptions, and cast names for matching text.
						</p>
						<p>
							The results are sorted with the programs airing nearest to the current time first.
						</p>
						<p>
							Click on a program's title to view its Details box.
						</p>
						<p>
							Click on the TitanTV logo to return to the schedule grid.
						</p>
					</span>
				</Understanding>
			</SettingsContainer>
		</>
	)
}

// #region Search Results Styles
const SearchContainer = styled(FavoriteContainer)`
	max-width: 48%;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		max-width: 90%;
	}
`

const SearchLabel = styled(SettingsLabel)`
	margin: 12px 10px 0 0;
	font-weight: bold;
	user-select: none;
`

const SearchHeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		flex-direction: column;
	}
`

const SearchHeader = styled.div`
	font-weight: bold;
	font-size: 18px;
	margin-right: 20px;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		text-align: center;
	}
`

const SearchControls = styled.div`
	display: flex;
	flex-direction: row;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`

const SearchButton = styled(BlueButton)`
	margin: 0;
`
// #endregion

export default SearchResults

const SearchItemDisplay = (props: any) => {
	const searchItem: SearchItem = props.SearchItem;
	const channel: Channel = props.channel;
	const dispatch = useAppDispatch();
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID)


	const handleEventCardClick = (searchItem: SearchItem) => {
		const { channelIndex, eventId } = searchItem
		const data = { userId, lineupId, channelIndex, eventId }
		dispatch(setDetailsChannel(channel))
		dispatch(fetchEventDetails({ userId, lineupId, channelIndex, eventId }))
		dispatch(setModalContent(ModalState.EVENTDETAILS));
		dispatch(setModalOpen());
	}

	let AMPM = 'AM';
	let startTime = new Date(searchItem.startTime);
	let hour = startTime.getHours();
	let min = leadingZeroBuilder(startTime.getMinutes());
	const { GridColors } = useAppSelector(selectColors);
	type key = keyof typeof GridColors;
	let genre = searchItem.displayGenre;
	if (genre === 'Game/Quiz') { genre = 'Game_Quiz' }

	if (startTime.getHours() > 11) {
		AMPM = 'PM';
		hour = startTime.getHours() - 12;
	}

	if (hour === 0) { hour = 12; }

	const newRepeat = (str: string): string => {
		if (str === 'N') { return 'New' }
		if (str === 'R') { return 'Repeat' }
		return ''
	}

	const dayOfTheWeek = (day: number): string => {
		const daysOfWeek = [
			'Sun',
			'Mon',
			'Tue',
			'Wed',
			'Thur',
			'Fri',
			'Sat'
		]
		return daysOfWeek[day];
	}

	//const [displayParentheses, setDisplayParentheses] = useState(true)

	let displayParentheses = true
	if ((searchItem.isHD === false) && searchItem.newRepeat === '' && !searchItem.tvRating) {
		displayParentheses = false;
	}


	return (
		<StyledUpcomingFavoriteBox>
			<UpcomingTimeDate>
				<div>{`${dayOfTheWeek(startTime.getDay())} ${leadingZeroBuilder(startTime.getMonth() + 1)}/${leadingZeroBuilder(startTime.getDate())}`}</div>
				<div>{`${hour}:${min} ${AMPM}`}</div>
			</UpcomingTimeDate>
			<ClickableChannelCard channel={channel} />
			<UpcomingChannelNumber>
				{channel.majorChannel > 0 ? channel.majorChannel + '.' + channel.minorChannel : channel.rfChannel}
			</UpcomingChannelNumber>
			<UpcomingEvent color={GridColors[genre as key]} onClick={() => handleEventCardClick(searchItem)} title={eventFlyOverText(searchItem.title, searchItem.episodeTitle, true)} >
				<UpcomingTitle>{searchItem.title}</UpcomingTitle>
				{searchItem.title !== searchItem.episodeTitle ?
					<UpcomingEpisodeTitle>{searchItem.episodeTitle}</UpcomingEpisodeTitle>
					: ''
				}
				{/* <EventAttributes event={searchItem} showAirDate={false} /> */}
				<UpcomingDetails>
					{/* {displayParentheses ? '(' : ''} */}
					{searchItem.isHD ? <ShowHD>HD </ShowHD> : ''}
					{searchItem.newRepeat ? `${newRepeat(searchItem.newRepeat)} ` : ''}
					{searchItem.tvRating === '' ? '' : searchItem.tvRating}
					{!(searchItem.tvRating === '' && searchItem.mpaaRating === '' ? `` : '')}
					{searchItem.mpaaRating === '' ? '' : searchItem.mpaaRating}
					{/* {displayParentheses ? ')' : ''} */}
				</UpcomingDetails>
			</UpcomingEvent>
		</StyledUpcomingFavoriteBox>
	)
}