import { useLocation } from 'react-router-dom'
import { StyledTabBar, Tab } from '../../UserSettingsPages/components/SettingsStyles'

const LegalTabBar = () => {

	const path = useLocation().pathname;

	return (
		<StyledTabBar>
			<Tab to='/terms' $current={path === "/terms" ? true : false} dvr={false}>Terms of Service</Tab>
			<Tab to='/privacy' $current={path === "/privacy" ? true : false} dvr={false}>Privacy</Tab>
			<Tab to='/ccpa' $current={path === "/ccpa" ? true : false} dvr={false}>CCPA</Tab>
			<Tab to='/donotsell' $current={path === "/donotsell" ? true : false} dvr={false}>Do Not Sell My Info</Tab>
		</StyledTabBar>
	)
}

export default LegalTabBar