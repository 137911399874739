import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Channel } from '../../../interfaces/channels'
import { Event } from '../../../interfaces/schedule';
import { selectColors } from '../gridColorsSlice';
import { setModalContent, setModalOpen } from '../../Modal/modalSlice'
import { DataState, ModalState } from '../../../interfaces/enums'
import { CreateUUID, eventFlyOverText, fontSizeCalculation } from '../../Global/GobalFunctions'
import {
	StyledDailyEventCard, StyledDailyProgramColumn
} from './DailyGridStyles';
import {
	selectCellDescription, selectDisplayShowCards, selectEpsTitle, selectFontSize, selectMovieRating, selectSeasonNumber,
	selectAudioDescription, selectSocialMediaLinks
} from '../../UserSettingsPages/SettingsState/gridSettingsSlice'
import {
	EventDescription, EventTitle, EventDetailsContainer, EventCard, EpsTitle, TitleContainer, FavIcon, EventContainerDetailsClick
} from './GridStyles'
import { selectGridLineupID } from '../mainGridSlice'
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import { fetchEventDetails, setDetailsChannel, } from '../detailsSlice';
import { selectFavorites, selectFavoritesStatus } from '../../UserSettingsPages/SettingsState/favoritesRemidersSlice';
import RedHeart from '../../../img/Icons/Red Icons/TitanTV Icon_Heart_Red.svg';
import { ProgramFlag } from './eventDetails/EventDetailsStyles';
import AD from '../../../img/StatsFlags/AudioDes.svg';
import StarRating from './StarRating';
import SocialMediaLinks from './SocialMediaLinks';
import EventAttributes from './EventAttributes';

const handleEventCardClick = (dispatch: any, userID: string, lineupID: string, channelIndex: number, eventID: number, channel: Channel) => {
	dispatch(setDetailsChannel(channel))
	dispatch(fetchEventDetails({ userId: userID, lineupId: lineupID, channelIndex: channelIndex, eventId: eventID }));
	dispatch(setModalContent(ModalState.EVENTDETAILS));
	dispatch(setModalOpen());
}

const totalHeight = 4800;

const DailyProgramColumn = (props: any) => {
	const events: Event[] = props.DayOfEvents;
	const length = events.length;
	const channelIndex = props.channelIndex;
	const chl = props.Channel
	const dayIndex: number = props.dayIndex;

	return (
		<StyledDailyProgramColumn totalHeight={'' + totalHeight}>
			{events.map((e: Event, i: number) =>
				<DailyEventCard key={CreateUUID()} title='Click to view details' Event={e} Length={length} channelIndex={channelIndex} Channel={chl} index={i} dayIndex={dayIndex} />
			)
			}
		</StyledDailyProgramColumn>
	)
}

const DailyEventCard = (props: any) => {
	const dispatch = useAppDispatch();
	const userID = useAppSelector(selectUserId);
	const lineupID = useAppSelector(selectGridLineupID);
	const displayShowCard = useAppSelector(selectDisplayShowCards);
	const showMovieRating = useAppSelector(selectMovieRating);
	const showDescription = useAppSelector(selectCellDescription);
	const showEpsTitle = useAppSelector(selectEpsTitle);
	const fontModifer = useAppSelector(selectFontSize);
	const showMediaLinks = useAppSelector(selectSocialMediaLinks);
	const favs = useAppSelector(selectFavorites);
	const favStatus = useAppSelector(selectFavoritesStatus);
	const showAudioDes = useAppSelector(selectAudioDescription);

	const e: Event = props.Event;
	const channelIndex: number = props.channelIndex;
	const chl = props.Channel;
	const index = props.index;
	const length = props.Length;
	const dayIndex: number = props.dayIndex;
	const dayLength = 1440;

	let visableDuration = e.duration / 60;
	const { GridColors } = useAppSelector(selectColors);
	type key = keyof typeof GridColors;
	let genre = e.displayGenre;
	let isFav = false;
	if (genre === 'Game/Quiz') { genre = 'Game_Quiz' }
	if (genre === 'Sci-Fi') { genre = 'SciFi' }

	// Start of Day column visableDuration handling
	if (index === 0) {
		let start = new Date();
		start.setDate(start.getDate() + dayIndex)
		start.setHours(0, 0, 0);
		let startTime = new Date(e.startTime);
		let diff = +start - +startTime
		diff = Math.floor((diff / 1000) / 60);
		visableDuration = visableDuration - diff
	}

	// End of Day column visableDuration handling
	if ((index + 1) === length) {
		const oneMoreDay = 1;
		let end = new Date();
		end.setDate(end.getDate() + (dayIndex + oneMoreDay));
		end.setHours(0, 0, 0)
		let startTime = new Date(e.startTime);
		let diff = Math.floor(end.getTime() - startTime.getTime()) / 1000;
		visableDuration = Math.floor(diff / 60)
	}

	let percent = (visableDuration / dayLength);
	let cellHeight = percent * totalHeight;

	const fontSize = fontSizeCalculation(parseFloat(fontModifer));

	for (let i = 0; i < favs.favorites.length; i++) {
		if (favs.favorites[i].title === e.title) {
			isFav = true;
			break;
		}
	}

	let isAudioDes = false;
	if (e.audioStreams[0]) {
		e.audioStreams.forEach(as => {
			if (as.audioServiceType === 'visually_impaired') {
				isAudioDes = true;
			}
		});
	}

	return (
		<StyledDailyEventCard duration={'' + cellHeight} color={GridColors[genre as key]} key={e.eventId}>
			<EventContainerDetailsClick
				onClick={() => { handleEventCardClick(dispatch, userID, lineupID, channelIndex, e.eventId, chl) }}
				title={eventFlyOverText(e.title, e.episodeTitle, true)}
			>
				{displayShowCard ?
					<EventCard src={e.showCard} onError={(event) => event.currentTarget.style.display = 'none'} /> : ''
				}
				<EventDetailsContainer display={true}>
					<TitleContainer>
						<EventTitle fontSize={fontSize + 'px'}>
							{e.title}
						</EventTitle>
						{(favStatus === DataState.SUCCEEDED && isFav) ? <FavIcon src={RedHeart} /> : ''}
					</TitleContainer>
					{showEpsTitle ? <EpsTitle fontSize={fontSize + 'px'}>{e.episodeTitle}</EpsTitle> : ''}
					<EventAttributes Event={e} />
					<EventDescription fontSize={fontSize + 'px'}>
						{(e.description && showDescription) ? e.description : ''}
					</EventDescription>
					{isAudioDes && showAudioDes ? <ProgramFlag title='Audio Descriptions' src={AD} /> : ''}
					{showMovieRating ? <StarRating StarRating={e.starRating} /> : ''}
					{showMediaLinks ? '' : ''}
				</EventDetailsContainer>
			</EventContainerDetailsClick>
			{showMediaLinks ? <SocialMediaLinks links={e.links} /> : ''}
		</StyledDailyEventCard>
	)
}

export default DailyProgramColumn