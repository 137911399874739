import { useEffect, useState } from 'react';
import { BlueButton, CheckBox } from '../../Global/GobalStyledComponents';
import {
	SettingsContainer, SettingsOptionsContainer, Understanding, SettingsLabel, CellSettingsCheckBoxContainer,
	CellSettingsContainer, CellSettingsOptionsContainer, CellSettingsHeader, SaveButtonContainer, SaveMessage,
	ReturnToGrid, DataError, DetailsSettingHeader, CompactModeWarning
} from '../components/SettingsStyles';
import { selectChannelSort } from '../../GridPages/mainGridSlice';
import { selectUserId } from '../SettingsState/userSlice';
import {
	selectAudioDescription, selectCastBio, selectCellDescription, selectDefaultLineup, selectDetailsShowCard,
	selectDisplayChannelLogo, selectDisplayShowCards, selectEpsTitle, selectFixedCellHeight, selectFontSize,
	selectHDIndicator, selectHideNGChannels, selectMovieRating, selectNewRepeat, selectSocialMediaLinks,
	selectNextGenIndicator, selectPostalCode, selectProgramType, selectSeasonNumber, selectTvRating,
	setCellDescription, setHDIndicator, setSeasonNumber, setGenreDisplay, setMovieRating, setProgramType,
	setNewRepeat, setYearProduction, setTvRating, selectCompactMode, setSocialMediaLinks, setDetailsSynopsis,
	selectDetailsSynopsis, selectGenreDisplay, selectStartTime, setCastBio, selectWrapCellText, selectYearProduction,
	setAudioDescription, setEpsTitle, setDetailsShowCard, postUserSettings, selectDailyGridWidth, selectGridColorScheme,
	selectEnableWatch, selectEnableRecord, selectEnableBurnToDVD, selectEnableRemoteScheduling, selectPVRChannel,
	selectSettingMainGridDuration,
} from '../SettingsState/gridSettingsSlice';
import TabBar from '../components/TabBar';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { PostUserSettings } from '../../../interfaces/settings';
import { GetDefaultUser } from '../../Global/GobalFunctions';
import BecomeMemberText from '../components/BecomeMemberText';

const CellSettings = () => {
	const dispatch = useAppDispatch();
	const description = useAppSelector(selectCellDescription)
	const HDIndicator = useAppSelector(selectHDIndicator);
	const genre = useAppSelector(selectGenreDisplay);
	const movieRating = useAppSelector(selectMovieRating);
	const programType = useAppSelector(selectProgramType);
	const audioIndicator = useAppSelector(selectAudioDescription);
	const epsTitle = useAppSelector(selectEpsTitle);
	const seasonNumber = useAppSelector(selectSeasonNumber);
	const newRepeat = useAppSelector(selectNewRepeat);
	const yearProd = useAppSelector(selectYearProduction);
	const tvRatings = useAppSelector(selectTvRating);
	const nextGenIndicator = useAppSelector(selectNextGenIndicator);
	const socialMediaLinks = useAppSelector(selectSocialMediaLinks);
	const detailsSynopsis = useAppSelector(selectDetailsSynopsis);
	const detailsShowCard = useAppSelector(selectDetailsShowCard);
	const detailsCast = useAppSelector(selectCastBio);
	const compactMode = useAppSelector(selectCompactMode)
	const userId = useAppSelector(selectUserId);

	const [displayCellSaveMessage, setDisplayCellSaveMessage] = useState(false);
	const postSettings: PostUserSettings = {
		postalCode: useAppSelector(selectPostalCode),
		defaultLineupId: useAppSelector(selectDefaultLineup),
		userId: userId,
		gridSettings: {
			gridStartHour: parseInt(useAppSelector(selectStartTime)),
			gridDuration: useAppSelector(selectSettingMainGridDuration),
			channelSortOrder: useAppSelector(selectChannelSort),
			gridFontSize: useAppSelector(selectFontSize),
			hideNextGen: useAppSelector(selectHideNGChannels),
			wrapCellText: useAppSelector(selectWrapCellText),
			fixedCellHeight: useAppSelector(selectFixedCellHeight),
			includeLogos: useAppSelector(selectDisplayChannelLogo),
			includeShowCards: useAppSelector(selectDisplayShowCards),
			gridDailyWidth: useAppSelector(selectDailyGridWidth),
			gridColorScheme: useAppSelector(selectGridColorScheme),
			compactMode: compactMode
		},
		cellSettings: {
			includeDescription: useAppSelector(selectCellDescription),
			includeEpisodeTitle: useAppSelector(selectEpsTitle),
			includeSeasonNumbers: useAppSelector(selectSeasonNumber),
			includeHDIndicator: useAppSelector(selectHDIndicator),
			includeNewRepeatIndicator: useAppSelector(selectNewRepeat),
			includeGenres: useAppSelector(selectGenreDisplay),
			includeMovieYear: useAppSelector(selectYearProduction),
			includeMovieRatings: useAppSelector(selectMovieRating),
			includeTVRatings: useAppSelector(selectTvRating),
			includeOriginalAirDate: true,
			includeProgramType: useAppSelector(selectProgramType),
			includeNextGenIndicator: nextGenIndicator,
			includeAudioStreams: useAppSelector(selectAudioDescription),
			includeSocialMediaLinks: useAppSelector(selectSocialMediaLinks),
		},
		detailSettings: {
			includeSeriesDescription: useAppSelector(selectDetailsSynopsis),
			includeShowCards: useAppSelector(selectDetailsShowCard),
			includeCastAndCrewLinks: useAppSelector(selectCastBio)
		},
		pvrSettings: {
			enableWatch: useAppSelector(selectEnableWatch),
			enableRecord: useAppSelector(selectEnableRecord),
			enableBurnToDVD: useAppSelector(selectEnableBurnToDVD),
			enableRemoteScheduling: useAppSelector(selectEnableRemoteScheduling),
			channel: useAppSelector(selectPVRChannel)
		}
	}

	const handleSaveSettings = () => {
		if (userId === GetDefaultUser()) {
			setDisplayCellSaveMessage(true);
			return;
		}
		setDisplayCellSaveMessage(true);
		dispatch(postUserSettings(postSettings));
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		return () => {
			setDisplayCellSaveMessage(false);
		}
	}, [])


	return (
		<>
			<TabBar />
			<SettingsContainer>
				<CellSettingsOptionsContainer>
					{compactMode ? <CompactModeWarning>
						When in Compact Mode, the main grid page displays only Program and Episode Title, and New/Repeat Status.
						<br />
						<br />
						Any other selected program attributes are displayed in a channel's Daily View that is opened by clicking on its call sign.
					</CompactModeWarning> : ''}
					<SettingsOptionsContainer>
						<CellSettingsContainer>
							<CellSettingsHeader>
								Program Cell Options
							</CellSettingsHeader>
							<SettingsOptionsContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='CellDesc' defaultChecked={description} onChange={(e) => dispatch(setCellDescription())} />
									<SettingsLabel htmlFor='CellDesc'>Descriptions</SettingsLabel>
								</CellSettingsCheckBoxContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='SeasonNumber' defaultChecked={seasonNumber} onChange={(e) => dispatch(setSeasonNumber())} />
									<SettingsLabel htmlFor='SeasonNumber'>Display Season Number</SettingsLabel>
								</CellSettingsCheckBoxContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='CellHD' defaultChecked={HDIndicator} onChange={(e) => dispatch(setHDIndicator())} />
									<SettingsLabel htmlFor='CellHD'>HDTV Indicator</SettingsLabel>
								</CellSettingsCheckBoxContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='Genres' defaultChecked={genre} onChange={(e) => dispatch(setGenreDisplay())} />
									<SettingsLabel htmlFor='Genres'>Program Genres</SettingsLabel>
								</CellSettingsCheckBoxContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='MovieRatings' defaultChecked={movieRating} onChange={(e) => dispatch(setMovieRating())} />
									<SettingsLabel htmlFor='MovieRatings'>Movie Rating & Stars</SettingsLabel>
								</CellSettingsCheckBoxContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='ProgramType' defaultChecked={programType} onChange={(e) => dispatch(setProgramType())} />
									<SettingsLabel htmlFor='ProgramType'>Program Type</SettingsLabel>
								</CellSettingsCheckBoxContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='AudioDesc' defaultChecked={audioIndicator} onChange={(e) => dispatch(setAudioDescription())} />
									<SettingsLabel htmlFor='AudioDesc'>Include Audio Descriptions Indicator</SettingsLabel>
								</CellSettingsCheckBoxContainer>
							</SettingsOptionsContainer>
							<SettingsOptionsContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='EpsTitle' defaultChecked={epsTitle} onChange={(e) => dispatch(setEpsTitle())} />
									<SettingsLabel htmlFor='EpsTitle'>Episode Titles</SettingsLabel>
								</CellSettingsCheckBoxContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='NewRepeat' defaultChecked={newRepeat} onChange={(e) => dispatch(setNewRepeat())} />
									<SettingsLabel htmlFor='NewRepeat'>New/Repeat Status</SettingsLabel>
								</CellSettingsCheckBoxContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='Year' defaultChecked={yearProd} onChange={(e) => dispatch(setYearProduction())} />
									<SettingsLabel htmlFor='Year'>Year of Production</SettingsLabel>
								</CellSettingsCheckBoxContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='TVRatings' defaultChecked={tvRatings} onChange={(e) => dispatch(setTvRating())} />
									<SettingsLabel htmlFor='TVRatings'>Include TV Ratings</SettingsLabel>
								</CellSettingsCheckBoxContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='CellSocial' defaultChecked={socialMediaLinks} onChange={(e) => dispatch(setSocialMediaLinks())} />
									<SettingsLabel htmlFor='CellSocial'>Social Media Links</SettingsLabel>
								</CellSettingsCheckBoxContainer>
							</SettingsOptionsContainer>
						</CellSettingsContainer>
					</SettingsOptionsContainer>
					<SettingsOptionsContainer>
						<CellSettingsContainer>
							<DetailsSettingHeader>
								Details Options
							</DetailsSettingHeader>
							<SettingsOptionsContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='DetailsSynopsis' defaultChecked={detailsSynopsis} onChange={(e) => dispatch(setDetailsSynopsis())} />
									<SettingsLabel htmlFor='DetailsSynopsis'>Display Program's Basic Synopsis</SettingsLabel>
								</CellSettingsCheckBoxContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='ShowCardsDetails' defaultChecked={detailsShowCard} onChange={(e) => dispatch(setDetailsShowCard())} />
									<SettingsLabel htmlFor='ShowCardsDetails'>Display Show Cards in the Details Page</SettingsLabel>
								</CellSettingsCheckBoxContainer>
							</SettingsOptionsContainer>
							<SettingsOptionsContainer>
								<CellSettingsCheckBoxContainer>
									<CheckBox id='CastBioDetails' defaultChecked={detailsCast} onChange={(e) => dispatch(setCastBio())} />
									<SettingsLabel htmlFor='CastBioDetails'>Links to Cast & Credits Bio Details</SettingsLabel>
								</CellSettingsCheckBoxContainer>
							</SettingsOptionsContainer>
						</CellSettingsContainer>
					</SettingsOptionsContainer>

					<SaveButtonContainer>
						<BlueButton onClick={() => handleSaveSettings()}>Save Changes</BlueButton>
						<SaveMessage show={displayCellSaveMessage}>
							{userId === GetDefaultUser() ? <DataError>You must create a user to update settings</DataError> : 'Your Settings Have Been Saved'}
							{userId === GetDefaultUser() ?
								<BecomeMemberText />
								:
								<ReturnToGrid to='/'>
									Return to TV Listing
								</ReturnToGrid>}
						</SaveMessage>
					</SaveButtonContainer>

				</CellSettingsOptionsContainer>
				<Understanding>
					<span>
						<b>About This Page:</b>
						<p>This page contains determines which programming details are displayed in each program's grid cell.</p>
						<p>Program Cell Options: Controls the amount of program attributes to display in the grid.</p>
						<p>Details Page Options: Determines whether images and additional information will be included in each program's Details page.</p>
						<p>Click the Save Changes button to put the grid settings into effect.</p>
					</span>
				</Understanding>
			</SettingsContainer>
		</>
	)
}

export default CellSettings