import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { DataState } from '../../../interfaces/enums'
import {
	selectMainGridStatus, selectMainSchedule, selectMainGridChannelStatus, selectLineupChannels, selectGridLineupID,
	selectMainGridDuration, selectGridYear, selectGridMonth, selectGridDay, selectGridTime, fetchSchedule, setMainGridStatus
} from '../mainGridSlice'
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice'
import ChannelRow from './ChannelRow'
import GridFailed from './GridFailed'
import { StyledMainGridComponent, AdWrapper } from './GridStyles'
import MainGridTimeBar from './MainGridTimeBar'
import { useEffect } from 'react'
import {
	selectFilterGrid, selectGenreFilter, selectMoviesDisplay, selectMyFavoritesDisplay, selectNewProgramDisplay
} from '../../Modal/filterDisplaySlice'
import { CreateUUID, filterEventShouldDisplay } from '../../Global/GobalFunctions'
import { FreestarAd } from '../../Freestar/FreestarAd';
import PrimisMain from '../../Freestar/Primis';
import { DataError } from '../../UserSettingsPages/components/SettingsStyles'
import { handleErrorMessage } from '../../Global/ErrorMessage'
import { selectFavorites } from '../../UserSettingsPages/SettingsState/favoritesRemidersSlice'
import { ScheduleChannel } from '../../../interfaces/schedule'
import { selectHideNGChannels } from '../../UserSettingsPages/SettingsState/gridSettingsSlice'

const MainGridComponent = () => {
	const scheduleStatus = useAppSelector(selectMainGridStatus);
	const schedule = useAppSelector(selectMainSchedule);
	const channnelStatus = useAppSelector(selectMainGridChannelStatus);
	const channels = useAppSelector(selectLineupChannels);
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const mainGridDuration = useAppSelector(selectMainGridDuration);
	const year = useAppSelector(selectGridYear);
	const month = useAppSelector(selectGridMonth);
	const day = useAppSelector(selectGridDay);
	const hour = useAppSelector(selectGridTime);
	const hideNG = useAppSelector(selectHideNGChannels);
	const dispatch = useAppDispatch();
	let adCounter = 0;
	// #region filter controls
	const filterGrid = useAppSelector(selectFilterGrid);
	const displayNewProg = useAppSelector(selectNewProgramDisplay);
	const displayFav = useAppSelector(selectMyFavoritesDisplay);
	const displayMovies = useAppSelector(selectMoviesDisplay);
	const genreFilter = useAppSelector(selectGenreFilter);
	const favorites = useAppSelector(selectFavorites);

	const filterChannels = (schedule: ScheduleChannel): boolean => {
		if (!filterGrid) { return true; }
		let display = false;
		if (schedule.days.length === 0) { return false; }
		const favs = favorites.favorites

		for (let i = 0; i < schedule.days[0].events.length; i++) {
			let event = schedule.days[0].events[i]
			let isFav = false;

			for (let j = 0; j < favs.length; j++) {
				if (favs[j].title === event.title) { isFav = true; break; }
			}

			display = filterEventShouldDisplay({
				displayGenre: genreFilter,
				displayNewProgram: displayNewProg,
				displayMovies: displayMovies,
				displayFavorites: displayFav
			}, isFav, event);
			if (display) { return true; }
		}
		return display;
	}
	// #endregion
	useEffect(() => {
		// if ((schedule === undefined || schedule.channels[0] === undefined) && scheduleStatus !== DataState.LOADING) {
		// 	const timeString = `${year}${month}${day}${hour}00`;
		// 	dispatch(fetchSchedule({ userId: userId, lineupId: lineupId, startTimeToken: timeString, duration: mainGridDuration * 60 }));
		// }
		// eslint-disable-next-line
	}, []);

	const findEvents = (channelIndex: number): number => {
		let scheduleIndex = 0;
		for (let i = 0; i < schedule.channels.length; i++) {
			if (schedule.channels[i].channelIndex === channelIndex) {
				scheduleIndex = i;
				break;
			}
		}
		return scheduleIndex;
	}

	//This might be causeing more channel calls then needed
	if (channels.channels[0].channelId === 0) { dispatch(setMainGridStatus(DataState.SUCCEEDED)) }

	return (
		<StyledMainGridComponent>
			{(scheduleStatus === DataState.FAILED || channnelStatus === DataState.FAILED) ? <GridFailed errorCode={schedule.errorCode} errorMessage={schedule.errorMessage} /> :
				(scheduleStatus === DataState.SUCCEEDED || channnelStatus === DataState.SUCCEEDED) ?
					channels.errorCode !== 0 || schedule.errorCode !== 0 ?
						channels.errorCode !== 0 ? <DataError>{handleErrorMessage(channels.errorCode)}</DataError> :
							<DataError>{handleErrorMessage(schedule.errorCode)}</DataError> :
						channels.channels.map((channel, i) => {
							let scheduleChannel = schedule.channels[findEvents(channel.channelIndex)];
							// This area is for the filter to hide channels that don't have any shows when the filter is applied
							let display = true;
							if (filterGrid) {
								display = filterChannels(scheduleChannel);
							}
							if (hideNG && channel.isNextGen) { display = false; }
							// Time Bar every 10th row but not if the last row is less then 5 away
							if (display) {
								if ((i + 1) % 10 === 0 && (channels.channels.length - i) > 5) {
									adCounter++;
									let slotId = 'titantv_grid_dynamic' + adCounter.toString();
									return (<span key={channel.channelId + ':' + CreateUUID()}>
										<ChannelRow Channel={channel} Schedule={schedule.channels[findEvents(channel.channelIndex)]} index={i} />
										<AdWrapper><FreestarAd placementName={'titantv_grid_dynamic'} slotId={slotId}></FreestarAd></AdWrapper>
										<MainGridTimeBar isLast={false} />
									</span>)
								}
								// Time Bar at the end
								if (i + 1 === channels.channels.length) {
									if (adCounter > -1) {
										return (
											<span key={channel.channelId + ':' + CreateUUID()}>
												<ChannelRow Channel={channel} Schedule={schedule.channels[findEvents(channel.channelIndex)]} shouldDisplay={display} index={i} />
												<MainGridTimeBar isLast={true} isFirst={false} />
											</span>)
									}
									return (
										<span key={channel.channelId + ':' + CreateUUID()}>
											<ChannelRow Channel={channel} Schedule={schedule.channels[findEvents(channel.channelIndex)]} shouldDisplay={display} index={i} />
											<AdWrapper><PrimisMain /></AdWrapper>
											<MainGridTimeBar isLast={true} isFirst={false} />
										</span>)
								}
								return <ChannelRow key={channel.channelId + ':' + CreateUUID()} shouldDisplay={display} Channel={channel} Schedule={schedule.channels[findEvents(channel.channelIndex)]} index={i} />
							} else {
								return ('')
							}
						})
					:
					<GridFailed errorCode={schedule.errorCode} errorMessage={schedule.errorMessage} />
			}
		</StyledMainGridComponent>
	)
}

export default MainGridComponent
