import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectRunPrmisPlayer, setRunPrimisPlayer } from '../Header/headerSlice';
import { consoleLog } from '../Global/ConsoleLogging';

const PrimisMain = () => {
	const refCont = useRef<HTMLDivElement>(null);
	const run = useAppSelector(selectRunPrmisPlayer);
	const dispatch = useAppDispatch();
	//const [run, setRun] = useState(true);

	useEffect(() => {
		consoleLog('Primis Video Player useEffect', true)
		if (refCont.current && run) {
			consoleLog('Primis Script Load', true)
			const script = document.createElement('script');
			script.setAttribute('src', './PrimisScript.js');
			refCont.current.append(script);
			//setRun(false);
			dispatch(setRunPrimisPlayer(false));
		}

		// return () => {
		// 	const scriptUnload = document.createElement('script');
		// 	freestar.queue.push(function () { freestar.deleteVideo() });
		// 	//scriptUnload.setAttribute('src', './PrimisScriptUnload.js');
		// 	//refCont.current.append(scriptUnload);
		// }
	}, [])

	return (
		<Wrapper>
			<PlayerDiv id="FreeStarVideoAdContainer">
				<div id='freestar-video-parent'>
					<div id='freestar-video-child' ref={refCont}>

					</div>
				</div>
			</PlayerDiv>
		</Wrapper>
	);
}

export default PrimisMain

export const Wrapper = styled.div`
  width: 100%;
  padding:0;
  display: flex;
  justify-content: center;

	@media print {
		display: none;
	}
`;

export const PlayerDiv = styled.div`
  display: inline-block;
  max-width: 600px;
  max-height: 340px;
`;