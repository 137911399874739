
import * as React from 'react';
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component';
import styled from 'styled-components';

//import './demo.css';

interface IProps {
	placementName: string
	slotId?: string;
}
interface IState { }

export class FreestarAd extends React.Component<IProps, IState> {

	render() {
		const { placementName, slotId } = this.props;

		//const placementName = 'PublisherName_970x250_728x90_320x50';
		//const slotId = 'in_content_ad_1';
		const publisher = 'titantv-com';
		//const targeting = { key1: 'value1', key2: 'value2' };
		//const { adRefresh } = this.state;

		return (
			<AdWrappper key={slotId ? slotId : placementName}>
				<FreestarAdSlot
					publisher={publisher}
					placementName={placementName}
					slotId={slotId ? slotId : placementName}
					//targeting={targeting}
					//channel='custom_channel'
					classList={['m-30', 'p-15', 'b-thin-red']}
					onNewAdSlotsHook={(placementName: any) => console.log('creating ad', placementName)}
					onDeleteAdSlotsHook={(placementName: any) => console.log('destroying ad', placementName)}
					onAdRefreshHook={(placementName: any) => console.log('refreshing ad', placementName)}
				/>
				{/*<button onClick={this.onAdRefresh}>Trigger Refresh</button>*/}
			</AdWrappper>
		);
	}
}

const AdWrappper = styled.div`
@media print {
	display: none;
}
`