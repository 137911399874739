import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { ColorType, DataState, SortType } from "../../../interfaces/enums";
import { UserSettings, PostUserSettings } from "../../../interfaces/settings";
import { getBaseUrl } from "../../Global/GobalFunctions";
import { errorLog } from "../../Global/ConsoleLogging";

export interface gridSettings {
	postalCode: string;
	startTime: string;
	gridMainDuration: number;
	fontSize: string;
	hideNGCHannels: boolean;
	wrapCellText: boolean;
	fixedCellHeight: boolean;
	displayChannelLogo: boolean;
	displayShowCards: boolean;
	dailyGridWidth: string;
	settingsSort: SortType;
	compactMode: boolean;

	cellDescription: boolean;
	HDIndicator: boolean;
	genre: boolean;
	movieRating: boolean;
	programType: boolean;
	audioDescription: boolean;
	EpsTitle: boolean;
	SeasonNumber: boolean;
	newRepeat: boolean;
	yearProduction: boolean;
	tvRating: boolean;
	nextGenIndicator: boolean;
	socialMediaLinks: boolean;
	detailsSynopsis: boolean;
	detailsShowCard: boolean;
	castBio: boolean;
	gridColorScheme: ColorType;
	defaultLineup: string;

	enableWatch: boolean;
	enableRecord: boolean;
	enableBurnToDVD: boolean;
	enableRemoteScheduling: boolean;
	pvrChannel: number;

	displayWatch: boolean;
	displayRecord: boolean;
	displayBurn: boolean;
	displayICal: boolean;

	userSettingsStatus: DataState;
	postSettingsStatus: DataState;
	settingErrorCode: number;
	settingsErrorMessage: string;
}

const initialState: gridSettings = {
	postalCode: '',
	startTime: '-1',
	gridMainDuration: 3,
	fontSize: '1',
	hideNGCHannels: false,
	wrapCellText: false,
	fixedCellHeight: true,
	displayChannelLogo: true,
	displayShowCards: true,
	dailyGridWidth: '3',
	settingsSort: SortType.LINEUP,
	compactMode: false,

	cellDescription: true,
	HDIndicator: true,
	genre: true,
	movieRating: true,
	programType: true,
	audioDescription: true,
	EpsTitle: true,
	SeasonNumber: true,
	newRepeat: true,
	yearProduction: true,
	tvRating: true,
	nextGenIndicator: false,
	socialMediaLinks: false,
	detailsSynopsis: true,
	detailsShowCard: true,
	castBio: true,
	gridColorScheme: ColorType.TITANTVSTANDARD,
	userSettingsStatus: DataState.IDLE,
	defaultLineup: '',

	enableWatch: true,
	enableRecord: true,
	enableBurnToDVD: true,
	enableRemoteScheduling: true,
	pvrChannel: 0,

	displayWatch: true,
	displayRecord: true,
	displayBurn: true,
	displayICal: true,

	postSettingsStatus: DataState.IDLE,

	settingErrorCode: 0,
	settingsErrorMessage: '',
};

const baseUrl = getBaseUrl();

const setUserSettings = (state: any, data: UserSettings) => {
	let { postalCode, defaultLineupId, gridSettings, cellSettings, detailSettings, pvrSettings } = data;
	state.postalCode = postalCode;
	state.defaultLineup = defaultLineupId;

	state.startTime = gridSettings.gridStartHour + '';
	if (gridSettings.gridDuration < 1 || gridSettings.gridDuration > 6) {
		state.gridMainDuration = 3
	} else {
		state.gridMainDuration = gridSettings.gridDuration;
	}

	if (parseInt(gridSettings.gridFontSize) === Number.NaN || parseInt(gridSettings.gridFontSize) === 0) {
		state.fontSize = '1';
	} else {
		state.fontSize = gridSettings.gridFontSize + '';
	}

	state.hideNextGenChannels = gridSettings.hideNextGen;
	state.wrapCellText = gridSettings.wrapCellText;
	state.fixedCellHeight = gridSettings.fixedCellHeight;
	state.displayChannelLogo = gridSettings.includeLogos;
	state.displayShowCards = gridSettings.includeShowCards;
	state.dailyGridWidth = gridSettings.gridDailyWidth + '';
	state.settingsSort = gridSettings.channelSortOrder;
	state.gridColorScheme = gridSettings.gridColorScheme;
	state.compactMode = gridSettings.compactMode;

	state.cellDescription = cellSettings.includeDescription;
	state.HDIndicator = cellSettings.includeHDIndicator;
	state.genre = cellSettings.includeGenres;
	state.movieRating = cellSettings.includeMovieRatings;
	state.programType = cellSettings.includeProgramType;
	state.audioDescription = cellSettings.includeAudioStreams;
	state.EpsTitle = cellSettings.includeEpisodeTitle;
	state.SeasonNumber = cellSettings.includeSeasonNumbers;
	state.newRepeat = cellSettings.includeNewRepeatIndicator;
	state.yearProduction = cellSettings.includeMovieYear;
	state.tvRating = cellSettings.includeTVRatings;
	state.nextGenIndicator = cellSettings.includeNextGenIndicator;
	state.socialMediaLinks = cellSettings.includeSocialMediaLinks;

	state.detailsSynopsis = detailSettings.includeSeriesDescription;
	state.detailsShowCard = detailSettings.includeShowCards;
	state.castBio = detailSettings.includeCastAndCrewLinks;

	state.enableWatch = pvrSettings.enableWatch;
	state.enableRecord = pvrSettings.enableRecord;
	state.enableRemoteScheduling = pvrSettings.enableRemoteScheduling;
	state.enableBurnToDVD = pvrSettings.enableBurnToDVD;
	state.pvrChannel = pvrSettings.channel;
}

/**
 * preference/{userId}
 * @param userID {string} the userID of for the Lineup List
 * @returns the default user default lineup and sets the rest of the settings
 */
export const fetchUserSettings = createAsyncThunk('settings/fetchUserSettings', async (userID: string) => {
	return fetch(baseUrl + 'preference/' + userID)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`User Settings Error:: ${err}`, true))
});

/**
 * preference/{userId}
 * @param userSettings {PostUserSettings} UserSettings with the UserId
 * @returns set the postSettingsStatus to DataState.SUCCEEDED if data was updated
 */
export const postUserSettings = createAsyncThunk('settings/postUserSettings', async (userSettings: PostUserSettings) => {
	const url = `${baseUrl}preference/${userSettings.userId}`;
	let reqData = {
		postalCode: userSettings.postalCode,
		defaultLineupId: userSettings.defaultLineupId,
		gridSettings: userSettings.gridSettings,
		cellSettings: userSettings.cellSettings,
		detailSettings: userSettings.detailSettings,
		pvrSettings: userSettings.pvrSettings
	}
	const req = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Post Settings error: ${err}`, true))
})

export const gridSettingsSlice = createSlice({
	name: 'gridSettings',
	initialState,
	reducers: {
		setPostalCode: (state, action) => {
			state.postalCode = action.payload;
		},
		setStartTime: (state, action) => {
			state.startTime = action.payload;
		},
		setFontSize: (state, action) => {
			state.fontSize = action.payload
		},
		setHideNGChannels: (state) => {
			state.hideNGCHannels = !state.hideNGCHannels
		},
		setWrapCellText: (state) => {
			state.wrapCellText = !state.wrapCellText
		},
		setFixedCellHeight: (state) => {
			state.fixedCellHeight = !state.fixedCellHeight
		},
		setDisplayChannelLogo: (state) => {
			state.displayChannelLogo = !state.displayChannelLogo
		},
		setDisplayShowCards: (state) => {
			state.displayShowCards = !state.displayShowCards
		},
		setCompactMode: (state) => {
			state.compactMode = !state.compactMode
		},
		setDailyGridWidth: (state, action) => {
			state.dailyGridWidth = action.payload;
		},
		setCellDescription: (state) => {
			state.cellDescription = !state.cellDescription
		},
		setHDIndicator: (state) => {
			state.HDIndicator = !state.HDIndicator
		},
		setGenreDisplay: (state) => {
			state.genre = !state.genre
		},
		setMovieRating: (state) => {
			state.movieRating = !state.movieRating
		},
		setProgramType: (state) => {
			state.programType = !state.programType
		},
		setAudioDescription: (state) => {
			state.audioDescription = !state.audioDescription
		},
		setEpsTitle: (state) => {
			state.EpsTitle = !state.EpsTitle
		},
		setSeasonNumber: (state) => {
			state.SeasonNumber = !state.SeasonNumber
		},
		setNewRepeat: (state) => {
			state.newRepeat = !state.newRepeat
		},
		setYearProduction: (state) => {
			state.yearProduction = !state.yearProduction
		},
		setTvRating: (state) => {
			state.tvRating = !state.tvRating
		},
		setNextgenIndicator: (state) => {
			state.nextGenIndicator = !state.nextGenIndicator
		},
		setSocialMediaLinks: (state) => {
			state.socialMediaLinks = !state.socialMediaLinks
		},
		setDetailsSynopsis: (state) => {
			state.detailsSynopsis = !state.detailsSynopsis
		},
		setDetailsShowCard: (state) => {
			state.detailsShowCard = !state.detailsShowCard
		},
		setCastBio: (state) => {
			state.castBio = !state.castBio
		},
		setGridColorScheme: (state, action) => {
			state.gridColorScheme = action.payload;
		},
		setDefaultLineup: (state, action) => {
			state.defaultLineup = action.payload;
		},
		setSettingMainGridDuration: (state, action) => {
			state.gridMainDuration = action.payload;
		},
		setSettingsSort: (state, action) => {
			state.settingsSort = action.payload;
		},
		setEnableWatch: (state) => {
			state.enableWatch = !state.enableWatch
		},
		setEnableRecord: (state) => {
			state.enableRecord = !state.enableRecord
		},
		setEnableRemoteScheduling: (state) => {
			state.enableRemoteScheduling = !state.enableRemoteScheduling
		},
		setEnableBurnToDVD: (state) => {
			state.enableBurnToDVD = !state.enableBurnToDVD
		},
		setPVRChannel: (state, action) => {
			state.pvrChannel = action.payload
		},
		setPostSettingsStatus: (state, action) => {
			state.postSettingsStatus = action.payload;
		},
		setDisplayWatch: (state, action) => {
			state.displayWatch = action.payload;
		},
		setDisplayRecord: (state, action) => {
			state.displayRecord = action.payload;
		},
		setDisplayBurn: (state, action) => {
			state.displayBurn = action.payload;
		},
		setDisplayICal: (state, action) => {
			state.displayICal = action.payload;
		},
	},
	extraReducers: builder => {
		// fetchUserSettings
		builder.addCase(fetchUserSettings.pending, (state) => {
			state.userSettingsStatus = DataState.LOADING;
		})
		builder.addCase(fetchUserSettings.fulfilled, (state, action) => {
			if (action.payload === undefined || action.payload.errorCode !== 0) {
				state.settingErrorCode = action.payload.errorCode;
				state.settingsErrorMessage = action.payload.errorMessage;
				state.userSettingsStatus = DataState.FAILED;
			} else {
				let data: UserSettings
				data = action.payload
				setUserSettings(state, data);
				state.userSettingsStatus = DataState.SUCCEEDED;
			}
		})
		builder.addCase(fetchUserSettings.rejected, (state) => {
			state.userSettingsStatus = DataState.FAILED;
		})

		// postUserSettings
		builder.addCase(postUserSettings.pending, (state) => {
			state.postSettingsStatus = DataState.LOADING;
		})
		builder.addCase(postUserSettings.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.postSettingsStatus = DataState.FAILED;
			} else {
				state.postSettingsStatus = DataState.SUCCEEDED;
			}
		})
		builder.addCase(postUserSettings.rejected, (state) => {
			state.postSettingsStatus = DataState.FAILED;
		})
	},
});

export const {
	setPostalCode, setStartTime, setFontSize, setHideNGChannels, setWrapCellText, setFixedCellHeight,
	setDisplayChannelLogo, setDisplayShowCards, setDailyGridWidth, setCellDescription, setHDIndicator,
	setMovieRating, setProgramType, setAudioDescription, setEpsTitle, setNewRepeat, setYearProduction,
	setTvRating, setNextgenIndicator, setSocialMediaLinks, setDetailsSynopsis, setDetailsShowCard, setCompactMode,
	setCastBio, setGenreDisplay, setGridColorScheme, setSeasonNumber, setDefaultLineup, setSettingMainGridDuration,
	setSettingsSort, setEnableWatch, setEnableRecord, setEnableRemoteScheduling, setEnableBurnToDVD, setPVRChannel,
	setPostSettingsStatus, setDisplayWatch, setDisplayRecord, setDisplayBurn, setDisplayICal
} = gridSettingsSlice.actions;

export const selectPostalCode = (state: RootState) => state.gridSettings.postalCode;
export const selectStartTime = (state: RootState) => state.gridSettings.startTime;
export const selectSettingMainGridDuration = (state: RootState) => state.gridSettings.gridMainDuration;
export const selectFontSize = (state: RootState) => state.gridSettings.fontSize;
export const selectHideNGChannels = (state: RootState) => state.gridSettings.hideNGCHannels;
export const selectWrapCellText = (state: RootState) => state.gridSettings.wrapCellText;
export const selectFixedCellHeight = (state: RootState) => state.gridSettings.fixedCellHeight;
export const selectDisplayChannelLogo = (state: RootState) => state.gridSettings.displayChannelLogo;
export const selectDisplayShowCards = (state: RootState) => state.gridSettings.displayShowCards;
export const selectDailyGridWidth = (state: RootState) => state.gridSettings.dailyGridWidth;
export const selectSettingsSort = (state: RootState) => state.gridSettings.settingsSort;
export const selectCompactMode = (state: RootState) => state.gridSettings.compactMode;

export const selectCellDescription = (state: RootState) => state.gridSettings.cellDescription;
export const selectHDIndicator = (state: RootState) => state.gridSettings.HDIndicator;
export const selectGenreDisplay = (state: RootState) => state.gridSettings.genre;
export const selectMovieRating = (state: RootState) => state.gridSettings.movieRating;
export const selectProgramType = (state: RootState) => state.gridSettings.programType;
export const selectAudioDescription = (state: RootState) => state.gridSettings.audioDescription;
export const selectEpsTitle = (state: RootState) => state.gridSettings.EpsTitle;
export const selectSeasonNumber = (state: RootState) => state.gridSettings.SeasonNumber;
export const selectNewRepeat = (state: RootState) => state.gridSettings.newRepeat;
export const selectYearProduction = (state: RootState) => state.gridSettings.yearProduction;
export const selectTvRating = (state: RootState) => state.gridSettings.tvRating;
export const selectNextGenIndicator = (state: RootState) => state.gridSettings.nextGenIndicator;
export const selectSocialMediaLinks = (state: RootState) => state.gridSettings.socialMediaLinks;

export const selectDetailsSynopsis = (state: RootState) => state.gridSettings.detailsSynopsis;
export const selectDetailsShowCard = (state: RootState) => state.gridSettings.detailsShowCard;
export const selectCastBio = (state: RootState) => state.gridSettings.castBio;

export const selectGridColorScheme = (state: RootState) => state.gridSettings.gridColorScheme;
export const selectUserSettingsStatus = (state: RootState) => state.gridSettings.userSettingsStatus;
export const selectDefaultLineup = (state: RootState) => state.gridSettings.defaultLineup;
export const selectSettingsErrorCode = (state: RootState) => state.gridSettings.settingErrorCode;
export const selectSettingsErrorMessage = (state: RootState) => state.gridSettings.settingsErrorMessage;

export const selectEnableWatch = (state: RootState) => state.gridSettings.enableWatch;
export const selectEnableRecord = (state: RootState) => state.gridSettings.enableRecord;
export const selectEnableRemoteScheduling = (state: RootState) => state.gridSettings.enableRemoteScheduling;
export const selectEnableBurnToDVD = (state: RootState) => state.gridSettings.enableBurnToDVD;
export const selectPVRChannel = (state: RootState) => state.gridSettings.pvrChannel;

export const selectDisplayWatch = (state: RootState) => state.gridSettings.displayWatch;
export const selectDisplayRecord = (state: RootState) => state.gridSettings.displayRecord;
export const selectDisplayBurn = (state: RootState) => state.gridSettings.displayBurn;
export const selectDisplayICal = (state: RootState) => state.gridSettings.displayICal;

export const selectPostSettingsStatus = (state: RootState) => state.gridSettings.postSettingsStatus;

export default gridSettingsSlice.reducer;