import GridLegand from '../components/GridLegand'
import MainGridSelector from '../components/MainGridSelector'
import {
	GridContainer, GridContent, NoChannelLink, NoChannelsMessage, StyledGrid
} from '../components/GridStyles';
import VerticalAd from '../../mockAdSpace/VerticalAd';
import MainGridTimeBar from '../components/MainGridTimeBar';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useEffect } from 'react';
import {
	selectMainGridDuration, selectGridLineupID, selectLineupChannels, selectMainSchedule, setGridTime, setTimeBarArray,
	setGridLineupID
} from '../mainGridSlice';
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import {
	fetchLineupChannels, fetchSchedule, selectMainGridStatus, selectMainGridChannelStatus
} from '../mainGridSlice';
import { leadingZeroBuilder } from '../../Global/GobalFunctions';
import GridLoading from '../components/GridLoading';
import MainGridComponent from '../components/MainGridComponent';
import { DataState, LineupMode } from '../../../interfaces/enums';
import { selectStartTime, setDefaultLineup } from '../../UserSettingsPages/SettingsState/gridSettingsSlice';
import { useNavigate } from 'react-router-dom';
import {
	setLineupPageMode, setLineupToEditId, fetchLineupChannelsForEdit,
	selectLineups
} from '../../UserSettingsPages/SettingsState/lineupsSlice';
import { setFilterDefault } from '../../Modal/filterDisplaySlice';
import { setRunPrimisPlayer } from '../../Header/headerSlice';
import { consoleLog } from '../../Global/ConsoleLogging';

const MainGrid = () => {
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const nav = useNavigate();
	const mainGridDuration = useAppSelector(selectMainGridDuration);
	const dispatch = useAppDispatch();
	const scheduleStatus = useAppSelector(selectMainGridStatus);
	const channelStatus = useAppSelector(selectMainGridChannelStatus);
	const channels = useAppSelector(selectLineupChannels);
	const schedule = useAppSelector(selectMainSchedule);
	const startTime = useAppSelector(selectStartTime);
	const lineups = useAppSelector(selectLineups);

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(setRunPrimisPlayer(true));
		if (lineupId === '') {
			dispatch(setGridLineupID(lineups.lineups[0].lineupId));
			dispatch(setDefaultLineup(lineups.lineups[0].lineupId));
		}
		const now = new Date();
		if (startTime !== '-1') {
			now.setHours(parseInt(startTime));
		}
		dispatch(setGridTime(now.getHours() + ''));
		dispatch(setTimeBarArray());
		const dateTime = now.getFullYear() + leadingZeroBuilder(now.getMonth() + 1) + leadingZeroBuilder(now.getDate()) + leadingZeroBuilder(now.getHours()) + '00';
		dispatch(fetchSchedule({ userId: userId, lineupId: lineupId, startTimeToken: dateTime, duration: mainGridDuration * 60 }));

		if (!channels.lineupCacheId && channelStatus !== DataState.LOADING && channels.lineupCacheId !== schedule.lineupCacheId) {
			dispatch(fetchLineupChannels({ userId: userId, lineupId: lineupId }));
		}

		dispatch(setFilterDefault());

		return () => {
			dispatch(setFilterDefault());
		}
	}, []);

	if (scheduleStatus === DataState.SUCCEEDED || channelStatus === DataState.SUCCEEDED) {
		if (schedule.lineupCacheId !== channels.lineupCacheId) {
			consoleLog('MainGrid shedule cache not equal to channel cache', true)
			const now = new Date();
			if (startTime !== '-1') {
				now.setHours(parseInt(startTime));
			}
			const dateTime = now.getFullYear() + leadingZeroBuilder(now.getMonth() + 1) + leadingZeroBuilder(now.getDate()) + leadingZeroBuilder(now.getHours()) + '00';
			//dispatch(fetchSchedule({ userId: userId, lineupId: lineupId, startTimeToken: dateTime, duration: mainGridDuration * 60 }));
			dispatch(fetchLineupChannels({ userId: userId, lineupId: lineupId }));
		}
	}

	const handleNoChannels = () => {
		dispatch(setLineupToEditId(lineupId));
		dispatch(setLineupPageMode(LineupMode.EDIT));
		dispatch(fetchLineupChannelsForEdit({ userId, lineupId }));
		nav('/lineups');
	}

	return (
		<GridContainer>
			<MainGridSelector />
			<GridContent>
				<StyledGrid>
					<MainGridTimeBar isFirst={true} />
					{(channelStatus === DataState.SUCCEEDED && (channels.channels[0].channelId === 0 || channels.channels.length === 0 || !channels.channels)) ?
						<NoChannelsMessage>
							This lineup contains no channels,
							<NoChannelLink onClick={() => handleNoChannels()}>add some channels</NoChannelLink>
						</NoChannelsMessage>
						:
						(scheduleStatus === DataState.LOADING || channelStatus === DataState.LOADING) ?
							<GridLoading />
							:
							<MainGridComponent />
					}
				</StyledGrid>
				<VerticalAd />
			</GridContent>
			<GridLegand />
		</GridContainer>
	)
}

export default MainGrid