import { useState } from 'react';
import { Agree, SignupInput } from '../UserSettingsPages/components/SettingsStyles'
import {
	ModalContainer, ModalTitleBar, ModalIconSM, ModalContent, ModalHeader, ModalMainContent, ModalButtons, ModalBlueButton,
	WhiteClose
} from '../Modal/ModalStyles';
import { StyledLink } from '../Global/GobalStyledComponents';
import { setModalOpen } from "../Modal/modalSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Close from '../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import { CheckBox } from '../Global/GobalStyledComponents';
import {
	selectUserId, selectUsername, selectEmail, selectPassword, selectConfirmPass, setUsername, setEmail, setPassword,
	setConfirmPassword, upgradeGuestUser
} from '../UserSettingsPages/SettingsState/userSlice'
import {
	selectAudioDescription, selectCastBio, selectCellDescription, selectEpsTitle, selectNewRepeat, selectTvRating,
	selectDefaultLineup, selectDetailsShowCard, selectDetailsSynopsis, selectDisplayChannelLogo, selectDisplayShowCards,
	selectFixedCellHeight, selectFontSize, selectGenreDisplay, selectHDIndicator, selectHideNGChannels, selectMovieRating,
	selectNextGenIndicator, selectPostalCode, selectProgramType, selectSeasonNumber, selectSocialMediaLinks, selectCompactMode,
	selectWrapCellText, selectYearProduction, selectDailyGridWidth, selectEnableWatch, selectEnableRecord, selectEnableBurnToDVD,
	selectEnableRemoteScheduling, selectPVRChannel, selectSettingMainGridDuration
} from '../UserSettingsPages/SettingsState/gridSettingsSlice'
import {
	selectChannelSort
} from '../GridPages/mainGridSlice';
import { PostUserSettings } from '../../interfaces/settings';
import { GetDefaultUser } from '../Global/GobalFunctions';
import { useEffect } from 'react';

const Signup = () => {
	const dispatch = useAppDispatch();
	const userId = useAppSelector(selectUserId);
	const email = useAppSelector(selectEmail);
	const username = useAppSelector(selectUsername);
	const password = useAppSelector(selectPassword);
	const confirmPass = useAppSelector(selectConfirmPass);
	const defaultUserID = GetDefaultUser();

	const [aggreeToTerms, setAggreeToTerms] = useState(false);
	const [lockInput, setLockInput] = useState(false);


	const postSettings: PostUserSettings = {
		postalCode: useAppSelector(selectPostalCode),
		defaultLineupId: useAppSelector(selectDefaultLineup),
		userId: userId,
		gridSettings: {
			gridStartHour: -1,
			gridDuration: useAppSelector(selectSettingMainGridDuration),
			channelSortOrder: useAppSelector(selectChannelSort),
			gridFontSize: useAppSelector(selectFontSize),
			hideNextGen: useAppSelector(selectHideNGChannels),
			wrapCellText: useAppSelector(selectWrapCellText),
			fixedCellHeight: useAppSelector(selectFixedCellHeight),
			includeLogos: useAppSelector(selectDisplayChannelLogo),
			includeShowCards: useAppSelector(selectDisplayShowCards),
			gridDailyWidth: useAppSelector(selectDailyGridWidth),
			gridColorScheme: useAppSelector(selectDailyGridWidth),
			compactMode: useAppSelector(selectCompactMode)
		},
		cellSettings: {
			includeDescription: useAppSelector(selectCellDescription),
			includeEpisodeTitle: useAppSelector(selectEpsTitle),
			includeSeasonNumbers: useAppSelector(selectSeasonNumber),
			includeHDIndicator: useAppSelector(selectHDIndicator),
			includeNewRepeatIndicator: useAppSelector(selectNewRepeat),
			includeGenres: useAppSelector(selectGenreDisplay),
			includeMovieYear: useAppSelector(selectYearProduction),
			includeMovieRatings: useAppSelector(selectMovieRating),
			includeTVRatings: useAppSelector(selectTvRating),
			includeOriginalAirDate: true,
			includeProgramType: useAppSelector(selectProgramType),
			includeNextGenIndicator: useAppSelector(selectNextGenIndicator),
			includeAudioStreams: useAppSelector(selectAudioDescription),
			includeSocialMediaLinks: useAppSelector(selectSocialMediaLinks),
		},
		detailSettings: {
			includeSeriesDescription: useAppSelector(selectDetailsSynopsis),
			includeShowCards: useAppSelector(selectDetailsShowCard),
			includeCastAndCrewLinks: useAppSelector(selectCastBio)
		},
		pvrSettings: {
			enableWatch: useAppSelector(selectEnableWatch),
			enableRecord: useAppSelector(selectEnableRecord),
			enableBurnToDVD: useAppSelector(selectEnableBurnToDVD),
			enableRemoteScheduling: useAppSelector(selectEnableRemoteScheduling),
			channel: useAppSelector(selectPVRChannel)
		}
	}

	const handleSignUp = () => {
		setLockInput(true)
		if (password === confirmPass) {
			if (userId === defaultUserID) {

			} else {
				dispatch(upgradeGuestUser({ userId: userId, loginName: username, password: password, emailAddress: email }))
			}
		} else {

		}
	}

	useEffect(() => {
		setLockInput(false);
	}, [])

	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				<WhiteClose onClick={() => dispatch(setModalOpen())}>
					Close <ModalIconSM src={Close} alt="" />
				</WhiteClose>
			</ModalTitleBar>
			<ModalContent>
				<ModalHeader>Join TitanTV</ModalHeader>
				<ModalMainContent>
					<p>Become a TitanTV Member</p>
					<p>Fill out the form below to create your TitanTV membership. It is free and gives you access to additional features that allow you to customize your lineups.</p>
					<p>Your custom lineups and preferences may then be viewed on any computer or mobile device by logging in to your TitanTV account.</p>
				</ModalMainContent>
				<SignupInput disabled={lockInput} id='username' placeholder='Username' value={username} onChange={(e) => dispatch(setUsername(e.target.value))} />
				<SignupInput disabled={lockInput} type='email' id='email' placeholder='Email' value={email} onChange={(e) => dispatch(setEmail(e.target.value))} />
				<SignupInput disabled={lockInput} name='password' type='password' id='password' placeholder='Password' value={password} onChange={(e) => dispatch(setPassword(e.target.value))} />
				<SignupInput disabled={lockInput} name='confirmPassword' type='password' id='confirm' placeholder='Confirm Password' value={confirmPass} onChange={(e) => dispatch(setConfirmPassword(e.target.value))} />
				<Agree>
					<CheckBox checked={aggreeToTerms} onChange={() => setAggreeToTerms(!aggreeToTerms)} />
					<label>
						I agree to the <StyledLink to='/terms' target='_blank'>terms of service</StyledLink> and <StyledLink to='/privacy' target='_blank'>privacy</StyledLink>
					</label>
				</Agree>
				<ModalButtons>
					<ModalBlueButton disabled={lockInput} onClick={() => handleSignUp()}>Submit</ModalBlueButton>
				</ModalButtons>
			</ModalContent>
		</ModalContainer>
	)
}

export default Signup