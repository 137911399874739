import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import { DataState } from '../../../interfaces/enums';
import {
	selectLineupChannelsStatus, selectChannelSchedule, selectLineupChannels, selectGridLineupID, fetchLineupChannels, setMainGridScrollID,
	selectMainGridChannelStatus, fetchChannelLineup,
} from '../mainGridSlice'
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import {
	ChannelTimeColumn, DailyTimeBlock, StyledDailyGrid, StyledTimeColumn, DailyChannelCard, ChannelCardClose, SingleDaySchedule,
	DailyGridDayCard, DailyDayCard, NoDailyData
} from './DailyGridStyles';
import { selectDailyGridWidth, selectDisplayChannelLogo } from '../../UserSettingsPages/SettingsState/gridSettingsSlice'
import GridFailed from './GridFailed';
import { ChannelCard, ChannelInfo, ChannelID, ExpandIcon, ChannelCallsign, NextGenLogo } from './GridStyles';
import Close from '../../../img/Icons/Red Icons/TitanTV Icon_Close_Red.png';
import DailyProgramColumn from './DailyProgramColumn';
import VerticalAd from '../../mockAdSpace/VerticalAd';
import { leadingZeroBuilder } from '../../Global/GobalFunctions';
import GridLoading from './GridLoading';
import { useNavigate } from 'react-router-dom';
import NextGenSVG from '../../../img/nextgentv.svg';

const DailyGridComponent = (props: any) => {
	const channelIndex = parseInt(props.channelIndex);
	const schedule = useAppSelector(selectChannelSchedule);
	const loading = useAppSelector(selectLineupChannelsStatus);
	const channelLoading = useAppSelector(selectMainGridChannelStatus)
	const days = useAppSelector(selectDailyGridWidth);
	const numberOfDays = useAppSelector(selectDailyGridWidth)
	const displayChannelLogo = useAppSelector(selectDisplayChannelLogo);

	const dispatch = useAppDispatch();
	const channels = useAppSelector(selectLineupChannels);
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const compactMode = false;
	const nav = useNavigate()

	// #region Days Stuff
	let daysArray: Date[] = [];
	const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	// #endregion

	const buildDayArray = () => {
		const quantityOfDays = 7;
		for (let i = 0; i < quantityOfDays; i++) {
			const newDay = new Date();
			newDay.setDate(newDay.getDate() + i);
			daysArray.push(newDay);
		}
	}

	let channel = channels.channels.find((c) => {
		return c.channelIndex === channelIndex;
	});

	if (!channels.lineupCacheId && channelLoading !== DataState.LOADING) {
		dispatch(fetchLineupChannels({ userId: userId, lineupId: lineupId }));
	}

	if (!schedule.lineupCacheId && loading !== DataState.LOADING) {
		const date = new Date();
		const timeStamp = date.getFullYear() + leadingZeroBuilder(date.getMonth() + 1) + leadingZeroBuilder(date.getDate()) + '0000';
		dispatch(fetchChannelLineup({ userId: userId, lineupId: lineupId, timeStamp, duration: '1440', channelIndex: channelIndex + '' }))
	}

	const handleChannelClose = () => {
		dispatch(setMainGridScrollID(channel?.channelId));
		nav('/');
	}

	let events: JSX.Element[] = [];

	buildDayArray();

	const displayType = (index: number) => {
		if (index === 5 || index === 6) {
			return 'none'
		} else {
			return 'flex'
		}
	}

	/* 
		using a for-loop makes it possible to create 1 to 7 day grids based on the raido buttions
		using array.map makes you run throught the whole array. By doing this way we don't need to
		pull more data each time the user switches how many days are rendered. 
	*/
	if (schedule.channels[0].days[0]) {
		if (loading === DataState.SUCCEEDED && channel) {
			for (let i = 0; i < parseInt(days); i++) {
				let day = daysArray[i];
				let dayString = daysOfWeek[day.getDay()] + ', ' + months[day.getMonth()] + ' ' + day.getDate();
				let dayText: JSX.Element;
				if (parseInt(numberOfDays) < 4) {
					dayText = <DailyDayCard>{daysOfWeek[day.getDay()]},&nbsp;{months[day.getMonth()]}&nbsp;{day.getDate()}</DailyDayCard>
				} else {
					dayText = <DailyDayCard>{daysOfWeek[day.getDay()]},<br />{months[day.getMonth()]}&nbsp;{day.getDate()}</DailyDayCard>
				}

				events.push(
					<SingleDaySchedule key={dayString} printDisplay={displayType(i)}>
						<DailyGridDayCard>
							{dayText}
						</DailyGridDayCard>
						<DailyProgramColumn DayOfEvents={schedule.channels[0].days[i].events} Channel={channel} channelIndex={channelIndex} dayIndex={i} />
					</SingleDaySchedule>);
			}
		}
	} else {
		events.push(
			<NoDailyData>No data for this channel</NoDailyData>
		)
	}

	const displayMajorMinor = (providerTypeId: number): boolean => {
		switch (providerTypeId) {
			case 1:
				return false;
			case 2:
				return true;
			case 3:
				return false;
			case 4:
				return false;
			case 5:
				return true;
			case 6:
				return false;
			case 7:
				return false;
			default:
				return false;
		}
	}

	// When the X is clicked on the Channel card the main grid should scroll back to the channel
	// https://stackoverflow.com/questions/63327271/scroll-to-element-on-page-load-with-react-hooks
	return (
		<>
			{loading === DataState.FAILED ? <GridFailed errorCode={0} errorMessage={''} /> :
				loading === DataState.LOADING ? <GridLoading /> :
					<StyledDailyGrid>
						<ChannelTimeColumn>
							<DailyChannelCard>
								<ChannelInfo>
									<ChannelID compact={false}>
										{(displayMajorMinor(channel!.providerTypeId) && channel!.majorChannel > 0) ? channel?.majorChannel + '.' + channel?.minorChannel : channel?.rfChannel}
									</ChannelID>
									<ChannelCardClose onClick={() => handleChannelClose()}>
										<ExpandIcon compact={false} src={Close} title={`Click to return to full grid with all channels`} />
									</ChannelCardClose>
								</ChannelInfo>
								{displayChannelLogo ? <ChannelCard src={channel?.logo} />
									:
									<ChannelCallsign compact={false}>{channel!.callSign} <br />{channel!.network}</ChannelCallsign>
								}
								{channel?.isNextGen ? <NextGenLogo compact={compactMode} title={`This station is a NextGen TV channel`} src={NextGenSVG} /> : ''}
							</DailyChannelCard>
							<TimeColumn />
						</ChannelTimeColumn>
						{events}
						<VerticalAd />
					</StyledDailyGrid>
			}
		</>
	)
}

const TimeColumn = () => {
	return (
		<StyledTimeColumn>
			<DailyTimeBlock>
				12:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				12:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				1:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				1:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				2:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				2:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				3:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				3:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				4:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				4:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				5:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				5:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				6:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				6:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				7:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				7:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				8:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				8:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				9:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				9:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				10:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				10:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				11:00 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				11:30 AM
			</DailyTimeBlock>
			<DailyTimeBlock>
				12:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				12:30 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				1:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				1:30 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				2:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				2:30 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				3:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				3:30 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				4:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				4:30 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				5:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				5:30 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				6:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				6:30 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				7:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				7:30 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				8:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				8:30 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				9:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				9:30 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				10:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				10:30 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				11:00 PM
			</DailyTimeBlock>
			<DailyTimeBlock>
				11:30 PM
			</DailyTimeBlock>
		</StyledTimeColumn>
	)
}

export default DailyGridComponent