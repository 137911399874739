import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setMenuOpen, selectMenuOpen } from '../headerSlice'

const Burger = () => {

	const dispatch = useAppDispatch();
	const isOpen: boolean = useAppSelector(selectMenuOpen);

	return (
		<StyledBurger title='Open Menu' open={isOpen} onClick={() => dispatch(setMenuOpen())}>
			<TopBar open={isOpen}></TopBar>
			<MiddleBar open={isOpen}></MiddleBar>
			<BottomBar open={isOpen}></BottomBar>
		</StyledBurger>
	)
}

const StyledBurger = styled.div<{ open: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 25px;
	height: 25px;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	margin: 10px 20px 10px 10px;
	z-index: 15;
	user-select: none;
`

const BarDiv = styled.div`
	width: 25px;
	height: 4px;
	background: black;
	border-radius: 5px;
	transition: all 0.3s linear;
	transform-origin: 1px;
`

const TopBar = styled(BarDiv) <{ open: boolean }>`
	transform: ${p => p.open ? 'rotate(45deg)' : 'rotate(0)'};
`

const MiddleBar = styled(BarDiv) <{ open: boolean }>`
	opacity: ${p => p.open ? '0' : '1'};
`

const BottomBar = styled(BarDiv) <{ open: boolean }>`
	transform: ${p => p.open ? 'rotate(-45deg)' : 'rotate(0)'};
`

export default Burger