import styled from 'styled-components'

export const LegalContainer = styled.div`
	text-align: justify;
	font-size: 12px;
	padding: 20px 50px;
`

export const LegalHeader = styled.div`
	font-weight: bold;
	text-align: center;
`

export const TermsTopic = styled.span`
	font-weight: bold;
	text-transform: uppercase;
`

export const LegalList = styled.ul`
	list-style-type: disc;
	list-style-position: outside;

	li {
		padding-bottom: 10px;
	}
`

export const LegalLink = styled.a`
	color: grey;
`