	import styled from 'styled-components'

const Loading = (props: any) => {

	return (
		<StyledGridLoading>
			<StyledLoading />
			<LoadingMessage>{props.message} Please wait...</LoadingMessage>
		</StyledGridLoading>
	)
}

export default Loading

const StyledGridLoading = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	justify-self: center;
	padding: 15px 0 0 0;

	/* @media (max-width: ${({ theme }) => theme.mobile}) {
		display: none;
	} */
`

// Styled info from
// https://dev.to/cathleys/how-to-create-a-loading-spinner-in-react-with-styled-components-4p2g
const StyledLoading = styled.div`
	border-width: 0.5rem;
	border-style: solid;
	border-color: ${({ theme }) => theme.titanBlue} ${({ theme }) => theme.titanRed};
	width: 6.625rem;
	height: 6.625rem;
	border-radius: 50%;
	position: relative;
	-webkit-animation: spin 2s infinite;
	animation: spin 2s infinite;
	padding: 20px;

	&:before
	&:after {
		content: '';
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 50%;
		position: absolute;
		left: 20px;
		top: 20px;
	}

	&:before {
    top: 0.063rem;
  }

  &:after {
    bottom: 0.063rem;
  }

	@keyframes spin {
		100% {
			transform: rotate(360deg);
		}
	}
`

const LoadingMessage = styled.div`
	font-size: 14px;
	padding-top: 5px;
`