import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setModalOpen } from "../modalSlice";
import {
	ModalContainer, ModalIconSM, ModalContent, EditLineupContainer, WhiteClose, ModalBlueButton, DTSEditLabel,
	EditLineupInuptContainer, EditLineupLabel, EditLineupText, EditLineupPullDown, ModalTitleBar, ModalHeaderSM
} from '../ModalStyles';
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import { TimeZones } from "../../Global/GobalData";
import {
	fetchUserLineups, postUpdateLineup, selectLineupDSTEdit, selectLineupNameToEdit, selectLineupTimeZoneEdit,
	selectLineupToBeEdited, setLineupDSTEdit, setLineupNameToEdit, setLineupTimeZoneEdit
} from "../../UserSettingsPages/SettingsState/lineupsSlice";
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';

const ModalLineupEdit = () => {
	const dispatch = useAppDispatch();
	const lineup = useAppSelector(selectLineupToBeEdited);
	const userID = useAppSelector(selectUserId);
	const lineupName: string = useAppSelector(selectLineupNameToEdit);
	const lineupTimeZone: number = useAppSelector(selectLineupTimeZoneEdit);
	const lineupDTS: boolean = useAppSelector(selectLineupDSTEdit);


	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setLineupNameToEdit(e.target.value));
	}

	const handleTimeZoneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		// set timezone name with event.target.options[event.target.selectedIndex].text
		dispatch(setLineupTimeZoneEdit(parseInt(e.target.value)));

	}

	const handleDSTChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (e.target.value === 'true') {
			dispatch(setLineupDSTEdit(true));
		}
		if (e.target.value === 'false') {
			dispatch(setLineupDSTEdit(false));
		}
	}

	const handleLineupEditSave = () => {

		const data = {
			userId: userID,
			lineupName: lineupName,
			utcOffSet: lineupTimeZone,
			observesDST: lineupDTS,
			providerId: lineup.providerId,
			deviceTypeCode: lineup.deviceType,
			lineupId: lineup.lineupId
		}

		dispatch(postUpdateLineup(data));
		dispatch(setModalOpen());
	}

	useEffect(() => {


		return () => {
			fetchUserLineups(userID);
		}
	}, [])

	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				<WhiteClose onClick={() => dispatch(setModalOpen())}>
					<ModalIconSM src={Close} alt='Close' /> Close
				</WhiteClose>
			</ModalTitleBar>
			<ModalContent>
				<ModalHeaderSM>Change this lineup's display name, time zone, and Daylight Savings Time settings.</ModalHeaderSM>
				<br />
				<br />
				<EditLineupContainer>
					<EditLineupInuptContainer>
						<EditLineupLabel>
							Lineup Name
						</EditLineupLabel>
						<EditLineupText onChange={(e) => handleNameChange(e)} value={lineupName} />
					</EditLineupInuptContainer>
					<EditLineupInuptContainer>
						<EditLineupLabel>
							Time Zone
						</EditLineupLabel>
						<EditLineupPullDown value={lineupTimeZone} onChange={(e) => { handleTimeZoneChange(e) }}>
							{TimeZones.map((TZ, i) => {
								return (<option key={TZ.timeZone} value={TZ.utcOffset}>{TZ.timeZone}</option>)
							})}
						</EditLineupPullDown>
					</EditLineupInuptContainer>
					<EditLineupInuptContainer>
						<DTSEditLabel>
							Ovserves DST
							<EditLineupPullDown value={lineupDTS.toString()} onChange={(e) => { handleDSTChange(e) }}>
								<option value={'true'}>Yes</option>
								<option value={'false'}>No</option>
							</EditLineupPullDown>
						</DTSEditLabel>
					</EditLineupInuptContainer>
				</EditLineupContainer>
				<ModalBlueButton onClick={() => handleLineupEditSave()}>
					Save
				</ModalBlueButton>
			</ModalContent>
		</ModalContainer>
	)
}

export default ModalLineupEdit