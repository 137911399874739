import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { DataState } from '../../../interfaces/enums'
import {
	selectlineupChannelsToEdit, postAddChannel, selectLineupChannelsToAddStatus, selectLineupChannelsToAdd,
} from '../../UserSettingsPages/SettingsState/lineupsSlice'
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice'
import GridLoading from '../../GridPages/components/GridLoading'
import GridFailed from '../../GridPages/components/GridFailed'
import { AddChannelBox, StyledAddChannelList } from '../../UserSettingsPages/components/LineupEditStyles'
import { EditLineupChannel } from '../../../interfaces/channels'
import { CreateUUID } from '../../Global/GobalFunctions'
import { DataError } from '../../UserSettingsPages/components/SettingsStyles'
import { handleErrorMessage } from '../../Global/ErrorMessage'

const AddChannelList = () => {
	const dispatch = useAppDispatch()
	const userId = useAppSelector(selectUserId);
	const channelListStatus = useAppSelector(selectLineupChannelsToAddStatus);
	const channelList = useAppSelector(selectLineupChannelsToAdd);
	const lineupToEdit = useAppSelector(selectlineupChannelsToEdit);
	const lineupId = lineupToEdit.lineupId;

	const buildChannelNumber = (channel: EditLineupChannel) => {
		let channelNumberString = ''
		if (channel.majorChannel > 0) {
			channelNumberString = `${channel.majorChannel}.${channel.minorChannel}`
		} else {
			channelNumberString = `${channel.rfChannel}`;
		}

		return channelNumberString
	}

	const buildChannelDescription = (channel: EditLineupChannel): string => {
		let description = channel.description
		if (channel.callSign !== '') {
			description = description + ' ' + channel.callSign;
		}
		if (channel.network !== '') {
			description = description + ` (${channel.network}) `
		}
		return description
	}

	const handleChannelClick = (channel: EditLineupChannel) => {
		let newChannelSort = 1;
		if (lineupToEdit.channels.length > 0) {
			newChannelSort = lineupToEdit.channels[0].sortOrder - 0.001;
		}
		dispatch(postAddChannel({ userId, lineupId, channel, newChannelSort }));
	}

	return (
		<>
			{channelListStatus === DataState.LOADING ? <GridLoading /> :
				channelListStatus === DataState.FAILED ? <GridFailed errorCode={channelList.errorCode} errorMessage={channelList.errorMessage} /> :
					channelListStatus === DataState.IDLE ? <span>Select a lineup</span> :
						channelList.errorCode !== 0 ? <DataError>{handleErrorMessage(channelList.errorCode)}</DataError> :
							<StyledAddChannelList>
								{channelList.channels.map((c) => {
									return (
										<AddChannelBox key={CreateUUID()} onClick={() => handleChannelClick(c)}>
											{buildChannelDescription(c)}
										</AddChannelBox>
									)
								})}
							</StyledAddChannelList>

			}
		</>
	)
}

export default AddChannelList