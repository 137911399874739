import { useLocation } from 'react-router-dom'
import { StyledTabBar, Tab } from './SettingsStyles';

const TabBar = () => {

	const path = useLocation().pathname;

	return (
		<StyledTabBar>
			<Tab to='/account' $current={path === "/account" ? true : false} dvr={false}>My Account</Tab>
			<Tab to='/gridsettings' $current={path === "/gridsettings" ? true : false} dvr={false}>Grid Settings</Tab>
			<Tab to='/cellsettings' $current={path === "/cellsettings" ? true : false} dvr={false}>Cell Settings</Tab>
			<Tab to='/lineups' $current={path === "/lineups" ? true : false} dvr={false}>My Lineups</Tab>
			<Tab to='/myfavorites' $current={path === "/myfavorites" ? true : false} dvr={false}>My Favorites</Tab>
			<Tab to='/reminders' $current={path === "/reminders" ? true : false} dvr={false}>My Reminders</Tab>
			<Tab to='/dvroptions' $current={path === "/dvroptions" ? true : false} dvr={true}>DVR/PVR Options</Tab>
		</StyledTabBar>
	)
}

export default TabBar