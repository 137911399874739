import { setModalOpen } from "../modalSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Back from '../../../img/Icons/White Icons/TitanTV Icon_Back_White.png';
import Forward from '../../../img/Icons/Gray Icons/TitanTV Icon_Enter-Forward_Gray.png';
import {
	ModalTitleBar, ModalButton, ModalIconSM, ModalIconMed, ModalContent, ModalHeader, ModalNumEntry, ModalContainer,
	LineupsContainer, LineupName, LineupCard, LineupButton, WhiteClose
} from '../ModalStyles';
import { TextInputWithButton } from '../../Global/GobalStyledComponents';
import { setModalContent, selectNewLineupSource } from "../modalSlice";
import { ChangeEvent, useEffect, useState } from "react";
import { DataState, ModalState } from "../../../interfaces/enums";
import {
	fetchProviderByZipCode, fetchUserLineups, putNewLineup, selectLineups, selectLineupSelection, selectLineupSelectionStatus,
	selectLineupsStatus, selectNewLineupId, selectNewLineupStatus, setNewLineupStatus
} from "../../UserSettingsPages/SettingsState/lineupsSlice";
import { BaseLineup, PostUserSettings } from "../../../interfaces/settings";
import {
	fetchLineupChannels, fetchSchedule, selectChannelSort, selectMainGridDuration, setGridLineupID,
} from "../../GridPages/mainGridSlice";
import { newUserSettings, selectIsAnonymous, selectNewUserStatus, selectUserId } from "../../UserSettingsPages/SettingsState/userSlice";
import { CreateUUID, GetDefaultUser, leadingZeroBuilder, TooManyLineups } from "../../Global/GobalFunctions";
import { fetchNewGuestUser } from "../../UserSettingsPages/SettingsState/userSlice";
import {
	selectAudioDescription, selectCastBio, selectCellDescription, selectDailyGridWidth, selectDefaultLineup, selectDetailsShowCard,
	selectDetailsSynopsis, selectDisplayChannelLogo, selectDisplayShowCards, selectEnableWatch, selectEpsTitle, selectFixedCellHeight, selectFontSize,
	selectGenreDisplay, selectGridColorScheme, selectHDIndicator, selectHideNGChannels, selectMovieRating, selectNewRepeat, selectNextGenIndicator,
	selectPostalCode, selectProgramType, selectSeasonNumber, selectSocialMediaLinks, selectStartTime, selectTvRating, selectWrapCellText,
	selectYearProduction, setPostalCode, selectEnableRecord, selectEnableBurnToDVD, selectEnableRemoteScheduling, selectPVRChannel, postUserSettings,
	selectSettingMainGridDuration, selectCompactMode
} from "../../UserSettingsPages/SettingsState/gridSettingsSlice";
import { DataError } from "../../UserSettingsPages/components/SettingsStyles";
import { handleErrorMessage } from "../../Global/ErrorMessage";
import GridFailed from "../../GridPages/components/GridFailed";
import Loading from "../../Global/Loading";
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import { consoleLog } from "../../Global/ConsoleLogging";

const ModalZipCodeEnter = () => {
	const [clearCode, setClearCode] = useState(true);

	const dispatch = useAppDispatch();
	const zip = useAppSelector(selectPostalCode);
	const lineups = useAppSelector(selectLineupSelection);
	const userLineups = useAppSelector(selectLineups);
	const userLineupStatus = useAppSelector(selectLineupsStatus);
	const lineupsStatus = useAppSelector(selectLineupSelectionStatus);
	const lineupType = useAppSelector(selectNewLineupSource);
	const userId = useAppSelector(selectUserId);
	const newLineupStatus = useAppSelector(selectNewLineupStatus);
	const newUserStatus = useAppSelector(selectNewUserStatus);
	let deviceDesc = ''
	const isAnon = useAppSelector(selectIsAnonymous);
	const newLineupId = useAppSelector(selectNewLineupId)
	const mainGridDuration = useAppSelector(selectMainGridDuration);


	let lineupsArray: JSX.Element[] = []

	const REG_EX_ZIP = /^\d{5}$/;

	const settings: newUserSettings = {
		postalCode: useAppSelector(selectPostalCode),
		defaultLineupId: useAppSelector(selectDefaultLineup),
		gridSettings: {
			gridStartHour: parseInt(useAppSelector(selectStartTime)),
			gridDuration: useAppSelector(selectSettingMainGridDuration),
			channelSortOrder: useAppSelector(selectChannelSort),
			gridFontSize: useAppSelector(selectFontSize),
			hideNextGen: useAppSelector(selectHideNGChannels),
			wrapCellText: useAppSelector(selectWrapCellText),
			fixedCellHeight: useAppSelector(selectFixedCellHeight),
			includeLogos: useAppSelector(selectDisplayChannelLogo),
			includeShowCards: useAppSelector(selectDisplayShowCards),
			gridDailyWidth: useAppSelector(selectDailyGridWidth),
			gridColorScheme: useAppSelector(selectGridColorScheme),
			compactMode: useAppSelector(selectCompactMode)
		},
		cellSettings: {
			includeDescription: useAppSelector(selectCellDescription),
			includeEpisodeTitle: useAppSelector(selectEpsTitle),
			includeSeasonNumbers: useAppSelector(selectSeasonNumber),
			includeHDIndicator: useAppSelector(selectHDIndicator),
			includeNewRepeatIndicator: useAppSelector(selectNewRepeat),
			includeGenres: useAppSelector(selectGenreDisplay),
			includeMovieYear: useAppSelector(selectYearProduction),
			includeMovieRatings: useAppSelector(selectMovieRating),
			includeTVRatings: useAppSelector(selectTvRating),
			includeOriginalAirDate: true,
			includeProgramType: useAppSelector(selectProgramType),
			includeNextGenIndicator: useAppSelector(selectNextGenIndicator),
			includeAudioStreams: useAppSelector(selectAudioDescription),
			includeSocialMediaLinks: useAppSelector(selectSocialMediaLinks),
		},
		detailSettings: {
			includeSeriesDescription: useAppSelector(selectDetailsSynopsis),
			includeShowCards: useAppSelector(selectDetailsShowCard),
			includeCastAndCrewLinks: useAppSelector(selectCastBio)
		},
		pvrSettings: {
			enableWatch: useAppSelector(selectEnableWatch),
			enableRecord: useAppSelector(selectEnableRecord),
			enableBurnToDVD: useAppSelector(selectEnableBurnToDVD),
			enableRemoteScheduling: useAppSelector(selectEnableRemoteScheduling),
			channel: useAppSelector(selectPVRChannel)
		}
	}

	const checkDeviceType = (deviceTypeCode: string): boolean => {
		if (deviceTypeCode === 'O') {
			deviceDesc = ''
		}
		if (deviceTypeCode === 'D') {
			deviceDesc = 'Digital Cable';
		}
		if (lineupType === deviceTypeCode) {
			return true;
		}
		if (deviceTypeCode === 'R' && lineupType === 'A') {
			deviceDesc = 'Cable Rebuild'
			return true;
		}
		if (deviceTypeCode === 'C' && lineupType === 'D') {
			return true;
		}
		return false;
	}

	const handleSendZip = () => {
		if (TooManyLineups(userLineups.lineups.length, isAnon)) {
			alert('Too many lineups');
		} else {
			if (REG_EX_ZIP.test(zip)) {
				dispatch(fetchProviderByZipCode(zip));
				if (userId !== GetDefaultUser()) {
					let postSettings: PostUserSettings = {
						userId: userId,
						postalCode: settings.postalCode,
						defaultLineupId: settings.defaultLineupId,
						gridSettings: settings.gridSettings,
						cellSettings: settings.cellSettings,
						detailSettings: settings.detailSettings,
						pvrSettings: settings.pvrSettings
					}
					dispatch(postUserSettings(postSettings))
				}
			} else {
				alert(`Invalid ZIP Code: ${zip.trim()}`);
			}
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleSendZip();
		}
	}

	const handleZipChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch(setPostalCode(e.target.value));
	}

	const handleLineupClick = (lineup: BaseLineup) => {
		if (lineups) {
			if (userId === 'ffffffff-ffff-ffff-ffff-ffffffffffff') {
				dispatch(fetchNewGuestUser({ settings, lineup, setToPrimeTime: false }));
			} else {
				dispatch(putNewLineup(
					{
						lineupName: lineup.providerName, utcOffset: lineup.utcOffset, observesDST: lineup.observesDst, providerId: lineup.providerId,
						deviceTypeCode: lineup.deviceTypeCode, userId: userId
					}
				));
			}
		}
	}

	if (lineupsStatus === DataState.SUCCEEDED) {
		for (let i = 0; i < lineups.providers.length; i++) {
			if (checkDeviceType(lineups.providers[i].deviceTypeCode)) {
				lineupsArray.push(
					<LineupCard key={lineups.providers[i].providerId + CreateUUID()} title={`Add "${lineups.providers[i].providerName}" to lineups`} onClick={() => handleLineupClick(lineups.providers[i])}>
						<LineupName>
							{lineups.providers[i].providerName}
							{deviceDesc !== '' ? " - " + deviceDesc : ''}
						</LineupName>
						<LineupButton src={Forward} />
					</LineupCard>
				)
			}
		}
	}

	if (newLineupStatus === DataState.SUCCEEDED) {
		const lineupId = userLineups.lineups[userLineups.lineups.length - 1].lineupId
		dispatch(setGridLineupID(lineupId));
		dispatch(fetchLineupChannels({ userId: userId, lineupId: lineupId }));
		const now = new Date()
		const dateTime = now.getFullYear() + leadingZeroBuilder(now.getMonth() + 1) + leadingZeroBuilder(now.getDate()) + leadingZeroBuilder(now.getHours()) + '00';
		dispatch(fetchSchedule({ userId: userId, lineupId: lineupId, startTimeToken: dateTime, duration: mainGridDuration * 60 }));
		dispatch(setModalOpen());
	}

	useEffect(() => {
		dispatch(setNewLineupStatus(DataState.IDLE))
		if (userId === 'ffffffff-ffff-ffff-ffff-ffffffffffff' && clearCode) {
			dispatch(setPostalCode(''));
			setClearCode(false);
		}

		// return (() => {

		// })
	})

	return (
		<ModalContainer>
			<ModalTitleBar>
				<ModalButton onClick={() => dispatch(setModalContent(ModalState.SOURCE))}>
					<ModalIconSM src={Back} alt='Back' /> Back
				</ModalButton>
				{userId === GetDefaultUser() ? <div></div> : <WhiteClose onClick={() => dispatch(setModalOpen())}>
					<ModalIconSM src={Close} alt='Close' /> Close
				</WhiteClose>}
			</ModalTitleBar>
			<ModalContent>
				<ModalHeader>Enter Your ZIP Code</ModalHeader>
				<TextInputWithButton>
					<ModalNumEntry type='number' pattern="[0-9]" placeholder="Enter Your ZIP Code" defaultValue={zip} value={zip} onChange={handleZipChange} onKeyDown={((e) => handleKeyDown(e))} autoFocus />
					<ModalIconMed src={Forward} onClick={handleSendZip} />
				</TextInputWithButton>
				{lineupsStatus === DataState.LOADING ? <Loading message='Loading' /> :
					lineupsStatus === DataState.FAILED ? 'Failed to load Lineups' :
						lineupsStatus === DataState.IDLE ? '' :
							lineups.errorCode !== 0 ? <DataError>{handleErrorMessage(lineups.errorCode)}</DataError> :
								<>
									{newUserStatus === DataState.LOADING || newLineupStatus === DataState.LOADING ? <Loading message='Loading' /> :
										newUserStatus === DataState.FAILED || newLineupStatus === DataState.FAILED ? <GridFailed errorMessage={'Could not load Lineups'} errorCode={1} /> :
											<LineupsContainer>
												{lineupsArray}
											</LineupsContainer>
									}
								</>
				}
			</ModalContent>
		</ModalContainer>
	)
}

export default ModalZipCodeEnter