import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { DataState } from "../../interfaces/enums";
import { EventDetails } from "../../interfaces/eventDetails";
import { CastCrewDetails, CastAndCrewFetch } from "../../interfaces/castCrewDetails";
import { Channel } from "../../interfaces/channels";
import { getBaseUrl } from "../Global/GobalFunctions";
import { fetchLog, consoleLog, putLog, deleteLog, errorLog } from "../Global/ConsoleLogging";

export interface modalState {
	castCrewOpen: boolean;
	eventDetailsStatus: DataState;
	castDataStatus: DataState;
	eventDetails: EventDetails;
	castData: CastCrewDetails;
	detailsChannel: Channel;
	castAndCrewFetch: CastAndCrewFetch;
	pVRLogStatus: DataState;
	remoteScheduleStatus: DataState;
}

const initialState: modalState = {
	castCrewOpen: false,
	eventDetailsStatus: DataState.LOADING,
	castDataStatus: DataState.LOADING,
	eventDetails: {} as EventDetails,
	castData: {} as CastCrewDetails,
	detailsChannel: {} as Channel,
	castAndCrewFetch: {} as CastAndCrewFetch,
	pVRLogStatus: DataState.IDLE,
	remoteScheduleStatus: DataState.IDLE,
};

const baseUrl = getBaseUrl();

export interface FetchEventDetails {
	userId: string;
	lineupId: string;
	channelIndex: number;
	eventId: number;
}

export const fetchEventDetails = createAsyncThunk('details/fetchEventDetails', async (data: FetchEventDetails) => {
	return fetch(`${baseUrl}detail/${data.userId}/${data.lineupId}/${data.channelIndex}/${data.eventId}`)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Details Fetch Error: ${err}`, true))
})

/**
 * 
 * @param 
 * @returns sets the cast data for the Bio page
 */
export const fetchCastCrew = createAsyncThunk('details/fetchCastCrew', async (data: { channelId: number, programId: number, personId: number }) => {
	return fetch(`${baseUrl}contributor/${data.channelId}/${data.programId}/${data.personId}`)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Cast Crew Fetch Error: ${err}`, true));
})

/**
 * Logs when the user creates a PVR event or iCal event
 * @param userId id of the user
 * @param doc the remote scheduling doc
 */
export const putPVRiCalLog = createAsyncThunk('details/putPVRLog', async (data: { userId: string, doc: string }) => {
	const url = `${baseUrl}pvr/log/${data.userId}`;
	const reqData = {
		document: data.doc
	}
	const req = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`PVR or iCal Log error: ${err}`, true))
})

/**
 * Adds an Event to the remote schedule for the user
 * @param userId id of the user
 * @param doc the remote scheduling doc
 * @param expires the end dateTime of the event
 */
export const putRemoteScheduleEvent = createAsyncThunk('details/putRemoteScheduleEvent', async (data: { userId: string, doc: string, expires: string }) => {
	const url = `${baseUrl}pvr/schedule/${data.userId}`;
	const reqData = {
		document: data.doc,
		expires: data.expires
	}
	const req = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reqData),
	}
	return fetch(url, req)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`PVR Log error: ${err}`, true))
})


export const detailsSlice = createSlice({
	name: 'details',
	initialState,
	reducers: {
		setCastCrewOpen: (state) => {
			state.castCrewOpen = !state.castCrewOpen
		},
		setDetailsChannel: (state, action) => {
			state.detailsChannel = action.payload
		},
		setRemoteSchedulingStatus: (state, action) => {
			state.remoteScheduleStatus = action.payload;
		}
	},
	extraReducers: builder => {

		// #region details
		// fetchEventDetails
		builder.addCase(fetchEventDetails.pending, (state) => {
			state.eventDetailsStatus = DataState.LOADING;
		})
		builder.addCase(fetchEventDetails.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.eventDetailsStatus = DataState.FAILED
			} else {
				state.eventDetails = action.payload;
				state.eventDetailsStatus = DataState.SUCCEEDED;
			}
		})
		builder.addCase(fetchEventDetails.rejected, (state) => {
			state.eventDetailsStatus = DataState.FAILED
		})
		//#endregion

		// fetchCastCrew
		builder.addCase(fetchCastCrew.pending, (state) => {
			state.castDataStatus = DataState.LOADING;
		})
		builder.addCase(fetchCastCrew.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.castDataStatus = DataState.FAILED
			} else {
				state.castData = action.payload;
				state.castDataStatus = DataState.SUCCEEDED;
			}
		})
		builder.addCase(fetchCastCrew.rejected, (state) => {
			state.castDataStatus = DataState.FAILED
		})

		// #region PVR/Remote Scheduling
		// putPVRiCalLog
		builder.addCase(putPVRiCalLog.pending, (state) => {
			state.pVRLogStatus = DataState.LOADING
		})
		builder.addCase(putPVRiCalLog.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.pVRLogStatus = DataState.FAILED
			} else {
				state.pVRLogStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(putPVRiCalLog.rejected, (state) => {
			state.pVRLogStatus = DataState.FAILED
		})

		// putRemoteScheduleEvent
		builder.addCase(putRemoteScheduleEvent.pending, (state) => {
			state.remoteScheduleStatus = DataState.LOADING
		})
		builder.addCase(putRemoteScheduleEvent.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.remoteScheduleStatus = DataState.FAILED
			} else {
				state.remoteScheduleStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(putRemoteScheduleEvent.rejected, (state) => {
			state.remoteScheduleStatus = DataState.FAILED
		})
		// #endregion
	}
});

export const {
	setCastCrewOpen, setDetailsChannel, setRemoteSchedulingStatus
} = detailsSlice.actions;

export const selectCastCrewOpen = (state: RootState) => state.details.castCrewOpen;
export const selectDetailsChannel = (state: RootState) => state.details.detailsChannel;
export const selectEventDetailsStatus = (state: RootState) => state.details.eventDetailsStatus;
export const selectEventDetails = (state: RootState) => state.details.eventDetails;
export const selectCastDataStatus = (state: RootState) => state.details.castDataStatus;
export const selectCastData = (state: RootState) => state.details.castData;
export const selectPVRLogStatus = (state: RootState) => state.details.pVRLogStatus;
export const selectRemoteSchedulingStatus = (state: RootState) => state.details.remoteScheduleStatus;

export default detailsSlice.reducer;