import styled from "styled-components";
import { SettingsOptionsContainer, SettingsPulldown } from "./SettingsStyles";
import { BlueButton } from "../../Global/GobalStyledComponents";

export const LineupEditContainer = styled(SettingsOptionsContainer)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 95%;
	}
`

export const LineupEditControls = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 7px;
	border-radius: 3px;
	background: #4b4c4c;
	color: #eee;
	padding: 5px;
	//max-width: 600px;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		flex-direction: column;
		align-items: center;
	}
`

export const LineupEditButtons = styled.div`
	@media (max-width: ${({ theme }) => theme.mobile}) {
		min-width: 100%;
		display: flex;
		flex-direction: row;

		& button {
			margin-right: 10px;
		}
	}
`

export const LineupEditing = styled.span`
	align-self: center;
	margin-left: 5px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		margin-top: 7px;
	}
`

export const LineupEditPulldown = styled(SettingsPulldown)`
	width: fit-content;
	padding: 5px;
	margin-right: 7px;
`

export const LineupEditSelector = styled.div`
	margin-bottom: 7px;
	border-radius: 3px;
	background: #4b4c4c;
	color: #eee;
	padding: 5px;
	//max-width: 600px;
`

export const LineupEditLegand = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 7px;
`

export const LineupLegandKey = styled.span`
	width: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px;
	margin-right: 13px;
	border-radius: 3px;
`

/*
	lineupEdit: '#ffff99',
	lineupAdded: '#99ff99',
	lineupHidden: '#ff9999'
*/

export const EditKey = styled(LineupLegandKey)`
 background: ${({ theme }) => theme.lineupEdit};
`
export const AddedKey = styled(LineupLegandKey)`
	background: ${({ theme }) => theme.lineupAdded};
`
export const HiddenKey = styled(LineupLegandKey)`
	background: ${({ theme }) => theme.lineupHidden};
`

export const LineupEditChannelContainer = styled.div<{ bgColor: string } >`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 20px 15px;
	border: ${({ theme }) => theme.gridBordersSmall};
	border-radius: 3px;
	background: ${p => (p.bgColor)};
	margin-top: 10px;
	width: 100%;
	max-width: 660px;
`

export const LineupEditChannelBox = styled.label`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	user-select: none;
`

export const EditChannelNumber = styled.span`
	font-size: 20px;
	margin-right: 10px;
`

export const EditChannelName = styled.span`
	font-size: 14px;
	margin-right: 7px;
`

export const LineupControls = styled.div`
	display: flex;
	flex-direction: row;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		flex-direction: column;
	}
`

export const LineupOrderControls = styled.span`
	margin-right: 5px;
	display: flex;
	flex-direction: row;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		min-width: fit-content;
		margin-bottom: 5px;
	}
`

export const LineupOrderButton = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 7px;
	cursor: pointer;
`

export const EditShowButtonContainer = styled.span`
	@media (max-width: ${({ theme }) => theme.mobile}) {
		display: flex;
		flex-direction: row;
	}
`

export const LineupEditButton = styled(BlueButton)`
	padding: 10px;
	width: fit-content;
	margin-right: 7px;
	margin-top: 0;
`

export const ChannelEditButton = styled(LineupEditButton)`
	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 50px;
		margin-right: 10px;
	}
`

export const ChangeLineupLink = styled.span`
	cursor: pointer;
	text-decoration: underline;
`

export const StyledAddChannelList = styled.div`
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	height: 150px;
	width: 80%;
`

export const AddChannelBox = styled.div`
	display: flex;
	flex-direction: row;
	border: ${({ theme }) => theme.gridBorders};
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 3px;
	cursor: pointer;

	&:active {
		opacity: 0.75;
		transform: scale(0.98);
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
	}
`