import { setModalOpen } from "../modalSlice";
import {
	ModalContainer, ModalTitleBar, ModalIconSM, ModalContent, ModalBlueButton, ModalHeader, ModalPulldown,
	EditChannelInuptContainer, EditLineupLabel, EditLineupText, MajorMinorInupt, EditChannelContainter, EditLineupNum,
	WhiteClose
} from '../ModalStyles';
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
	selectChannelToEdit, selectlineupChannelsToEdit, setMajorChannel, setRFChannel, setMinorChannel, setChannelCallsign,
	setChannelNetwork, setChannelSource, postUpdateChannelInfo
} from '../../UserSettingsPages/SettingsState/lineupsSlice';
import { selectUserId } from "../../UserSettingsPages/SettingsState/userSlice";

const ModalChannelEdit = () => {
	const dispatch = useAppDispatch();
	const channel = useAppSelector(selectChannelToEdit);
	const lineup = useAppSelector(selectlineupChannelsToEdit);
	const userId = useAppSelector(selectUserId);

	const handleRFChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setRFChannel(parseInt(e.target.value)));
	}

	const handleMajorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setMajorChannel(parseInt(e.target.value)));
	}

	const handleMinorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setMinorChannel(parseInt(e.target.value)));
	}

	const handleCallsignChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setChannelCallsign(e.target.value));
	}

	const handleNetworkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setChannelNetwork(e.target.value));
	}

	const handleSourceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		dispatch(setChannelSource(parseInt(e.target.value)));
	}

	const handleSave = () => {
		dispatch(postUpdateChannelInfo({ userId, lineupId: lineup.lineupId, channel }));
		dispatch(setModalOpen());
	}

	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				<WhiteClose onClick={() => dispatch(setModalOpen())}>
					<ModalIconSM src={Close} alt='Close' /> Close
				</WhiteClose>
			</ModalTitleBar>
			<ModalContent>
				<ModalHeader>Channel Display Info</ModalHeader>
				<EditChannelContainter>
					<EditChannelInuptContainer>
						<EditLineupLabel htmlFor='channelNumber'>Channel</EditLineupLabel>
						<EditLineupNum type='number' pattern="[0-9]" onChange={handleRFChange} value={channel.rfChannel} id='channelNumber' />
					</EditChannelInuptContainer>
					<EditChannelInuptContainer>
						<EditLineupLabel>Major.Minor</EditLineupLabel>
						<MajorMinorInupt type='number' pattern="[0-9]" onChange={handleMajorChange} disabled={channel.majorChannel === 0} value={channel.majorChannel} id='majorNumber' />
						<MajorMinorInupt type='number' pattern="[0-9]" onChange={handleMinorChange} disabled={channel.majorChannel === 0} value={channel.minorChannel} id='minorNumber' />
					</EditChannelInuptContainer>
				</EditChannelContainter>
				<EditChannelContainter>
					<EditChannelInuptContainer>
						<EditLineupLabel htmlFor='callsign'>Callsign</EditLineupLabel>
						<EditLineupText onChange={handleCallsignChange} value={channel.callSign} id='callsign' />
					</EditChannelInuptContainer>
					<EditChannelInuptContainer>
						<EditLineupLabel htmlFor='network'>Network</EditLineupLabel>
						<EditLineupText onChange={handleNetworkChange} value={channel.network} id='network' />
					</EditChannelInuptContainer>
				</EditChannelContainter>
				<EditChannelContainter>
					<EditChannelInuptContainer>
						<EditLineupLabel htmlFor='source'>Source</EditLineupLabel>

						{/* 
							0	Default NULL
							1	Analog Broadcast
							2	Digital Broadcast
							3	Analog Cable
							4	Digital Cable
							5	Digital Cable w/QAM
							6	Satellite
							7	Over the Top
						*/}
						<ModalPulldown onChange={handleSourceChange} value={channel.providerTypeId}>
							<option value={0}>
								---
							</option>
							<option value={1}>
								Analog Broadcast
							</option>
							<option value={2}>
								Digital Broadcast
							</option>
							<option value={3}>
								Analog Cable
							</option>
							<option value={4}>
								Digital Cable
							</option>
							<option value={6}>
								Satellite
							</option>
							<option value={7}>
								Streaming
							</option>
						</ModalPulldown>
					</EditChannelInuptContainer>
				</EditChannelContainter>
				<ModalBlueButton onClick={() => handleSave()}>
					Save
				</ModalBlueButton>
			</ModalContent>
		</ModalContainer>
	)
}

/*
		B - broadcast
			Digital Cable
		[C - cable ready TV
		D - digital cable]

		O - live streaming
			Analog Cable
		[A - analog cable
		R - cable rebuild lineup]

		S - satellite
	*/

export default ModalChannelEdit