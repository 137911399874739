import {
	ModalTitleBar, ModalContent, ImageContainer, ModalMainContent, ViewGuestButton, ModalButtons, LoginInButton,
	WelcomeModalContainer
} from "../ModalStyles";
import TitanTVLogo from '../../../img/TitanTV_Logo.png';
import { setModalOpen, setModalContent } from "../modalSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ModalState } from "../../../interfaces/enums";
import { fetchNewGuestUser, newUserSettings, selectUserId } from "../../UserSettingsPages/SettingsState/userSlice";
import {
	selectPostalCode, selectDefaultLineup, selectStartTime, selectSettingMainGridDuration, selectFontSize, selectHideNGChannels,
	selectWrapCellText, selectFixedCellHeight, selectDisplayChannelLogo, selectDisplayShowCards, selectDailyGridWidth, selectGridColorScheme,
	selectCellDescription, selectEpsTitle, selectSeasonNumber, selectHDIndicator, selectNewRepeat, selectGenreDisplay, selectMovieRating,
	selectYearProduction, selectTvRating, selectProgramType, selectNextGenIndicator, selectAudioDescription, selectSocialMediaLinks,
	selectDetailsSynopsis, selectDetailsShowCard, selectCastBio, selectEnableWatch, selectEnableRecord, selectEnableBurnToDVD,
	selectEnableRemoteScheduling, selectPVRChannel,
	selectCompactMode
} from "../../UserSettingsPages/SettingsState/gridSettingsSlice";
import { selectChannelSort } from "../../GridPages/mainGridSlice";
import { GetDefaultUser } from "../../Global/GobalFunctions";
import { BaseLineup } from "../../../interfaces/settings";
import { selectLineups } from "../../UserSettingsPages/SettingsState/lineupsSlice";
import CloseButtonNewUser from "./CloseButtonNewUser";
import styled from "styled-components";

const ModalWelcome = () => {
	const settings: newUserSettings = {
		postalCode: useAppSelector(selectPostalCode),
		defaultLineupId: useAppSelector(selectDefaultLineup),
		gridSettings: {
			gridStartHour: parseInt(useAppSelector(selectStartTime)),
			gridDuration: useAppSelector(selectSettingMainGridDuration),
			channelSortOrder: useAppSelector(selectChannelSort),
			gridFontSize: useAppSelector(selectFontSize),
			hideNextGen: useAppSelector(selectHideNGChannels),
			wrapCellText: useAppSelector(selectWrapCellText),
			fixedCellHeight: useAppSelector(selectFixedCellHeight),
			includeLogos: useAppSelector(selectDisplayChannelLogo),
			includeShowCards: useAppSelector(selectDisplayShowCards),
			gridDailyWidth: useAppSelector(selectDailyGridWidth),
			gridColorScheme: useAppSelector(selectGridColorScheme),
			compactMode: useAppSelector(selectCompactMode)
		},
		cellSettings: {
			includeDescription: useAppSelector(selectCellDescription),
			includeEpisodeTitle: useAppSelector(selectEpsTitle),
			includeSeasonNumbers: useAppSelector(selectSeasonNumber),
			includeHDIndicator: useAppSelector(selectHDIndicator),
			includeNewRepeatIndicator: useAppSelector(selectNewRepeat),
			includeGenres: useAppSelector(selectGenreDisplay),
			includeMovieYear: useAppSelector(selectYearProduction),
			includeMovieRatings: useAppSelector(selectMovieRating),
			includeTVRatings: useAppSelector(selectTvRating),
			includeOriginalAirDate: true,
			includeProgramType: useAppSelector(selectProgramType),
			includeNextGenIndicator: useAppSelector(selectNextGenIndicator),
			includeAudioStreams: useAppSelector(selectAudioDescription),
			includeSocialMediaLinks: useAppSelector(selectSocialMediaLinks),
		},
		detailSettings: {
			includeSeriesDescription: useAppSelector(selectDetailsSynopsis),
			includeShowCards: useAppSelector(selectDetailsShowCard),
			includeCastAndCrewLinks: useAppSelector(selectCastBio)
		},
		pvrSettings: {
			enableWatch: useAppSelector(selectEnableWatch),
			enableRecord: useAppSelector(selectEnableRecord),
			enableBurnToDVD: useAppSelector(selectEnableBurnToDVD),
			enableRemoteScheduling: useAppSelector(selectEnableRemoteScheduling),
			channel: useAppSelector(selectPVRChannel)
		}
	}

	const userId = useAppSelector(selectUserId);
	const dispatch = useAppDispatch();
	const lineups = useAppSelector(selectLineups);
	const handleClose = () => {
		const defaultLineup = lineups.lineups[0]
		if (userId === GetDefaultUser()) {
			const eastcostlineup: BaseLineup = {
				providerId: defaultLineup.providerId,
				deviceTypeCode: defaultLineup.deviceType,
				providerName: defaultLineup.providerName,
				utcOffset: defaultLineup.utcOffset,
				observesDst: defaultLineup.observesDst,
			}
			dispatch(fetchNewGuestUser({ settings, lineup: eastcostlineup, setToPrimeTime: true }));
		}
		dispatch(setModalOpen());
	}

	return (
		<WelcomeModalContainer>
			<ModalTitleBar>
				<div></div>
			</ModalTitleBar>
			<ModalContent>
				<ImageContainer src={TitanTVLogo} alt="" />
				<ModalMainContent>
					Welcome to TitanTV, your free online TV schedule guide.
					<p>
						Click the <Bold>View as Guest</Bold> button to select your location and provider and view a guide anonymously.
					</p>
					<p>
						Click the <Bold>Login</Bold> link if you have created an account in this or the previous version of TitanTV.
					</p>
					<p>
						<Bold>If you had an account in the previous version of TitanTV, do not create a new account. This will prevent your existing account's lineups and settings from migrating to the new site.</Bold>
					</p>

				</ModalMainContent>
				<ModalButtons>
					<ViewGuestButton onClick={() => dispatch(setModalContent(ModalState.SOURCE))}>View as Guest</ViewGuestButton>
					<LoginInButton onClick={() => dispatch(setModalContent(ModalState.LOGIN))}>Login</LoginInButton>
				</ModalButtons>
			</ModalContent>
		</WelcomeModalContainer>
	)
}

export default ModalWelcome

const Bold = styled.span`
	font-weight: bold;
`