import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import gridColorsSlice from '../component/GridPages/gridColorsSlice';
import headerSlice from '../component/Header/headerSlice';
import modalReducer from '../component/Modal/modalSlice';
import filterDisplaySlice from '../component/Modal/filterDisplaySlice';
import otherLegalSlice from '../component/OtherLegal/otherLegalSlice';
import gridSettingsSlice from '../component/UserSettingsPages/SettingsState/gridSettingsSlice';
import counterSlice from '../features/counter/counterSlice';
import mainGridSlice from '../component/GridPages/mainGridSlice';
import detailsSlice from '../component/GridPages/detailsSlice';
import favoritesRemidersSlice from '../component/UserSettingsPages/SettingsState/favoritesRemidersSlice';
import searchAdditionAiringsSlice from '../component/GridPages/searchAdditionAiringsSlice';
import userSlice from '../component/UserSettingsPages/SettingsState/userSlice';
import lineupsSlice from '../component/UserSettingsPages/SettingsState/lineupsSlice';

export const store = configureStore({
	reducer: {
		counter: counterSlice,
		header: headerSlice,
		gridColors: gridColorsSlice,
		modal: modalReducer,
		gridSettings: gridSettingsSlice,
		otherLegal: otherLegalSlice,
		filterDisplay: filterDisplaySlice,
		mainGrid: mainGridSlice,
		details: detailsSlice,
		favoritesReminders: favoritesRemidersSlice,
		searchAdditionAiringsSlice: searchAdditionAiringsSlice,
		userSlice: userSlice,
		lineupsSlice: lineupsSlice,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
