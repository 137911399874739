import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ColorType } from "../../interfaces/enums";

export interface IGridColors {
	Animated: string;
	Children: string;
	Comedy: string;
	Documentary: string;
	Drama: string;
	Education: string;
	Entertainment: string;
	Food: string;
	Game_Quiz: string;
	Health: string;
	Home: string;
	Information: string;
	Interview: string;
	Music: string;
	Nature: string;
	News: string;
	Other: string;
	Religious: string;
	SciFi: string;
	Science: string;
	Sports: string;
	Travel: string;
	True: string
}

export interface gridColorsState {
	GridColors: IGridColors;
}

const nonHightlightedGenre = '#f2f2f2'

function returnColors(colorType: ColorType): IGridColors {
	switch (colorType) {
		default: case ColorType.TITANTVSTANDARD:
			return (
				{
					Animated: '#fbc213',
					Children: '#cc99ff',
					Comedy: '#aaaacc',
					Documentary: '#bd8f8f',
					Drama: '#00c0c1',
					Education: '#ff8081',
					Entertainment: '#8db38c',
					Food: '#ffaba9',
					Game_Quiz: '#ffcc9a',
					Health: '#527392',
					Home: '#cb99cc',
					Information: '#cd9967',
					Interview: '#ffcccb',
					Music: '#cfc4a4',
					Nature: '#2f9a30',
					News: '#99cdff',
					Other: '#ffffff',
					Religious: '#8766CD',
					Science: '#ffff80',
					SciFi: '#81ff81',
					Sports: '#9acccb',
					Travel: '#9a99dd',
					True: '#eedd98',
				}
			);
		case ColorType.COMEDYDRAMA:
			return (
				{
					Animated: nonHightlightedGenre,
					Children: nonHightlightedGenre,
					Comedy: '#aaaacc',
					Documentary: nonHightlightedGenre,
					Drama: '#00c0c1',
					Education: nonHightlightedGenre,
					Entertainment: '#8db38c',
					Food: nonHightlightedGenre,
					Game_Quiz: nonHightlightedGenre,
					Health: nonHightlightedGenre,
					Home: nonHightlightedGenre,
					Information: nonHightlightedGenre,
					Interview: nonHightlightedGenre,
					Music: nonHightlightedGenre,
					Nature: nonHightlightedGenre,
					News: nonHightlightedGenre,
					Other: nonHightlightedGenre,
					Religious: nonHightlightedGenre,
					Science: nonHightlightedGenre,
					SciFi: nonHightlightedGenre,
					Sports: nonHightlightedGenre,
					Travel: nonHightlightedGenre,
					True: nonHightlightedGenre
				}
			)
		case ColorType.FAMILY:
			return (
				{
					Animated: '#fbc213',
					Children: '#cc99ff',
					Comedy: nonHightlightedGenre,
					Documentary: nonHightlightedGenre,
					Drama: nonHightlightedGenre,
					Education: '#ff8081',
					Entertainment: nonHightlightedGenre,
					Food: nonHightlightedGenre,
					Game_Quiz: nonHightlightedGenre,
					Health: nonHightlightedGenre,
					Home: nonHightlightedGenre,
					Information: nonHightlightedGenre,
					Interview: nonHightlightedGenre,
					Music: nonHightlightedGenre,
					Nature: '#2f9a30',
					News: nonHightlightedGenre,
					Other: nonHightlightedGenre,
					Religious: nonHightlightedGenre,
					Science: nonHightlightedGenre,
					SciFi: nonHightlightedGenre,
					Sports: nonHightlightedGenre,
					Travel: '#9a99dd',
					True: nonHightlightedGenre
				}
			)
		case ColorType.HARDCOPY:
			return (
				{
					Animated: nonHightlightedGenre,
					Children: nonHightlightedGenre,
					Comedy: nonHightlightedGenre,
					Documentary: nonHightlightedGenre,
					Drama: nonHightlightedGenre,
					Education: nonHightlightedGenre,
					Entertainment: nonHightlightedGenre,
					Food: nonHightlightedGenre,
					Game_Quiz: nonHightlightedGenre,
					Health: nonHightlightedGenre,
					Home: nonHightlightedGenre,
					Information: nonHightlightedGenre,
					Interview: nonHightlightedGenre,
					Music: nonHightlightedGenre,
					Nature: nonHightlightedGenre,
					News: nonHightlightedGenre,
					Other: nonHightlightedGenre,
					Religious: nonHightlightedGenre,
					Science: nonHightlightedGenre,
					SciFi: nonHightlightedGenre,
					Sports: nonHightlightedGenre,
					Travel: nonHightlightedGenre,
					True: nonHightlightedGenre
				}
			)
		case ColorType.NEWSHOUND:
			return (
				{
					Animated: nonHightlightedGenre,
					Children: nonHightlightedGenre,
					Comedy: nonHightlightedGenre,
					Documentary: '#bd8f8f',
					Drama: nonHightlightedGenre,
					Education: nonHightlightedGenre,
					Entertainment: nonHightlightedGenre,
					Food: nonHightlightedGenre,
					Game_Quiz: nonHightlightedGenre,
					Health: nonHightlightedGenre,
					Home: nonHightlightedGenre,
					Information: '#cd9967',
					Interview: nonHightlightedGenre,
					Music: nonHightlightedGenre,
					Nature: nonHightlightedGenre,
					News: '#99cdff',
					Other: nonHightlightedGenre,
					Religious: nonHightlightedGenre,
					Science: nonHightlightedGenre,
					SciFi: nonHightlightedGenre,
					Sports: nonHightlightedGenre,
					Travel: nonHightlightedGenre,
					True: nonHightlightedGenre
				}
			)
		case ColorType.SPORTSFAN:
			return (
				{
					Animated: nonHightlightedGenre,
					Children: nonHightlightedGenre,
					Comedy: nonHightlightedGenre,
					Documentary: nonHightlightedGenre,
					Drama: nonHightlightedGenre,
					Education: nonHightlightedGenre,
					Entertainment: nonHightlightedGenre,
					Food: nonHightlightedGenre,
					Game_Quiz: nonHightlightedGenre,
					Health: nonHightlightedGenre,
					Home: nonHightlightedGenre,
					Information: nonHightlightedGenre,
					Interview: nonHightlightedGenre,
					Music: nonHightlightedGenre,
					Nature: nonHightlightedGenre,
					News: nonHightlightedGenre,
					Other: nonHightlightedGenre,
					Religious: nonHightlightedGenre,
					Science: nonHightlightedGenre,
					SciFi: nonHightlightedGenre,
					Sports: '#9acccb',
					Travel: nonHightlightedGenre,
					True: nonHightlightedGenre
				}
			)
		case ColorType.TALK:
			return (
				{
					Animated: nonHightlightedGenre,
					Children: nonHightlightedGenre,
					Comedy: nonHightlightedGenre,
					Documentary: '#bd8f8f',
					Drama: nonHightlightedGenre,
					Education: nonHightlightedGenre,
					Entertainment: nonHightlightedGenre,
					Food: nonHightlightedGenre,
					Game_Quiz: nonHightlightedGenre,
					Health: nonHightlightedGenre,
					Home: '#cb99cc',
					Information: '#cd9967',
					Interview: nonHightlightedGenre,
					Music: nonHightlightedGenre,
					Nature: nonHightlightedGenre,
					News: nonHightlightedGenre,
					Other: nonHightlightedGenre,
					Religious: nonHightlightedGenre,
					Science: nonHightlightedGenre,
					SciFi: nonHightlightedGenre,
					Sports: '#9acccb',
					Travel: nonHightlightedGenre,
					True: nonHightlightedGenre
				}
			)
	}
}

const initialState: gridColorsState = {
	GridColors: returnColors(ColorType.TITANTVSTANDARD)
};

export const gridColorsSlice = createSlice({
	name: 'gridColors',
	initialState,
	reducers: {
		setColors: (state, action) => {
			state.GridColors = returnColors(action.payload)
		}
	}
});

export const { setColors } = gridColorsSlice.actions;

export const selectColors = (state: RootState) => state.gridColors;
export default gridColorsSlice.reducer;