const logging = true;
const genralLog = true;
const fetchLogging = true;
const putLogging = false;
const deleteLogging = false;
const errorLogging = true;


export const consoleLog = (message: string, shouldShow: boolean) => {
	if (logging && genralLog && shouldShow) {
		console.log(message);
	}
}

export const fetchLog = (message: string, shouldShow: boolean) => {
	if (logging && fetchLogging && shouldShow) {
		console.log(message);
	}
}

export const putLog = (message: string, shouldShow: boolean) => {
	if (logging && putLogging && shouldShow) {
		console.log(message);
	}
}

export const deleteLog = (message: string, shouldShow: boolean) => {
	if (logging && deleteLogging && shouldShow) {
		console.log(message);
	}
}

export const errorLog = (message: string, shouldShow: boolean) => {
	if (logging && errorLogging && shouldShow) {
		console.log(message);
	}
}