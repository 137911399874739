import React from 'react'
import {
	StyledSearchBar, SearchImgContainer, SearchImg, SearchOptions, SearchInputWithButton, TextInput
} from './HeaderStyles';
import Antop from '../../img/antop_logo.png'
import AntennaWeb from '../../img/antenna-web-logo.png'
import SearchIcon from '../../img/Icons/Gray Icons/TitanTV Icon_Search_Gray.png'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ChangeEvent } from "react";
import {
	selectSearchTerms, setSearchTerm, postSearchJSON
} from '../GridPages/searchAdditionAiringsSlice';
import { selectGridLineupID } from '../GridPages/mainGridSlice';
import { selectUserId } from '../UserSettingsPages/SettingsState/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { FreestarAd } from '../Freestar/FreestarAd';


const SearchBar = () => {
	const dispatch = useAppDispatch()
	const searchText: string = useAppSelector(selectSearchTerms);
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const location = useLocation()
	const page = location.pathname
	const nav = useNavigate()

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch(setSearchTerm(e.target.value));
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	}

	const handleSearch = () => {
		dispatch(postSearchJSON({ userId: userId, lineupId: lineupId, searchTerm: searchText, numberOfDays: 14, titleOnly: true }))
		if (page !== '/SearchResults') {
			nav('/SearchResults')
		}
	}

	return (
		<StyledSearchBar>
			<SearchImgContainer>
				{/* <a href={'https://antopusa.com/'}><SearchImg src={Antop} alt="antopusa.com" /></a> */}
				<a href={'https://www.antennaweb.org/'} target="_blank" rel="noopener noreferrer"><SearchImg src={AntennaWeb} alt="AntennaWeb.org" /></a>
			</SearchImgContainer>
			<FreestarAd placementName={'titantv_header_atf_2'}></FreestarAd>
			<SearchOptions>
				<SearchInputWithButton>
					<TextInput type='text' placeholder='Search for Programming' value={searchText} onChange={handleSearchChange} onKeyDown={((e) => handleKeyDown(e))} />
					<img src={SearchIcon} alt='Click to perform text search' title='Click to perform text search' width='20px' height='20px' onClick={() => handleSearch()} />
				</SearchInputWithButton>
				{/* <OptionsButton src={OptionsIcon} /> */}
			</SearchOptions>
		</StyledSearchBar>
	)
}

export default SearchBar