import LegalTabBar from '../../components/LegalTabBar'
import { LegalContainer, LegalHeader, LegalLink, LegalList } from '../../components/OtherLegalComponents'
import {
	Bold, StyledLink, Table, TR, TH, TD
} from '../../../Global/GobalStyledComponents'
import { useEffect } from 'react'
import FaqsHelpHeader from '../FaqsHelpHeader'

const CCPA = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	})

	return (
		<>
			<FaqsHelpHeader />
			<LegalTabBar />
			<LegalContainer>
				<LegalHeader>California Privacy Notice</LegalHeader>
				<br />
				<LegalHeader>Effective as of January 1, 2020</LegalHeader>
				<LegalHeader>Updated July 20, 2020</LegalHeader>
				<Bold>1. Scope</Bold>
				<p>
					This California Privacy Notice (this <Bold>“California Notice”</Bold>) pursuant to the California Consumer
					Privacy Act of 2018 (the “ CCPA”) applies to you if you are a natural resident of the State of
					California (<Bold>"California Consumer"</Bold> or <Bold>“you”</Bold>).
				</p>
				<p>
					This California Notice supplements the information contained in our Privacy Policy and describes
					how we collect, use, disclose, and otherwise process personal information of California Consumers,
					either online or offline, within the scope of the CCPA.
				</p>
				<p>
					Unless otherwise expressly stated, all terms in this California Notice have the same meaning
					as defined in our <StyledLink to='/privacy'>Privacy Policy</StyledLink> or as otherwise defined in the CCPA.
				</p>
				<Bold> 2. Personal Information Disclosures</Bold>
				<p>
					When we use the term “personal information” in this California Notice, we mean information that
					identifies, relates to, describes, is reasonably capable of being associated with, or could
					reasonably be linked, directly or indirectly, with a particular California Consumer or household.
				</p>
				<p>
					For the purposes of this California Notice, personal information does not include:
				</p>
				<LegalList>
					<li>Publicly available information from government records.</li>
					<li>
						Deidentified, aggregated or anonymized information that is maintained in a form that is not
						capable of being associated with or linked to a California Consumer.
					</li>
					<li>Information excluded from the CCPA's scope, such as:</li>
					<LegalList>
						<li>
							Health or medical information covered by the Health Insurance Portability and Accountability Act
							of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical
							trial data;
						</li>
						<li>
							Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting
							Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act
							(FIPA), and the Driver's Privacy Protection Act of 1994.
						</li>
					</LegalList>
					<li>nformation relating to our job applicants, employees, contractors and other TitanTV personnel.</li>
				</LegalList>
				<Bold> Collection and Use of Personal Information</Bold>
				<p>
					We collect personal information from and about California Consumers for a variety of purposes.
					To learn more about the types of personal information we collect, the sources from which we collect
					or receive personal information, and the purposes for which we use this information, please refer
					to the following sections in our Privacy Policy: Sections 1 (Information We Collect and How We Collect
					It), 2 (Information You Provide Us), 3 (Information Collected Automatically), 4 (Use of Information),
					and 5 (Disclosure of Information).
				</p>
				<p>
					In the last 12 months, we have collected the following categories of personal information, from the sources described below.
				</p>
				<Table>
					<thead>
						<TR>
							<TH>Category of Personal Information Collected </TH>
							<TH>Collected</TH>
							<TH>Sources</TH>
						</TR>
					</thead>
					<tbody>
						<TR>
							<TD>
								<Bold>Identifiers</Bold>
								, such as your name, address, IP address, email address, or other similar identifiers
							</TD>
							<TD>Yes</TD>
							<TD>Directly from you</TD>
						</TR>
						<TR>
							<TD>
								<Bold>Characteristics of protected classes or groups under state or federal law</Bold>
								, such as your race, sex or marital status
							</TD>
							<TD>No</TD>
							<TD>N/A</TD>
						</TR>
						<TR>
							<TD>
								<Bold>Commercial Information</Bold>
								, such as information regarding your use of our services
							</TD>
							<TD>Yes</TD>
							<TD><p>Directly from you</p>
								Your browser or device or from your use of the TitanTV.com Mobile Application
							</TD>
						</TR>
						<TR>
							<TD><Bold>Biometric information</Bold>, such as your fingerprints </TD>
							<TD>No</TD>
							<TD>N/A</TD>
						</TR>
						<TR>
							<TD>
								<Bold>Internet/Network Information</Bold>, such as your browsing history, search history, client ID, and
								information regarding interaction with our websites, TitanTV Mobile Application, or
								advertisements
							</TD>
							<TD>Yes</TD>
							<TD>
								Your browser or device or from your use of the TitanTV.com Mobile Application
							</TD>
						</TR>
						<TR>
							<TD>
								<Bold>Geolocation Data</Bold>, such as information about your physical location collected from geolocation
								features on your browser, device or TitanTV.com Mobile Application, including your IP address,
								zip code, and street level address.
							</TD>
							<TD>Yes</TD>
							<TD>
								<p>
									Directly from you
								</p>
								Your browser or device or from your use of the TitanTV.com Mobile Application
							</TD>
						</TR>
						<TR>
							<TD>
								<Bold>Sensory Information</Bold>, such as audio or electronic information
							</TD>
							<TD>No</TD>
							<TD>N/A</TD>
						</TR>
						<TR>
							<TD><Bold>Professional or employment related information</Bold></TD>
							<TD>No</TD>
							<TD>N/A</TD>
						</TR>
						<TR>
							<TD>
								<Bold>Inferences</Bold>, including information generated from your use of the websites reflecting predictions about your preferences.
							</TD>
							<TD>Yes</TD>
							<TD>Your browser or device or from your use of the TitanTV.com Mobile Application </TD>
						</TR>
					</tbody>
				</Table>
				<Bold>Purposes for Collecting Personal Information</Bold>
				<p>
					We collect, use or disclose your personal information, explained in the table above, for one or
					more business purposes, including:
				</p>
				<LegalList>
					<li>to present, manage or administer the Services and its content for you;</li>
					<li>to optimize or customize the delivery of advertising or content to you;</li>
					<li>to contact you and respond to your messages;</li>
					<li>to carry out our obligations and enforce our rights arising from any contracts entered into between you and us; and</li>
					<li>for other business purposes specified in our Privacy Policy or at the time the information is gathered.</li>
				</LegalList>
				<Bold>Disclosure of Personal Information</Bold>
				<p>
					In the previous 12 months, we have disclosed your personal information, explained in the
					table above, to third parties for a business purpose, including:-
				</p>
				<LegalList>
					<li>to TITANTV’s clients, suppliers, licensors, advertising partners, and service vendors; </li>
					<li>
						to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
						dissolution, or other sale or transfer of some or all of TITANTV's assets, whether as a going
						concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information
						held by TITANTV about users of the Services is among the assets transferred;
					</li>
					<li>
						if required to do so by law or if TITANTV believes that such action is necessary to comply
						with the law or with legal process including any government or regulatory request;
					</li>
					<li>
						if we believe disclosure is necessary to protect against misuse or unauthorized use of the
						Services or to protect the personal safety or property of users of the Services, the public or TITANTV and its employees;
					</li>
					<li>for any other purpose disclosed by us when you provide the information; and</li>
					<li>with your consent.</li>
				</LegalList>
				<Bold>Sale</Bold>
				<p>
					Advertising is a core part of TitanTV’s business. Our free Services are supported by the ads
					we and others display on the Services, including on TitanTV.com, AntennaWeb.org, and the
					TitanTV Mobile Application. TitanTV serves display ads, which appear as images, animation,
					or video. TitanTV also serves text ads that appear as text links.
				</p>
				<p>
					Targeted / interest-based ads namely, advertisements of products and services that a person is
					more likely to be interested in, tend to be more effective. For this reason, TitanTV enables its
					third-party ad partners to place cookies and web beacons, as such terms are described in our
					Privacy Policy, and other similar technologies (collectively, <Bold>“Marketing Cookies”</Bold>) on your
					computer, smart phone, or other device when you use the Services. Third-party ad partners use
					these Marketing Cookies, which include an online identifier to recognize you, to collect
					information about your use of our Services in order to serve ads that may be relevant to you on
					the Services and on other unaffiliated websites. Please note that making personal information
					available to our third-party ad partners may be considered a “sale” under the CCPA. If it does
					constitute a “sale”, then we hereby notify you that in the previous 12 months, we may have sold
					in this manner the following categories of information (as these categories are defined by the
					CCPA) to such third-party ad partners: identifiers; internet or electronic network activity
					information; commercial information; geolocation data; inferences, including advertising and
					content related inferences.
				</p>
				<p>
					Third-party targeted advertising cookies may also be placed on your browser after being generated
					on a website you visit that is not owned or operated by TitanTV. TitanTV has no control over such
					third-party targeted advertising cookies.
				</p>
				<p>
					If you wish to opt out of the sale of your personal information, please use the tools provided on
					the following webpage: <StyledLink to='/donotsell'>Do Not Sell My Information</StyledLink>
				</p>
				<Bold>California Consumers’ Privacy Rights</Bold>
				<p>
					As a California resident, you may be able to exercise the following rights in relation to the
					personal information about you that we have collected (subject to certain limitations at law):
				</p>
				<Table>
					<tbody>
						<TR>
							<TD><Bold>The Right to Know</Bold></TD>
							<TD>
								<p>
									You have the right to request any or all of the following information relating to your personal
									information we have collected and disclosed in the last 12 months, upon verification of your
									identity:
								</p>
								<LegalList>
									<li>The specific pieces of personal information we have collected about you;</li>
									<li>The categories of personal information we have collected about you;</li>
									<li>The categories of sources of the personal information;</li>
									<li>
										The categories of personal information that we have disclosed to third parties for a business
										purpose, and the categories of recipients to whom this information was disclosed;
									</li>
									<li>
										The categories of personal information we have sold about you (if any), and the categories of third
										parties to whom the information was sold; and
									</li>
									<li>
										The business or commercial purposes for collecting or, if applicable, selling the personal information
									</li>
								</LegalList>
							</TD>
						</TR>
						<TR>
							<TD><Bold>The Right to Request Deletion</Bold></TD>
							<TD>
								You have the right to request the deletion of personal information we have collected from you,
								subject to certain exceptions.
							</TD>
						</TR>
						<TR>
							<TD><Bold>The Right to Opt Out of Personal Information Sales</Bold></TD>
							<TD>
								You have the right to direct us not to sell personal information we have collected about you to
								third parties now or in the future. If you are under the age of 16, you have the right to opt in,
								or to have a parent or guardian opt in on your behalf, to such sales.
							</TD>
						</TR>
						<TR>
							<TD><Bold>The Right to Non-Discrimination</Bold></TD>
							<TD>
								<p>You have the right not to receive discriminatory treatment for exercising these rights.</p>
								However, please note that if the exercise of these rights limits our ability to process
								personal information (such as in the case of a deletion request), we may no longer be
								able to provide you our services or engage with you in the same manner.
							</TD>
						</TR>
						<TR>
							<TD><Bold>“Shine the Light”</Bold></TD>
							<TD>California residents that have an established business relationship with us have rights to
								know how their information is disclosed to third parties for their direct marketing purposes
								under California’s “Shine the Light” law (Civ. Code §1798.83).
							</TD>
						</TR>
					</tbody>
				</Table>
				<Bold>To Exercise Your Right to Know or Right to Deletion</Bold>
				<p>
					To exercise your Right to Know and/or your Right to Deletion, please submit a request by:
				</p>
				<LegalList>
					<li>
						Emailing us at <LegalLink href='mailto:privacy@TitanTV.com'>privacy@TitanTV.com</LegalLink> with the subject line “California Rights Request”, or
					</li>
					<li>Calling 319-365-5597.</li>
				</LegalList>
				<p>
					We will need to verify your identity before processing your request. In order to verify your
					identity, we will generally require the matching of sufficient information you provide us to the
					information we maintain about you in our systems. Although we try to limit the personal
					information collected in connection with a request to exercise the right to know and/or the
					right to deletion to that personal information identified on the California Resident Rights
					Request Form, certain requests may require us to obtain additional personal information from you.
					In certain circumstances, we may decline a request to exercise the right to know and/or right to
					deletion, particularly where we are unable to verify your identity.
				</p>
				<Bold>To Exercise Your Right to Opt-Out of Personal Information Sales</Bold>
				<p>
					If you wish to opt out of the sale of your personal information, please use the tools
					provided on the following webpage: <StyledLink to='/donotsell'>Do Not Sell My Information</StyledLink>
				</p>
				<p>
					Additionally, you may have the following “opt-out” options with respect to third-party cookies
					generated on TitanTV.com, AntennaWeb.org, and/or the TitanTV Mobile Application, including
					Marketing Cookies:
				</p>
				<p>
					<Bold>National Advertising Initiative’s (NAI):</Bold>
					The NAI offers the following tools to provide consumers
					with choices as to whether participating third parties can use your information to provide
					targeted/interest-based ads:
				</p>
				<LegalList>
					<li>
						For NAI’s General Opt-Out tool, please click:
						<LegalLink href='https://optout.networkadvertising.org/'>https://optout.networkadvertising.org/</LegalLink>
					</li>
					<li>
						For NAI’s Mobile Device Choices tool, please click:
						<LegalLink href='https://www.networkadvertising.org/mobile-choice'>https://www.networkadvertising.org/mobile-choice</LegalLink>
					</li>
					<li>
						For NAI’s Internet Connected TV Choices tool, please click:
						<LegalLink href='https://www.networkadvertising.org/internet-connected-tv-choices/'>https://www.networkadvertising.org/internet-connected-tv-choices/</LegalLink>
					</li>
				</LegalList>
				<p>
					<Bold>The Digital Advertising Alliance (DAA):</Bold>
					The DAA is an independent non-profit organization led by leading advertising and marketing trade
					associations. The DAA offers a tool that generates a list of participating vendors who are
					currently collecting data from you for the purposes of targeted advertising, from which you can
					opt out. To access the DAA’s tool, please click:
					<LegalLink href='https://optout.privacyrights.info/'>https://optout.privacyrights.info/</LegalLink>
				</p>
				<p>
					<Bold>Google Opt-Out:</Bold>
					If you are on the web, you can also opt out of Google Analytics by installing Google’s opt-out
					browser add-on and opt out of targeted/interest-based Google ads using Google’s Ads Settings tool at
					<LegalLink href='https://adssettings.google.com. '> https://adssettings.google.com. </LegalLink>
					Google may also participate in the NAI’s opt out tools above.
				</p>
				<p>
					<Bold>Mobile Ad Settings:</Bold>
					If you no longer wish to receive targeted/interest-based ads on your mobile applications,
					please refer to your device’s operating system settings, which may include “Limit Ad-Tracking”
					on iOS devices and “opt-out of interest-based advertising” on Android devices
				</p>
				<p>
					Please note that the opt-outs described above are device and browser based and as such, you must
					opt out on each device and each browser where you want your choice to apply. Since opt-outs may
					be stored via cookies, if you clear cookies, you must opt out again where you want your choices
					to apply. TitanTV does not maintain or control the opt-out mechanisms and settings above and is
					not responsible for those tools operated by others. Further, please note that the above choices
					will not opt you out of the use of previously collected and shared information or all
					targeted/interest-based advertising (such as that conducted on other sites). You can visit the
					NAI or DAA for more information about targeted/interest-based advertising
				</p>
				<p>
					TitanTV may still deliver contextual ads and ads based on our own personal information about you
					for business purposes, including serving you first-party ads directly, or using information for
					non-targeted ad purposes, such as attribution and analytics. We may also still share some personal
					information with our partners (who will function as our service providers in such instance) to
					help us perform functions such as measuring ad effectiveness, controlling how many times you see
					an ad, determining ad performance, providing and securing ads, providing aggregate statistics and
					analytics.
				</p>
				<Bold>Minors Under Age 16</Bold>
				<p>
					We do not knowingly “sell” the personal information of minors under 16 years of age without
					legally-required affirmative authorization.
				</p>
				<Bold>California’s “Shine the Light” Law</Bold>
				<p>
					In addition to the rights described above, California's "Shine the Light" law
					(Civil Code Section §1798.83) permits California residents that have an established business
					relationship with us to request certain information regarding our disclosure of certain types of
					personal information to third parties for their direct marketing purposes during the immediately
					preceding calendar year. To make such a request, please send an email to privacy@TitanTV.com.
					This request may be made no more than once per calendar year. We reserve our right not to respond
					to requests submitted other than to the email address specified in this section.
				</p>
				<Bold>Updates to This California Notice</Bold>
				<p>
					We will update this California Notice from time to time. When we make changes to this California
					Notice, we will change the "Last Updated" date at the beginning of this California Notice. All
					changes shall be effective from the date of publication unless otherwise provided in the notification
				</p>
				<Bold>Contact Us</Bold>
				<p>
					If you have any questions or comments concerning our Privacy Policy regarding TitanTV.com,
					please contact us at <LegalLink href='mailto:privacy@TitanTV.com'>privacy@TitanTV.com.</LegalLink>
				</p>

			</LegalContainer>
		</>
	)
}

export default CCPA