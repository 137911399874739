import { useEffect, useRef, useState } from 'react';
import { Channel } from '../../../interfaces/channels';
import { ScheduleChannel } from '../../../interfaces/schedule';
import { Event } from '../../../interfaces/schedule';
import {
	StyledChannelRow, ChannelContainer, ChannelInfo, ChannelCard, ChannelID, ExpandIcon, Events, ChannelCallsign, NextGenLogo,
	ChannelCardSM, ChannelCallsignSM, NextGenText
} from './GridStyles';
import EventContent from './EventContent';
import Expand from '../../../img/Icons/Blue Icons/TitanTV Icon_Expand_Blue.png';
import { useAppSelector } from '../../../app/hooks';
import {
	selectMainGridStatus, selectGridTime, selectMainGridScrollID, selectMainGridChannelStatus, setMainGridScrollID
} from '../mainGridSlice';
import GridLoading from './GridLoading';
import { DataState } from '../../../interfaces/enums';
import DayMissing from './gridFailComponents/DayMissing';
import {
	selectCompactMode, selectDisplayChannelLogo, selectFixedCellHeight
} from '../../UserSettingsPages/SettingsState/gridSettingsSlice'
import NextGenSVG from '../../../img/nextgentv.svg';
import { CreateUUID, getChannelRowHeight } from '../../Global/GobalFunctions';

const ChannelRow = (props: any) => {
	const [display, setDisplay] = useState(true);
	const channel: Channel = props.Channel
	const schedule: ScheduleChannel = props.Schedule
	const index: number = props.index;
	const gridStatus = useAppSelector(selectMainGridStatus);
	const currentHour = parseInt(useAppSelector(selectGridTime));
	const displayChannelLogo = useAppSelector(selectDisplayChannelLogo);
	const fixedCellHeight = useAppSelector(selectFixedCellHeight);
	let linkActive = true;
	const channelHoverInfo = channel.description;
	const compactMode = useAppSelector(selectCompactMode);
	let height = '105px';
	height = getChannelRowHeight(compactMode);
	const scrollId = useAppSelector(selectMainGridScrollID);
	const ref: any = useRef(null);
	const channelStatus = useAppSelector(selectMainGridChannelStatus);

	if (gridStatus === DataState.SUCCEEDED) {
		if (schedule.days === undefined || schedule.days.length < 1) {
			linkActive = false;
		}
	}

	const scrollToEle = () => {
		if (scrollId < 1) {
			return
		} else if (scrollId === channel.channelId) {
			setMainGridScrollID(-1);
			ref.current.scrollIntoView({ behavior: 'instant', block: 'start' });
			window.scrollBy(0, -180);
		}
	}

	useEffect(() => {
		if (channelStatus === DataState.SUCCEEDED && gridStatus === DataState.SUCCEEDED) {
			scrollToEle()
		}
	}, []);

	{/* 
		0	Default NULL
		1	Analog Broadcast
		2	Digital Broadcast
		3	Analog Cable
		4	Digital Cable
		5	Digital Cable w/QAM
		6	Satellite
		7	Over the Top
	*/}

	const displayMajorMinor = (providerTypeId: number): boolean => {
		switch (providerTypeId) {
			case 1:
				return false;
			case 2:
				return true;
			case 3:
				return false;
			case 4:
				return false;
			case 5:
				return true;
			case 6:
				return false;
			case 7:
				return false;
			default:
				return false;
		}
	}

	return (
		<StyledChannelRow shouldDisplay={display} rowHeight={height} ref={ref} id={channel.channelId + ''} fixcellheight={fixedCellHeight}>
			<ChannelContainer title={`${channelHoverInfo}`} rowHeight={height} compact={compactMode} fixcellheight={fixedCellHeight} active={linkActive} to={linkActive ? `dailygrid/${channel.channelIndex}` : ''}>
				<ChannelInfo>
					<ChannelID compact={compactMode}>
						{(displayMajorMinor(channel.providerTypeId) && channel.majorChannel > 0) ? channel.majorChannel + '.' + channel.minorChannel : channel.rfChannel}
					</ChannelID>
					{compactMode ?
						displayChannelLogo ?
							<ChannelCardSM src={channel.logo} onError={(event) => event.currentTarget.style.display = 'none'} />
							: <ChannelCallsignSM compact={compactMode}>{channel.callSign}</ChannelCallsignSM>
						: ''}
					<ExpandIcon compact={compactMode} src={Expand} title={`Click to view this channel's daily view`} />
				</ChannelInfo>
				{!compactMode ?
					displayChannelLogo ? <ChannelCard src={channel.logo} onError={(event) => event.currentTarget.style.display = 'none'} />
						:
						<ChannelCallsign compact={compactMode}>{channel.callSign} <br />{channel.network}</ChannelCallsign>
					: ''}
				<div>
					{channel.isNextGen ? <NextGenLogo compact={compactMode} title={`This station is a NextGen TV channel`} src={NextGenSVG} /> : ''}
					{channel.isNextGen ? <NextGenText>Next Gen</NextGenText> : ''}
				</div>
			</ChannelContainer>
			<Events>
				{(gridStatus === DataState.LOADING) ?
					<GridLoading />
					:
					schedule.days.length > 0 ?
						schedule.days[0].events.map((event: Event, i: number) =>
							<EventContent Channel={channel} ChannelIndex={schedule.channelIndex + ''} Show={event} I={i} Current={currentHour} key={CreateUUID()} />
						)
						:
						<DayMissing channel={channel} key={'channelFail' + channel.channelId} />
				}
			</Events>
		</StyledChannelRow>
	)
}

export default ChannelRow