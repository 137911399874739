import styled from 'styled-components';
import { BlueButton } from '../../Global/GobalStyledComponents';
import { logout } from '../../Global/GobalFunctions';

const GridFailed = (props: any) => {
	const errorCode: number = props.errorCode;
	let errorMessage: string = props.errorMessage;
	let displayCode = true

	if (errorCode === undefined || errorCode === 0) {
		displayCode = false;
		errorMessage = 'An unknown error occurred'
	}

	return (
		<ErrorContainter>
			<div>Error: </div>
			{displayCode ? <ErrorCode>
				{errorCode}
			</ErrorCode> : ''}
			<ErrorMessage>
				{errorMessage}
				{errorCode === 1001 ?
					<div>
						<ReloadButton onClick={() => logout()}>Reload Site</ReloadButton>
					</div>
					: ''}
			</ErrorMessage>
		</ErrorContainter>
	)
}

const ErrorContainter = styled.div`
	margin: 25px;
`

const ErrorCode = styled.div`
	font-size: 20px;
`

const ErrorMessage = styled.div`
	font-size: 20px;
`
const ReloadButton = styled(BlueButton)`

`

export default GridFailed