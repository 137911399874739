import React, { useEffect, useState } from 'react'
import {
	ModalBlueButton, ModalHeader, ModalIconSM, ModalTextEntry, SmPulldown, WhiteClose, ModalContainer, ModalTitleBar, ModalContent,
	EditLineupLabel
} from '../ModalStyles'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setModalOpen } from '../modalSlice'
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import { DataUpdated } from '../../UserSettingsPages/components/SettingsStyles';
import { putNewLineup, fetchUserLineups, selectLineups } from '../../UserSettingsPages/SettingsState/lineupsSlice';
import { selectIsAnonymous, selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import { TooManyLineups } from '../../Global/GobalFunctions';

const ModalCustomLineup = () => {
	const [lineupName, setLineupName] = useState('')
	const [tzOffSet, setTZOffest] = useState('-300')
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const lineups = useAppSelector(selectLineups);
	const dispatch = useAppDispatch();
	const userId = useAppSelector(selectUserId);
	const isAnon = useAppSelector(selectIsAnonymous);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleAddLineup();
		}
	}

	const handleAddLineup = () => {
		if (lineupName === '') {
			setShowError(true);
			setErrorMessage('You must name the lineup')
			return;
		}

		if (TooManyLineups(lineups.lineups.length, isAnon)) {
			setShowError(true);
			setErrorMessage('You have too many lineups, delete one or more to add another')
			return;
		}

		setShowError(false);
		const utcOffset = parseInt(tzOffSet);
		const observesDST = true
		const providerId = 0
		const deviceTypeCode = ''
		dispatch(putNewLineup({ lineupName, utcOffset, observesDST, providerId, deviceTypeCode, userId }))
		dispatch(fetchUserLineups(userId));
		dispatch(setModalOpen());
	}

	useEffect(() => {
		setShowError(false);
		const d = new Date()
		const jan = new Date(d.getFullYear(), 0, 1);
		const offset = (jan.getTimezoneOffset() * -1).toString()
		setTZOffest(offset);

		return (() => {
			setLineupName('');
			setShowError(false);
		})
	}, [])

	const handleClose = () => {
		dispatch(setModalOpen());
		setLineupName('');
		setShowError(false);
	}

	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				<WhiteClose onClick={() => handleClose()}>
					<ModalIconSM src={Close} alt='Close' /> Close
				</WhiteClose>
			</ModalTitleBar>
			<ModalContent>
				<ModalHeader>Create Custom Linup</ModalHeader>
				<br />
				<div>This will create an empty lineup to which channels can be added.</div>
				<br />
				<EditLineupLabel htmlFor='lineupName'>Lineup Name</EditLineupLabel>
				<ModalTextEntry id='lineupName' value={lineupName} onChange={(e) => setLineupName(e.target.value)} onKeyDown={((e) => handleKeyDown(e))} autoFocus />
				{showError ? <DataUpdated>{errorMessage}</DataUpdated> : ''}
				<br />
				<EditLineupLabel htmlFor='timezone'>Timezone</EditLineupLabel>
				<SmPulldown id='timezone' onChange={(e) => setTZOffest(e.target.value)} value={tzOffSet}>
					<option value={-300}>
						Eastern
					</option>
					<option value={-360}>
						Central
					</option>
					<option value={-420}>
						Mountain
					</option>
					<option value={-480}>
						Pacific
					</option>
					<option value={-540}>
						Alaska
					</option>
					<option value={-600}>
						Hawaii
					</option>
				</SmPulldown>
				<ModalBlueButton onClick={() => handleAddLineup()}>Add Lineup</ModalBlueButton>
			</ModalContent>
		</ModalContainer>
	)
}

export default ModalCustomLineup