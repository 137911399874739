import React, { useEffect } from 'react'
import { ModalHeader, ModalIconSM, ModalButton } from '../ModalStyles'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setModalContent, setModalOpen } from '../modalSlice'
import {
	ModalContainer, ModalTitleBar, ModalContent, WhiteClose
} from '../ModalStyles'
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import Back from '../../../img/Icons/White Icons/TitanTV Icon_Back_White.png';
import { DataState, ModalState } from '../../../interfaces/enums'
import { GridLineupPulDown } from '../../GridPages/components/GridStyles'
import {
	fetchLineupChannelsToAdd, selectLineups, selectLineupsStatus, setLineupChannelsToAddStatus
} from '../../UserSettingsPages/SettingsState/lineupsSlice'
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice'
import AddChannelList from './AddChannelList'
import GridFailed from '../../GridPages/components/GridFailed'

const ModalAddChannelFromLineup = () => {
	const dispatch = useAppDispatch()
	const lineups = useAppSelector(selectLineups);
	const lineupsStatus = useAppSelector(selectLineupsStatus);
	const userId = useAppSelector(selectUserId);

	const handleLineupPulldownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		dispatch(fetchLineupChannelsToAdd(({ userId, lineupId: e.target.value })));
	}

	useEffect(() => {
		return (() => {
			dispatch(setLineupChannelsToAddStatus(DataState.IDLE));
		})
	}, [])

	return (
		<ModalContainer>
			<ModalTitleBar>
				<ModalButton onClick={() => dispatch(setModalContent(ModalState.CHANNELADDSELECT))}>
					<ModalIconSM src={Back} alt='Back' /> Back
				</ModalButton>
				<WhiteClose onClick={() => dispatch(setModalOpen())}>
					<ModalIconSM src={Close} alt='Close' /> Close
				</WhiteClose>
			</ModalTitleBar>
			<ModalContent>
				<ModalHeader>Add Channels to Lineup</ModalHeader>
				<GridLineupPulDown onChange={(e) => handleLineupPulldownChange(e)}>
					<option selected disabled value='0'> --- </option>
					{lineupsStatus === DataState.FAILED ? <GridFailed errorMessage={lineups.errorMessage} errorCode={lineups.errorCode} /> :
						lineupsStatus === DataState.SUCCEEDED ?
							lineups.lineups.map((l) => (
								<option key={l.lineupId} value={l.lineupId}>
									{l.lineupName}
								</option>
							)) : 'Loading'
					}
				</GridLineupPulDown>
				<AddChannelList />
			</ModalContent>
		</ModalContainer>
	)
}

export default ModalAddChannelFromLineup