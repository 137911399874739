import { setNewLineupSource, setModalContent, setModalOpen } from "../modalSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
	ModalContent, ModalHeader, ModalIconBar, ModalIconSM, ProgramSouceText, WhiteClose,
	ModalMainContent, ModalTitleBar, ModalIconContainer, ModalContainer, SourceIcon, ModalButton
} from '../ModalStyles'
import Broadcast from '../../../img/Icons/Blue Icons/TitanTV Icon_Broadcast_Blue.png';
import Cable from '../../../img/Icons/Blue Icons/TitanTV Icon_Cable_Blue.png';
import Digital from '../../../img/Icons/Blue Icons/TitanTV Icon_Digital_Blue.png';
import Satellite from '../../../img/Icons/Blue Icons/TitanTV Icon_Satellite_Blue.png';
import Streaming from '../../../img/Icons/Blue Icons/TitanTV Icon_Streaming_Blue.png';
import { DataState, ModalState } from "../../../interfaces/enums";
import { useEffect } from "react";
import { setLineupSelectionStatus } from "../../UserSettingsPages/SettingsState/lineupsSlice";
import CloseButtonNewUser from "./CloseButtonNewUser";
import { selectUserId } from "../../UserSettingsPages/SettingsState/userSlice";
import { GetDefaultUser } from "../../Global/GobalFunctions";
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';


const ModalProgramSource = () => {

	const dispatch = useAppDispatch();
	const userId = useAppSelector(selectUserId);
	//const isOpen: boolean = useAppSelector(selectOpen);
	const programSourceHandler = (source: string) => {
		dispatch(setNewLineupSource(source));
		dispatch(setModalContent(ModalState.ZIP));
	}

	useEffect(() => {
		dispatch(setLineupSelectionStatus(DataState.IDLE))
	}, [])

	/*
		B - broadcast
			Digital Cable
		[C - cable ready TV
		D - digital cable]

		O - live streaming
			Analog Cable
		[A - analog cable
		R - cable rebuild lineup]

		S - satellite
	*/

	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				{userId === GetDefaultUser() ? '' : <ModalButton onClick={() => dispatch(setModalOpen())}>
					<ModalIconSM src={Close} alt='Close' /> Close
				</ModalButton>}
			</ModalTitleBar>
			<ModalContent>
				<ModalHeader>Choose Program Source</ModalHeader>
				<ModalMainContent>
					TitanTV provides television listings for a variety of sources. Please choose your source by clicking its icon below.
				</ModalMainContent>
				<ModalIconBar>
					<ModalIconContainer>
						<SourceIcon src={Broadcast} alt='Broadcast' onClick={() => { programSourceHandler('B') }} />
						<ProgramSouceText>Broadcast</ProgramSouceText>
					</ModalIconContainer>
					<ModalIconContainer>
						<SourceIcon src={Cable} alt='Analog Cable' onClick={() => { programSourceHandler('A') }} />
						<ProgramSouceText>Basic<br />Cable</ProgramSouceText>
					</ModalIconContainer>
					<ModalIconContainer>
						<SourceIcon src={Digital} alt='Digital Cable' onClick={() => { programSourceHandler('D') }} />
						<ProgramSouceText>Digital<br />Cable</ProgramSouceText>
					</ModalIconContainer>
					<ModalIconContainer>
						<SourceIcon src={Satellite} alt='Satellite' onClick={() => { programSourceHandler('S') }} />
						<ProgramSouceText>Satellite</ProgramSouceText>
					</ModalIconContainer>
					<ModalIconContainer>
						<SourceIcon src={Streaming} alt='Streaming' onClick={() => { programSourceHandler('O') }} />
						<ProgramSouceText>Streaming</ProgramSouceText>
					</ModalIconContainer>
				</ModalIconBar>
			</ModalContent>
		</ModalContainer>
	)
}

export default ModalProgramSource