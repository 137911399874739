import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ModalState } from "../../interfaces/enums";

export interface modalState {
	modalOpen: boolean;
	modalContent: ModalState;
	newUserSignup: boolean;
	newLineupSource: string;
	newLineupZip: string;
	loginName: string;
	loginPassword: string;
}

const initialState: modalState = {
	modalOpen: false,
	modalContent: ModalState.WELCOME,
	newUserSignup: false,
	newLineupSource: '',
	newLineupZip: '',
	loginName: '',
	loginPassword: ''
};

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		setModalOpen: (state) => {
			state.modalOpen = !state.modalOpen
		},
		setModalContent: (state, action) => {
			state.modalContent = action.payload
		},
		setNewUserSignup: (state, action) => {
			state.newUserSignup = action.payload
		},
		setNewLineupSource: (state, action) => {
			state.newLineupSource = action.payload
		},
		setNewLineupZip: (state, action) => {
			state.newLineupZip = action.payload
		},
		setLoginName: (state, action) => {
			state.loginName = action.payload
		},
		setLoginPassword: (state, action) => {
			state.loginPassword = action.payload
		}
	}
});

export const {
	setModalOpen, setModalContent, setNewUserSignup, setNewLineupSource, setNewLineupZip, setLoginName, setLoginPassword
} = modalSlice.actions;

export const selectModalOpen = (state: RootState) => state.modal.modalOpen;
export const selectModalContent = (state: RootState) => state.modal.modalContent;
export const selectNewUserSignup = (state: RootState) => state.modal.newUserSignup;
export const selectNewLineupZip = (state: RootState) => state.modal.newLineupZip;
export const selectNewLineupSource = (state: RootState) => state.modal.newLineupSource;
export const selectLoginName = (state: RootState) => state.modal.loginName;
export const selectLoginPassword = (state: RootState) => state.modal.loginPassword;
export default modalSlice.reducer;