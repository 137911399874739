import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Schedule } from "../../interfaces/schedule";
import { Channel, LineupChannels } from "../../interfaces/channels";
import { DataState, SortType } from "../../interfaces/enums";
import { getBaseUrl, leadingZeroBuilder } from "../Global/GobalFunctions";
import { EventDetails } from "../../interfaces/eventDetails";
import { fetchLog, consoleLog, putLog, deleteLog, errorLog } from "../Global/ConsoleLogging";


export interface mainGridSliceState {
	mainGridSchedule: Schedule;
	channelSchedule: Schedule;
	lineupChannels: LineupChannels;
	eventDetails: EventDetails;
	mainGridStatus: DataState;
	lineupChannelStatus: DataState;
	channelScheduleStatus: DataState;
	castDataStatus: DataState;
	channelSort: SortType;
	gridDay: string;
	gridMonth: string;
	gridYear: string;
	gridDate: string;
	gridTime: string;
	gridLineupID: string;
	mainGridDuration: number;
	timeBarArray: Array<string>;
	channelIndex: string;
	eventID: string;
	mainGridScrollID: number;
}

const initialState: mainGridSliceState = {
	mainGridSchedule: {} as Schedule,
	lineupChannels: {} as LineupChannels,
	channelSchedule: {} as Schedule,
	eventDetails: {} as EventDetails,
	mainGridStatus: DataState.LOADING,
	lineupChannelStatus: DataState.LOADING,
	channelScheduleStatus: DataState.LOADING,
	castDataStatus: DataState.LOADING,
	channelSort: SortType.LINEUP,
	gridDay: '',
	gridMonth: '',
	gridYear: '',
	gridDate: '',
	gridTime: '',
	gridLineupID: '',
	mainGridDuration: 3,
	timeBarArray: [''],
	channelIndex: '',
	eventID: '184291194',
	mainGridScrollID: 0,
}

const updateGridDate = (state: any) => {
	state.gridDate = state.gridYear + '-' + state.gridMonth + '-' + state.gridDay;
	const startTime = state.gridYear + leadingZeroBuilder(parseInt(state.gridMonth)) + leadingZeroBuilder(parseInt(state.gridDay));
	fetchSchedule({ userId: sessionStorage.userId, lineupId: state.gridLineupID, startTimeToken: startTime, duration: state.mainGridDuration });
}

const updateGridDayMonthYear = (state: any) => {
	let arr = state.gridDate.split('-');
	state.gridYear = arr[0];
	state.gridMonth = arr[1];
	state.gridDay = arr[2];
	const startTime = arr[0] + leadingZeroBuilder(parseInt(arr[1])) + leadingZeroBuilder(parseInt(arr[2]))
	fetchSchedule({ userId: sessionStorage.userId, lineupId: state.gridLineupID, startTimeToken: startTime, duration: state.mainGridDuration });
}

const updateBuildTimeArray = (state: any) => {
	let intLength = state.mainGridDuration;
	let startTime = state.gridTime;
	let pastMidnight = false;

	let AMPM = 'AM';
	let timeArray: string[] = [];
	for (let i = 0; i < intLength * 2; i++) {
		let startTimeUsed = 0;
		if (startTime > 11) {
			startTimeUsed = startTime - 12;
			AMPM = 'PM';
			if (startTimeUsed === 0) { startTimeUsed = 12 }
		} else if (startTime === 0 || startTime === '00') {
			startTimeUsed = 12
			AMPM = 'AM'
		} else {
			startTimeUsed = startTime
		}

		if (startTime === 13 && AMPM === 'AM') {
			startTime = 1;
		}

		if (pastMidnight) { AMPM = 'AM' }

		if (startTime === 24) {
			AMPM = 'AM'
			startTime = 12;
			pastMidnight = true;
		}

		if (i % 2 === 0) {
			timeArray.push(`${parseInt(startTimeUsed.toString())}:00 ${AMPM}`);
		} else {
			timeArray.push(`${parseInt(startTimeUsed.toString())}:30 ${AMPM}`);
			startTime++
		}
	}
	state.timeBarArray = timeArray;
}

const baseUrl = getBaseUrl();

// http://dev-max-api.titantv.com/api/schedule/E40E0FB2-B413-4F42-B515-7E4F78C2E9E2/DD4A4236-6176-4085-9F94-134148D2B796/202207141400/180
// {userId}/{lineupId}/{startTimeToken}/{duration}
export const fetchSchedule = createAsyncThunk('grid/fetchSchedule', async (urlData: { userId: string, lineupId: string, startTimeToken: string, duration: string | number }) => {
	fetchLog('fetching schedule', true);
	const url = `${baseUrl}schedule/${urlData.userId}/${urlData.lineupId}/${urlData.startTimeToken}/${urlData.duration}`;
	return fetch(url)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Schedule Fetch Error: ${err}`, true));
});


// http://dev-max-api.titantv.com/api/channel/{userId}/{lineupId}
// channel/{userId}/{lineupId}
export const fetchLineupChannels = createAsyncThunk('grid/fetchLineupChannels', async (data: { userId: string, lineupId: string }) => {
	fetchLog('fetching channels', true)
	return fetch(`${baseUrl}channel/${data.userId}/${data.lineupId}`)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Channels Fetch Error: ${err}`, true));
});

// {userId}/{lineupId}/{startTimeStamp}/{duration}/7?channelIndex=436&channelCount=1
export const fetchChannelLineup = createAsyncThunk('grid/fetchChannelLineup', async (data: { userId: string, lineupId: string, timeStamp: string, duration: string, channelIndex: string }) => {
	return fetch(`${baseUrl}schedule/${data.userId}/${data.lineupId}/${data.timeStamp}/${data.duration}/7?channelIndex=${data.channelIndex}&channelCount=1`)
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Channel Lineup Fetch Error : ${err}`, true))
});

/*
	sort the schedule array based on the channel array
	from - https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
	itemsArray.sort(function(a, b){  
		return sortingArr.indexOf(a) - sortingArr.indexOf(b);
	});
	*/
const getChannelNumber = (channel: Channel): number => {
	let channelNumber = 0;
	if (channel.majorChannel > 0) {
		let channelString = `${channel.majorChannel}.${channel.minorChannel}`
		channelNumber = parseFloat(channelString);
	} else {
		channelNumber = channel.rfChannel
	}
	return channelNumber;
}

const sortSchedule = (state: any) => {
	switch (state.channelSort) {
		case SortType.CALLSIGN:
			state.lineupChannels.channels.sort((a: Channel, b: Channel) => {
				return (a.callSign.localeCompare(b.callSign)) * -1
			})
			break;
		case SortType.CALLSIGN_DESC:
			state.lineupChannels.channels.sort((a: Channel, b: Channel) => {
				return a.callSign.localeCompare(b.callSign)
			})
			break;
		case SortType.CHANNEL_NUMBER:
			state.lineupChannels.channels.sort((a: Channel, b: Channel) => {
				return getChannelNumber(a) - getChannelNumber(b);
			})
			break;
		case SortType.CHANNEL_NUMBER_DESC:
			state.lineupChannels.channels.sort((a: Channel, b: Channel) => {
				return getChannelNumber(b) - getChannelNumber(a);
			})
			break;
		case SortType.LINEUP:
		default:
			state.lineupChannels.channels.sort((a: Channel, b: Channel) => {
				return a.sortOrder - b.sortOrder;
			})
			break;
	}
}

export const mainGridSlice = createSlice({
	name: 'mainGrid',
	initialState,
	reducers: {
		setGridDay: (state, action) => {
			state.gridDay = action.payload;
			updateGridDate(state);
		},
		setGridMonth: (state, action) => {
			state.gridMonth = action.payload;
			updateGridDate(state);
		},
		setGridYear: (state, action) => {
			state.gridYear = action.payload;
			updateGridDate(state);
		},
		setGridDate: (state, action) => {
			state.gridDate = action.payload;
			updateGridDayMonthYear(state);
		},
		setGridTime: (state, action) => {
			state.gridTime = leadingZeroBuilder(parseInt(action.payload));
			updateBuildTimeArray(state);
		},
		setGridLineupID: (state, action) => {
			state.gridLineupID = action.payload;
		},

		setChannelSort: (state, action) => {
			state.channelSort = action.payload
			if (state.lineupChannelStatus === DataState.SUCCEEDED) {
				sortSchedule(state);
			}
		},
		setTimeBarArray: (state) => {
			updateBuildTimeArray(state);
		},
		setMainGridDuration: (state, action) => {
			state.mainGridDuration = action.payload
			updateBuildTimeArray(state);
		},
		setEventDetails: (state, action) => {
			state.eventDetails = action.payload
		},
		setChannelIndex: (state, action) => {
			state.channelIndex = action.payload
		},
		setEventID: (state, action) => {
			state.eventID = action.payload
		},
		sortMainGrid: (state, action) => {
			sortSchedule(state.channelSort);
		},
		setMainGridScrollID: (state, action) => {
			state.mainGridScrollID = action.payload;
		},
		setMainGridStatus: (state, action) => {
			state.mainGridStatus = action.payload;
		}
	},

	extraReducers: builder => {
		//fetchSchedule
		builder.addCase(fetchSchedule.pending, (state) => {
			state.mainGridStatus = DataState.LOADING;
		})
		builder.addCase(fetchSchedule.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.mainGridStatus = DataState.FAILED
			} else {
				state.mainGridSchedule = action.payload;
				state.mainGridStatus = DataState.SUCCEEDED;
			}
		})
		builder.addCase(fetchSchedule.rejected, (state) => {
			state.mainGridStatus = DataState.FAILED
		})

		// fetchLineupChannels
		builder.addCase(fetchLineupChannels.pending, (state) => {
			state.lineupChannelStatus = DataState.LOADING;
		})
		builder.addCase(fetchLineupChannels.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.lineupChannelStatus = DataState.FAILED
			} else {
				state.lineupChannels = action.payload;
				sortSchedule(state);
				state.lineupChannelStatus = DataState.SUCCEEDED;
			}
		})
		builder.addCase(fetchLineupChannels.rejected, (state) => {
			state.lineupChannelStatus = DataState.FAILED
		})

		// fetchChannelLineup
		builder.addCase(fetchChannelLineup.pending, (state) => {
			state.channelScheduleStatus = DataState.LOADING;
		})
		builder.addCase(fetchChannelLineup.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.channelScheduleStatus = DataState.FAILED
			} else {
				state.channelSchedule = action.payload;
				state.channelScheduleStatus = DataState.SUCCEEDED;
			}
		})
		builder.addCase(fetchChannelLineup.rejected, (state) => {
			state.channelScheduleStatus = DataState.FAILED
		})
	}
});

export const {
	setGridDay, setGridMonth, setGridYear, setGridDate, setGridTime, setGridLineupID,
	setTimeBarArray, setMainGridDuration, setEventDetails, setChannelIndex, setEventID,
	sortMainGrid, setChannelSort, setMainGridScrollID, setMainGridStatus
} = mainGridSlice.actions;

export const selectMainSchedule = (state: RootState) => state.mainGrid.mainGridSchedule;
export const selectChannelSchedule = (state: RootState) => state.mainGrid.channelSchedule;
export const selectMainGridStatus = (state: RootState) => state.mainGrid.mainGridStatus;
export const selectLineupChannels = (state: RootState) => state.mainGrid.lineupChannels;
export const selectLineupChannelsStatus = (state: RootState) => state.mainGrid.channelScheduleStatus;
export const selectMainGridChannelStatus = (state: RootState) => state.mainGrid.lineupChannelStatus;
export const selectChannelSort = (state: RootState) => state.mainGrid.channelSort;
export const selectGridDay = (state: RootState) => state.mainGrid.gridDay;
export const selectGridMonth = (state: RootState) => state.mainGrid.gridMonth;
export const selectGridYear = (state: RootState) => state.mainGrid.gridYear;
export const selectGridDate = (state: RootState) => state.mainGrid.gridDate;
export const selectGridTime = (state: RootState) => state.mainGrid.gridTime;
export const selectGridLineupID = (state: RootState) => state.mainGrid.gridLineupID;
export const selectTimeBarArray = (state: RootState) => state.mainGrid.timeBarArray;
export const selectMainGridDuration = (state: RootState) => state.mainGrid.mainGridDuration;
export const selectEventDetails = (state: RootState) => state.mainGrid.eventDetails;
export const selectChanneIndex = (state: RootState) => state.mainGrid.channelIndex;
export const selectEventID = (state: RootState) => state.mainGrid.eventID;
export const selectCastDataStatus = (state: RootState) => state.mainGrid.castDataStatus;
export const selectMainGridScrollID = (state: RootState) => state.mainGrid.mainGridScrollID;

export default mainGridSlice.reducer;