import styled from 'styled-components'
import { BlueButton, WhiteButton } from '../Global/GobalStyledComponents';
import { MenuDarkoutPage } from '../Global/GobalStyledComponents';

export const ModalDarkoutPage = styled(MenuDarkoutPage) <{ open: boolean }>`
	display: ${({ open }) => open ? 'flex' : 'none'};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 15;
	height: 100%;
	width: 100%;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		align-items: center;
	}
`

export const ModalContainer = styled.div`
	max-width: 500px;
	height: fit-content;
	max-height: 90vh;
	z-index: 20;
	margin-bottom: 15px;
	width: 500px;
	user-select: none;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		width: 90%;
	}
`

export const WelcomeModalContainer = styled(ModalContainer)`
	height: 320px;
	font-size: 24px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 90%;
	}
`

export const ModalTitleBar = styled.div`
	border-radius: 5px 5px 0 0;
	width: 100%;
	background: ${({ theme }) => theme.medBlue};
	height: fit-content;
	color: white;
	display: flex;
	flex-direction: row;
	padding: 10px;
	justify-content: space-between;
	align-items: center;
	min-height: 35px;
`

export const ModalButton = styled.button`
	background: ${({ theme }) => theme.medBlue};
	color: white;
	box-shadow: none;
	border: none;
	cursor: pointer;
`

export const RedClose = styled(ModalButton)`
	display: flex;
	flex-direction: row;
	color: ${({ theme }) => theme.redClose};
`

export const WhiteClose = styled(ModalButton)`
	display: flex;
	flex-direction: row;
	color: ${({ theme }) => theme.whiteClose};
`

export const ModalContent = styled.div`
	border-radius: 0 0 5px 5px;
	background: white;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	padding-bottom: 15px;
	min-height: 200px;
`

export const ImageContainer = styled.img`
	height: 45px;
	width: 160px;
`

export const ModalIconSM = styled.img`
	height: 10px;
	width: 10px;
	cursor: pointer;
	margin: 0 3px;
`

export const MarqueeIcon = styled(ModalIconSM)`
	cursor: default;
`

export const ModalIconMed = styled.img`
	height: 20px;
	width: 20px; 
`

export const ModalMainContent = styled.div`
	text-align: center;
	padding: 15px 50px;
`

export const ForgotPassword = styled.div`
	text-decoration: underline;
	cursor: pointer;
	padding-bottom: 5px;
`

export const ModalButtons = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

export const ModalBlueButton = styled(BlueButton)`
	padding: 10px 0;
	width: 125px;
	margin-right: 15px;
	margin-bottom: 15px;
`

export const AddChannelSelectButton = styled(ModalBlueButton)`
	width: 275px;
`

export const ModalWhiteButton = styled(WhiteButton)`
	padding: 10px 0;
	width: 125px;
	border: 2px solid ${({ theme }) => theme.medBlue};
	margin-bottom: 15px;
`

export const ViewGuestButton = styled(ModalBlueButton)`
	margin-right: 15px;
	border: 1px solid ${({ theme }) => theme.medBlue};

	&:hover {
		background: white;
		color: ${({ theme }) => theme.medBlue};
		border-radius: 5px;
		border: 1px solid ${({ theme }) => theme.medBlue};
	}
`

export const LoginInButton = styled(ModalBlueButton)`
	border: 1px solid ${({ theme }) => theme.medBlue};

&:hover {
		background: white;
		color: ${({ theme }) => theme.medBlue};
		border-radius: 5px;
		border: 1px solid ${({ theme }) => theme.medBlue};
	}
`

export const ModalHeader = styled.div`
	padding-top: 10px;
	font-size: 18px;
	font-weight: 900;
	color: ${({ theme }) => theme.darkBlue};
`

export const ModalHeaderSM = styled(ModalHeader)`
	font-size: 13px;
`

export const ModalIconBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	padding: 30px 10px 0px 10px;
`

export const ModalIconContainer = styled.div`
	display: flex;
	flex-direction: column;
	//justify-content: space-around;
	align-items: center;
	width: 85%;
	min-height: 128px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		font-size: 12px;
	}
`

export const ModalIconLarge = styled.img`
	padding: 15px;
	width: 70px;
	height: 70px;
	background: ${({ theme }) => theme.lightGray};
	cursor: pointer;
	border-radius: 5px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		width: 55px;
		height: 55px;
	}
`

export const SourceIcon = styled(ModalIconLarge)`
	margin-bottom: 10px;
`

export const ModalTextEntry = styled.input`
	min-width: 300px;
	width: 100%;
	padding: 10px;
	border: 1px solid lightgray;
	border-radius: 5px;
	margin: 7px 0;
`

export const ModalNumEntry = styled(ModalTextEntry)`
	-moz-appearance:textfield;
	::-webkit-inner-spin-button{
				-webkit-appearance: none; 
				margin: 0; 
	}
	::-webkit-outer-spin-button{
				-webkit-appearance: none; 
				margin: 0; 
	}
`

export const RememberForget = styled.div`
	width: 85%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	margin-top: 15px;

	a {
		color: grey;
		font-size: 12px;
	}
`

export const ModalPulldown = styled.select`
	margin-top: 15px;
	width: 85%;
	padding: 10px;
	border-radius: 5px;
	background: white;
	border: solid 1px lightgray;
`

export const GridFilterGenreOption = styled.option <{ color: string }>`
	background: ${p => (p.color)};
	font-size: 16px;
`

export const SmPulldown = styled(ModalPulldown)`
	width: fit-content;
`

export const CheckBoxContainer = styled.div`
	width: 85%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	margin-top: 25px;
	cursor: pointer;

	span {
		width: 50%;

		label {
			padding-left: 10px;
		}
	}

	@media (max-width: ${({ theme }) => theme.mobile}) {
		span {
			label {
				padding-left: 0;
			}
		}
	}
`

export const RemberMeContainer = styled(CheckBoxContainer)`
	justify-content: center;
	margin-top: 0;

	label {
		margin-top: 5px;
	}
`

export const ProgramSouceText = styled.span`
	display: flex;
	justify-content: center;
	text-align: center;
`
export const LineupsContainer = styled.div`
	margin: 15px 10px;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 75vh;
	overflow-y: auto;
`

export const LineupCard = styled.div`
	border: solid 1px lightgray;
	border-radius: 5px;
	padding: 7px;
	margin: 3px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: ${({ theme }) => theme.lightGray};
	cursor: pointer;
	user-select: none;

	&:hover {
		background: white;
	}

	&:active {
		opacity: 0.75;
		transform: scale(0.98);
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
	}
`

export const LineupName = styled.div`

`

export const LineupButton = styled(ModalIconMed)`

`

export const EditLineupContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
	height: fit-content;
	flex-wrap: wrap;
`

export const EditChannelContainter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
	height: fit-content;
	flex-wrap: wrap;
`

export const EditLineupInuptContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
`

export const EditChannelInuptContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

export const EditLineupLabel = styled.label`
	color: ${({ theme }) => theme.darkBlue};
	margin-right: 5px;
`

export const EditLineupText = styled(ModalTextEntry)`
	min-width: auto;
	width: 157px;
`

export const EditLineupNum = styled(ModalNumEntry)`
	min-width: auto;
	width: 100px;
`

export const MajorMinorInupt = styled(ModalNumEntry)`
	min-width: auto;
	max-width: 50px;
	margin-right: 5px;
`

export const EditLineupPullDown = styled(ModalPulldown)`
	margin-top: 6px;
	width: fit-content;
`

export const DTSEditLabel = styled(EditLineupLabel)`
	display: flex;
	flex-direction: column;
`