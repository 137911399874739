import styled from 'styled-components'
import { useAppSelector } from '../../../app/hooks'
import { selectColors } from '../gridColorsSlice'
import { ColorType } from '../../../interfaces/enums'
import { selectGridColorScheme } from '../../UserSettingsPages/SettingsState/gridSettingsSlice'
import { useEffect, useState } from 'react'

const GridLegand = () => {
	const [colorSchemeText, setColorSchemeText] = useState('Titan TV Standard')

	const { GridColors } = useAppSelector(selectColors);
	const SelectedColorScheme = useAppSelector(selectGridColorScheme);

	useEffect(() => {
		switch (SelectedColorScheme) {
			case ColorType.COMEDYDRAMA:
				setColorSchemeText('COMEDY/DRAMA')
				break;
			case ColorType.FAMILY:
				setColorSchemeText('Family')
				break;
			case ColorType.HARDCOPY:
				setColorSchemeText('Hard Copy')
				break;
			case ColorType.NEWSHOUND:
				setColorSchemeText('News Hound')
				break;
			case ColorType.SPORTSFAN:
				setColorSchemeText('Sports Fan')
				break;
			case ColorType.TALK:
				setColorSchemeText('Talk')
				break;
			case ColorType.TITANTVSTANDARD:
			default:
				setColorSchemeText('Titan TV Standard')
				break;
		}
	}, [])

	return (
		<GridLegandContianer>
			{/* <HorizontalAd></HorizontalAd> */}
			<StyledGridLegand>
				<GridLegandTitle>Genre Color Scheme: {colorSchemeText}</GridLegandTitle>
				<LegandContainer>
					{/* <GirdColorSelect /> */}
					<GridKeyContainer>
						<GridKey color={GridColors.Animated}>Animated</GridKey>
						<GridKey color={GridColors.Children}>Children</GridKey>
						<GridKey color={GridColors.Comedy}>Comedy</GridKey>
						<GridKey color={GridColors.Documentary}>Documentary</GridKey>
						<GridKey color={GridColors.Drama}>Drama</GridKey>
						<GridKey color={GridColors.Education}>Education</GridKey>
						<GridKey color={GridColors.Entertainment}>Entertainment</GridKey>
						<GridKey color={GridColors.Food}>Food</GridKey>
						<GridKey color={GridColors.Game_Quiz}>Game/Quiz</GridKey>
						<GridKey color={GridColors.Health}>Health</GridKey>
						<GridKey color={GridColors.Home}>Home</GridKey>
						<GridKey color={GridColors.Information}>Information</GridKey>
						<GridKey color={GridColors.Interview}>Interview</GridKey>
						<GridKey color={GridColors.Music}>Music</GridKey>
						<GridKey color={GridColors.Nature}>Nature</GridKey>
						<GridKey color={GridColors.News}>News</GridKey>
						<GridKey color={GridColors.Other}>Other</GridKey>
						<GridKey color={GridColors.Religious}>Religious</GridKey>
						<GridKey color={GridColors.Science}>Science</GridKey>
						<GridKey color={GridColors.SciFi}>Sci-Fi</GridKey>
						<GridKey color={GridColors.Sports}>Sports</GridKey>
						<GridKey color={GridColors.Travel}>Travel</GridKey>
						<GridKey color={GridColors.True}>True</GridKey>
					</GridKeyContainer>
				</LegandContainer>
			</StyledGridLegand>
		</GridLegandContianer>
	)
}

const GridLegandContianer = styled.div`
	background: ${({ theme }) => theme.lightGray};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0 15px;
	user-select: none;

	@media print {
		display: none;
	}
`

const GridLegandTitle = styled.h4`
	text-align: center;
	font-size: 18px;
`

const StyledGridLegand = styled.div`
	padding: 0px;
	padding: 2px 15px 15px 15px;
	max-width: 1250px;
	/* border-left: ${({ theme }) => theme.gridBorders};
	border-right: ${({ theme }) => theme.gridBorders};
	border-bottom: ${({ theme }) => theme.gridBorders}; */

	h4 {
		color: ${({ theme }) => theme.darkBlue};
	}

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		text-align: center;
	}
`

const LegandContainer = styled.div`
	display: flex;
	flex-direction: row;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		flex-direction: column;
	}
`

const GridKeyContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@media (max-width: ${({ theme }) => theme.medScreen}) {
		padding-top: 15px;
		align-items: center;
		justify-content: center;
		height: auto;
		flex-direction: row;
	}
`

const GridKey = styled.div`
	margin: 0 17px 15px 17px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 110px;
	height: 30px;
	border-radius: 3px;
	background: ${props => props.color};
	font-size: 14px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		margin: 0 10px 10px 10px;
	}
`

const LegandPushOver = styled.div`
	min-width: 150px;
`

export default GridLegand