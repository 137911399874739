import React, { useEffect } from 'react';
import Marquee from './Marquee';
import Print from '../../../img/Icons/White Icons/TitanTV Icon_Print_White.png'
import {
	GridPullDown, GridSelectorImg, GridSelectorsContainer, GridDayTime, GridLineup,
	GridPullDownDay, GridLineupPulDown, GridSelectorImgContainer, Printer,
	AddLineupIcon
} from './GridStyles';
import { DateInfo } from '../../Global/GobalTypes';
import Clock from '../../../img/Icons/White Icons/TitanTV Icon_Clock_White.png'
import Filter from '../../../img/Icons/White Icons/TitanTV Icon_Filter_White.png'
import Gear from '../../../img/Icons/White Icons/TitanTV Icon_Custom_White.png'
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { leadingZeroBuilder } from '../../Global/GobalFunctions';
import {
	fetchSchedule, setGridDate, setGridTime, setGridLineupID, selectGridDate, selectGridTime, selectGridLineupID,
	selectMainGridDuration, selectGridYear, selectGridMonth, selectGridDay, fetchLineupChannels, setTimeBarArray
} from '../mainGridSlice';
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import { selectStartTime } from '../../UserSettingsPages/SettingsState/gridSettingsSlice';
import {
	fetchFavorites, fetchUpcomingReminders, fetchUpcomingFavorites, selectFavoritesStatus
} from '../../UserSettingsPages/SettingsState/favoritesRemidersSlice';
import {
	fetchUserLineups, selectLineups, selectLineupsStatus
} from '../../UserSettingsPages/SettingsState/lineupsSlice';
import { DataState } from '../../../interfaces/enums';
import { setModalContent, setModalOpen } from '../../Modal/modalSlice';
import { ModalState } from '../../../interfaces/enums';
import { setRunPrimisPlayer } from '../../Header/headerSlice';


let dates: DateInfo[] = new Array<DateInfo>();

let dayNamesAb = [
	'Sun',
	'Mon',
	'Tue',
	'Wed',
	'Thur',
	'Fri',
	'Sat'
]

let date = new Date();

let gridHour: string;
let gridYear: string;
let gridMonth: string;
let gridDay: string;
let startTime: string;

const buildDateArray = () => {
	dates = [];
	for (let i = -1; i < 15; i++) {
		let current = new Date(date.getTime());
		current.setDate(date.getDate() + i)
		let d = {
			year: current.getFullYear(),
			month: current.getMonth() + 1,
			day: current.getDay(),
			dayName: dayNamesAb[current.getDay()],
			dayNameAb: dayNamesAb[current.getDay()],
			date: current.getDate()
		}
		dates.push(d);
	}
}

let userId: string;
let lineupId: string;
let mainGridDuration: number;

const handleCurrent = (dispatch: any, userId: string) => {
	date = new Date();
	buildDateArray();

	dispatch(setGridDate(date.getFullYear() + '-' + leadingZeroBuilder(date.getMonth() + 1) + '-' + leadingZeroBuilder(date.getDate())))
	dispatch(setGridTime(date.getHours().toString()));
	const dateTime = date.getFullYear() + leadingZeroBuilder(date.getMonth() + 1) + leadingZeroBuilder(date.getDate()) + leadingZeroBuilder(date.getHours()) + '00';
	dispatch(setTimeBarArray())
	dispatch(fetchSchedule({ userId: userId, lineupId: lineupId, startTimeToken: dateTime, duration: mainGridDuration * 60 }));
	dispatch(fetchUpcomingReminders({ userId, lineupId }));
	dispatch(fetchUpcomingFavorites({ userId, lineupId, startTime: dateTime, duration: 1440 }));
	dispatch(setRunPrimisPlayer(true));
}

const handleDayPullDownChange = (e: React.ChangeEvent<HTMLSelectElement>, dispatch: any, userId: string) => {
	dispatch(setGridDate(e.target.value));
	let arr = e.target.value.split('-');
	const dateTime = arr[0] + leadingZeroBuilder(parseInt(arr[1])) + leadingZeroBuilder(parseInt(arr[2])) + leadingZeroBuilder(parseInt(gridHour)) + '00';
	dispatch(fetchSchedule({ userId: userId, lineupId: lineupId, startTimeToken: dateTime, duration: mainGridDuration * 60 }));
	dispatch(setRunPrimisPlayer(true));
}

const handleTimePullDownChange = (e: React.ChangeEvent<HTMLSelectElement>, dispatch: any, userId: string) => {
	dispatch(setGridTime(e.target.value));
	const dateTime = gridYear + leadingZeroBuilder(parseInt(gridMonth)) + leadingZeroBuilder(parseInt(gridDay)) + leadingZeroBuilder(parseInt(e.target.value)) + '00';
	dispatch(fetchSchedule({ userId: userId, lineupId: lineupId, startTimeToken: dateTime, duration: mainGridDuration * 60 }));
	dispatch(setRunPrimisPlayer(true));
}

const loadFavorites = (dispatch: any, favoritesStatus: DataState) => {
	let lFNow = new Date();
	lFNow.setMinutes(0);
	lFNow.setHours(lFNow.getHours() + 1);
	let startTimeToken = lFNow.getFullYear() + leadingZeroBuilder(lFNow.getMonth() + 1) + leadingZeroBuilder(lFNow.getDate()) + leadingZeroBuilder(lFNow.getHours()) + '00'
	if (favoritesStatus !== DataState.SUCCEEDED) {
		dispatch(fetchFavorites(userId));
	}
	dispatch(fetchUpcomingFavorites({ userId: userId, lineupId: lineupId, startTime: startTimeToken, duration: 1440 }));
}

const handleLineupPulldownChange = (e: React.ChangeEvent<HTMLSelectElement>, dispatch: any, userId: string) => {
	dispatch(setGridLineupID(e.target.value));
	dispatch(fetchLineupChannels({ userId: userId, lineupId: e.target.value }));
	const dateTime = gridYear + leadingZeroBuilder(parseInt(gridMonth)) + leadingZeroBuilder(parseInt(gridDay)) + leadingZeroBuilder(parseInt(gridHour)) + '00';
	dispatch(fetchSchedule({ userId: userId, lineupId: e.target.value, startTimeToken: dateTime, duration: mainGridDuration * 60 }))
	let lFNow = new Date();
	lFNow.setMinutes(0);
	lFNow.setHours(lFNow.getHours() + 1);
	let startTimeToken = lFNow.getFullYear() + leadingZeroBuilder(lFNow.getMonth() + 1) + leadingZeroBuilder(lFNow.getDate()) + leadingZeroBuilder(lFNow.getHours()) + '00'
	dispatch(fetchUpcomingFavorites({ userId: userId, lineupId: e.target.value, startTime: startTimeToken, duration: 1440 }));
	dispatch(setRunPrimisPlayer(true));
}

// Render Function
const MainGridSelector = () => {
	userId = useAppSelector(selectUserId);
	lineupId = useAppSelector(selectGridLineupID);
	const dispatch = useAppDispatch();
	gridHour = useAppSelector(selectGridTime);
	gridYear = useAppSelector(selectGridYear);
	gridMonth = useAppSelector(selectGridMonth);
	gridDay = useAppSelector(selectGridDay);
	mainGridDuration = useAppSelector(selectMainGridDuration);
	startTime = useAppSelector(selectStartTime);
	const lineupStatus = useAppSelector(selectLineupsStatus);
	const lineups = useAppSelector(selectLineups);
	const favoritesStatus = useAppSelector(selectFavoritesStatus)

	buildDateArray();

	useEffect(() => {
		if (lineupStatus !== DataState.SUCCEEDED) {
			dispatch(fetchUserLineups(userId));
		}
		if (startTime !== '-1') {
			date.setHours(parseInt(startTime));
		}
		dispatch(setGridDate(date.getFullYear() + '-' + leadingZeroBuilder((date.getMonth() + 1)) + '-' + leadingZeroBuilder(date.getDate())))
		if (gridHour === '') {
			dispatch(setGridTime(date.getHours().toString()));
		}
		loadFavorites(dispatch, favoritesStatus);
	}, [])

	const handleFilerClick = () => {
		dispatch(setModalContent(ModalState.FILTER));
		dispatch(setModalOpen())
	}

	// #region runOnTheHour
	// https://stackoverflow.com/questions/12309019/javascript-how-to-do-something-every-full-hour
	// const runOnTheHour = (callbackFn: Function) => {
	// 	const Hour = 60 * 60 * 1000;
	// 	const currentDate = new Date();
	// 	const firstCall = Hour - (currentDate.getMinutes() * 60 + currentDate.getSeconds()) * 1000 - currentDate.getMilliseconds();
	// 	setTimeout(() => {
	// 		callbackFn();
	// 		setInterval(callbackFn, Hour);
	// 	}, firstCall);
	// };

	// runOnTheHour(() => {
	// 	handleCurrent(dispatch, userId)
	// });
	//#endregion

	const setTimeDisabled = (time: number): boolean => {
		const currentGridDate = new Date();
		currentGridDate.setFullYear(parseInt(gridYear))
		currentGridDate.setMonth(parseInt(gridMonth) - 1);
		currentGridDate.setHours(time);
		currentGridDate.setDate(parseInt(gridDay));
		currentGridDate.setMinutes(0, 0, 0);


		const toFar = new Date();
		toFar.setHours(0 - mainGridDuration + 1);
		toFar.setMinutes(0, 0, 0);
		toFar.setDate(toFar.getDate() + 15)

		return currentGridDate >= toFar;
	}

	const addNewLineup = () => {
		dispatch(setModalContent(ModalState.SOURCE));
		dispatch(setModalOpen())
	}

	return (
		<>
			<GridSelectorsContainer>
				<GridDayTime>
					<GridPullDownDay id='gridDate' value={useAppSelector(selectGridDate)} onChange={(e) => handleDayPullDownChange(e, dispatch, userId)}>
						{dates.map((d) => (
							<option key={d.month + '/' + d.date} value={d.year + '-' + leadingZeroBuilder(d.month) + '-' + leadingZeroBuilder(d.date)}>
								{d.dayName + ' - ' + d.month + '/' + d.date}
							</option>
						))}
					</GridPullDownDay>

					<GridPullDown id='gridTime' value={gridHour} onChange={(e) => handleTimePullDownChange(e, dispatch, userId)}>
						<option value='00'>
							12 AM
						</option>
						<option value='01'>
							1 AM
						</option>
						<option value='02'>
							2 AM
						</option>
						<option value='03'>
							3 AM
						</option>
						<option value='04'>
							4 AM
						</option>
						<option value='05'>
							5 AM
						</option>
						<option value='06'>
							6 AM
						</option>
						<option value='07'>
							7 AM
						</option>
						<option value='08'>
							8 AM
						</option>
						<option value='09'>
							9 AM
						</option>
						<option value='10'>
							10 AM
						</option>
						<option value='11'>
							11 AM
						</option>
						<option value='12'>
							12 PM
						</option>
						<option value='13'>
							1 PM
						</option>
						<option value='14'>
							2 PM
						</option>
						<option value='15'>
							3 PM
						</option>
						<option value='16'>
							4 PM
						</option>
						<option value='17'>
							5 PM
						</option>
						<option value='18' disabled={setTimeDisabled(18)} title={!setTimeDisabled(18) ? '' : 'Cannot view times past midnight 14 days in the future'}>
							6 PM
						</option>
						<option value='19' disabled={setTimeDisabled(19)} title={!setTimeDisabled(19) ? '' : 'Cannot view times past midnight 14 days in the future'}>
							7 PM
						</option>
						<option value='20' disabled={setTimeDisabled(20)} title={!setTimeDisabled(20) ? '' : 'Cannot view times past midnight 14 days in the future'}>
							8 PM
						</option>
						<option value='21' disabled={setTimeDisabled(21)} title={!setTimeDisabled(21) ? '' : 'Cannot view times past midnight 14 days in the future'}>
							9 PM
						</option>
						<option value='22' disabled={setTimeDisabled(22)} title={!setTimeDisabled(22) ? '' : 'Cannot view times past midnight 14 days in the future'}>
							10 PM
						</option>
						<option value='23' disabled={setTimeDisabled(23)} title={!setTimeDisabled(23) ? '' : 'Cannot view times past midnight 14 days in the future'}>
							11 PM
						</option>
					</GridPullDown>
					<GridSelectorImg title='Click to view grid for current day and time' src={Clock} onClick={() => { handleCurrent(dispatch, userId) }} />
				</GridDayTime>
				<GridLineup>
					<GridLineupPulDown value={useAppSelector(selectGridLineupID)} onChange={(e) => handleLineupPulldownChange(e, dispatch, userId)}>
						{lineupStatus === DataState.SUCCEEDED ?
							lineups.lineups.map((l, i) => (
								<option key={l.lineupId} value={l.lineupId}>
									{(i + 1) + ' - ' + l.lineupName}
								</option>
							)) : ''
						}
					</GridLineupPulDown>
					{/* This button was added because some users could figure out how to add lineups without it */}
					<AddLineupIcon title='Click to Add a New Lineup' src={Close} onClick={() => addNewLineup()} />
					<GridSelectorImgContainer to='/cellsettings'><GridSelectorImg title='Click to change the Grid and Cell display settings, maintain Favorites and Reminders, and more' src={Gear} /></GridSelectorImgContainer>
					<GridSelectorImg src={Filter} title='Click to temporarily display selected program genre/attributes' onClick={() => handleFilerClick()} />
				</GridLineup>
				<Marquee />
				<Printer title='Print Currently Displayed schedule' src={Print} onClick={() => window.print()} />
			</GridSelectorsContainer>
		</>
	)
}

export default MainGridSelector