import { useState, useEffect } from 'react';
import { FreestarAd } from '../Freestar/FreestarAd';
import styled from 'styled-components'

function VerticalAd() {
	const adTop = 130;
	const debug = false;

	return (
		<VertAd id='vertad'>
			<AdDiv top={adTop} debug={debug}>
				<FreestarAd placementName={'titantv_right_rail'}></FreestarAd>
			</AdDiv>
		</VertAd>
	)
}

interface AdProps {
	top: number;
	debug: boolean;
}

const AdDiv = styled.div<AdProps>`
 position: sticky; 
 top: ${props => props.top};
 width: 160px;
 height: 600px;
 margin: 5px;
 background: ${props => props.debug ? 'red' : 'transparent'};

 @media print {
   display: none
 }

 @media (max-width: 645px) {
   display: none;
 }
`;

const VertAd = styled.div`
  @media (max-width: 645px) {
   display: none;
 }
  @media print {
   display: none
 }
`
export default VerticalAd