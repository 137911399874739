import { Link } from "react-router-dom"
import styled from "styled-components"
import { TextInputWithButton } from "../Global/GobalStyledComponents"

export const StickyHeader = styled.div`
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9;
	background: ${({ theme }) => theme.lightGray};
	min-height: 110px;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		padding-top: 10px;
		padding-left: 5px;
	}
`

export const StyledHeader = styled.div`
	background: ${({ theme }) => theme.lightGray};
	user-select: none;
	max-width: 100%;

	@media print {
		display: none;
	}
`
export const StyledNav = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	max-width: 100%;
	/* padding: 10px; */

	@media (max-width: ${({ theme }) => theme.smallScreen}) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`
export const HeaderLeft = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		justify-content: space-between;
	}
`

export const LogoCenter = styled.div`
	@media (max-width: ${({ theme }) => theme.mobile}) {
		justify-content: space-between;
		margin-left: 50px;
	}

	@media (min-width: ${({ theme }) => theme.mobile}) {
		display: none;
	}
`

export const TitanTVLogo = styled.img`
	margin-left: 20px;
	height: 45px;
	width: 175px;
`

export const TitanTVLogoLink = styled(Link)`
	z-index: 15;
`

export const HeaderRight = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 7px;
	padding-top: 7px;
	font-size: 15px;
	min-width: 120px;
	
	@media (max-width: ${({ theme }) => theme.mobile}) {
		font-size: 20px;
		align-self: center;
		margin-right: 0;
	}
`

export const HeaderLink = styled(Link)`
	margin-left: 5px;
	color: black;
	text-decoration: underline;
	cursor: pointer;
	font-size: 14px;
	display: inline-block;
`

export const HeaderLinkWithoutTo = styled.span`
	margin-left: 5px;
	color: black;
	text-decoration: underline;
	cursor: pointer;
	font-size: 14px;
`

export const StyledSearchBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: ${({ theme }) => theme.lightBlue};
	/* padding: 15px; */
	min-height: 110px;
	margin-top: 100px;
	padding: 10px 0;
	height: fit-content;

	@media (max-width: 1170px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 190px;
		padding-bottom: 7px;
	}
`

export const SearchImgContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	@media (max-width: ${({ theme }) => theme.mobile}) {
		justify-content: space-around;
		margin-top: 50px;
	}
`

export const SearchImg = styled.img`
	padding: 0 5px;
`

export const SearchInputWithButton = styled(TextInputWithButton)`
	margin-right: 15px;

	img {
		&:active {
			opacity: 0.75;
			transform: scale(0.98);
			box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
		}
	}
`

export const TextInput = styled.input`
	/* max-width: 350px; */
	width: 180px;
	border-radius: 5px;
	padding: 10px;
	margin-right: 20px;
	border: 1px solid lightgray;
	border-radius: 5px;
	margin: 7px 0;
`

export const SearchOptions = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-right: 10px;
`

export const OptionsButton = styled.img`
	height: 20px;
	width: 20px;
	margin-left: 10px;
	align-self: center;
	cursor: pointer;
`