import { useLocation } from 'react-router-dom'
import { StyledTabBar, Tab } from '../../UserSettingsPages/components/SettingsStyles'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectModalOpen, setModalOpen } from '../../Modal/modalSlice';

const OtherTabBar = () => {

	const path = useLocation().pathname;
	const modalOpen = useAppSelector(selectModalOpen);
	const dispatch = useAppDispatch()

	if (modalOpen) {
		dispatch(setModalOpen());
	}

	return (
		<StyledTabBar>
			<Tab to='/about' $current={path === "/about" ? true : false} dvr={false}>About Us</Tab>
			<Tab to='/contact' $current={path === "/contact" ? true : false} dvr={false}>Contact Us</Tab>
			<Tab to='/relatedlinks' $current={path === "/relatedlinks" ? true : false} dvr={false}>Related Links</Tab>
		</StyledTabBar>
	)
}

export default OtherTabBar