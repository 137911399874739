import { EventCard } from './GridStyles'
import { Event } from '../../../interfaces/schedule'

// #region Genre Pics imports
import Animated from '../../../img/Genre Placeholders/Animated.png'
import Children from '../../../img/Genre Placeholders/Children.png'
import Comedy from '../../../img/Genre Placeholders/Comedy.png'
import Documentary from '../../../img/Genre Placeholders/Documentary.png'
import Drama from '../../../img/Genre Placeholders/Drama.png'
import Education from '../../../img/Genre Placeholders/Education.png'
import Entertainment from '../../../img/Genre Placeholders/Entertainment.png'
import Food from '../../../img/Genre Placeholders/Food.png'
import Game from '../../../img/Genre Placeholders/Game.png'
import Health from '../../../img/Genre Placeholders/Health.png'
import Home from '../../../img/Genre Placeholders/Home.png'
import Information from '../../../img/Genre Placeholders/Information.png'
import Interview from '../../../img/Genre Placeholders/Interview.png'
import Music from '../../../img/Genre Placeholders/Music.png'
import Nature from '../../../img/Genre Placeholders/Nature.png'
import News from '../../../img/Genre Placeholders/News.png'
import Other from '../../../img/Genre Placeholders/Other.png'
import Religious from '../../../img/Genre Placeholders/Religious.png'
import SciFI from '../../../img/Genre Placeholders/SciFI.png'
import Sports from '../../../img/Genre Placeholders/Sports.png'
import Travel from '../../../img/Genre Placeholders/Travel.png'
import True from '../../../img/Genre Placeholders/True.png'
// #endregion

const EventShowCard = (props: any) => {
	const event: Event = props.Event

	const pickGenreImg = (): string => {
		switch (event.displayGenre) {
			case 'Animated':
				return Animated
			case 'Children':
				return Children
			case 'Comedy':
				return Comedy
			case 'Documentary':
				return Documentary
			case 'Drama':
				return Drama
			case 'Education':
				return Education
			case 'Entertainment':
				return Entertainment
			case 'Food':
				return Food
			case 'Game/Quiz':
				return Game
			case 'Health':
				return Health
			case 'Home':
				return Home
			case 'News':
				return News
			case 'Information':
				return Information
			case 'Interview':
				return Interview
			case 'Music':
				return Music
			case 'Nature':
				return Nature
			case 'Religious':
				return Religious
			case 'Sci-Fi':
				return SciFI
			case 'Sports':
				return Sports
			case 'Travel':
				return Travel
			case 'True':
				return True
			default:
				return Other
		}
	}

	return (
		<EventCard src={event.showCard} onError={({ currentTarget }) => {
			currentTarget.onerror = null;
			currentTarget.src = pickGenreImg();
		}} />
	)
}

export default EventShowCard