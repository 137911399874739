import {
	ModalTitleBar, ModalContent, ModalBlueButton, ModalIconSM, ModalHeader, ModalPulldown, CheckBoxContainer,
	ModalContainer, WhiteClose, GridFilterGenreOption
} from "../ModalStyles";
import { CheckBox } from '../../Global/GobalStyledComponents'
import { setModalOpen } from "../modalSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import {
	selectMoviesDisplay, selectMyFavoritesDisplay, selectNewProgramDisplay, selectGenreFilter, setFilterDefault, setFilters,
	setGenreDisplay, setNewProgramDisplay, setMyFavoritesDisplay, setMoviesDisplay
} from "../filterDisplaySlice";
import { SaveButtonContainer } from "../../UserSettingsPages/components/SettingsStyles";
import { selectColors } from "../../GridPages/gridColorsSlice";


const FilterDisplay = () => {
	const genre = useAppSelector(selectGenreFilter);
	const displayFav = useAppSelector(selectMyFavoritesDisplay)
	const displayNewProg = useAppSelector(selectNewProgramDisplay);
	const displayMovie = useAppSelector(selectMoviesDisplay);
	const { GridColors } = useAppSelector(selectColors);

	const dispatch = useAppDispatch();

	const handlePulldownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		dispatch(setGenreDisplay(e.target.value));
	}

	const applyFilters = () => {
		dispatch(setFilters({ genre, displayFav, displayNewProg, displayMovie }))
		dispatch(setModalOpen());
	}

	const resetFilters = () => {
		dispatch(setFilterDefault());
		dispatch(setModalOpen());
	}

	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				<WhiteClose onClick={() => dispatch(setModalOpen())}>
					Close <ModalIconSM src={Close} alt="" />
				</WhiteClose>
			</ModalTitleBar>
			<ModalContent>
				<ModalHeader>Select Listing Display Options</ModalHeader>
				<ModalPulldown value={genre} onChange={(e) => handlePulldownChange(e)}>
					{/* <option value='all' disabled>Select Genre</option> */}
					<GridFilterGenreOption color={'white'} value='all'>All</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Animated} value='Animated'>Animated</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Children} value='Children'>Children</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Comedy} value='Comedy'>Comedy</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Documentary} value='Documentry'>Documentry</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Drama} value='Drama'>Drama</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Education} value='Education'>Education</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Education} value='Entertainment'>Entertainment</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Food} value='Food'>Food</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Game_Quiz} value='Game/Quiz'>Game/Quiz</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Health} value='Health'>Health</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Home} value='Home'>Home</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Information} value='Information'>Information</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Interview} value='Interview'>Interview</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Music} value='Music'>Music</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Nature} value='Nature'>Nature</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.News} value='News'>News</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Other} value='Other'>Other</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Religious} value='Religious'>Religious</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Science} value='Science'>Science</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.SciFi} value='Sci-Fi'>Sci-Fi</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Sports} value='Sports'>Sports</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.Travel} value='Travel'>Travel</GridFilterGenreOption>
					<GridFilterGenreOption color={GridColors.True} value='True'>True</GridFilterGenreOption>
				</ModalPulldown>
				<CheckBoxContainer>
					<label><CheckBox checked={displayNewProg} onChange={(e) => dispatch(setNewProgramDisplay())} />New Programs</label>
					<label><CheckBox checked={displayFav} onChange={(e) => dispatch(setMyFavoritesDisplay())} />My Favorites</label>
				</CheckBoxContainer>
				<CheckBoxContainer>
					<label><CheckBox checked={displayMovie} onChange={(e) => dispatch(setMoviesDisplay())} />Movies</label>
					{/* <label><CheckBox checked={repeatProg} onChange={() => dispatch(setRepeatProgramDisplay())} />Repeat Programs</label> */}
				</CheckBoxContainer>
				<SaveButtonContainer>
					<ModalBlueButton onClick={() => applyFilters()}>Apply Selections</ModalBlueButton>
					<ModalBlueButton onClick={() => resetFilters()}>Reset Filters</ModalBlueButton>
				</SaveButtonContainer>
			</ModalContent>
		</ModalContainer>
	)
}

export default FilterDisplay