import { useEffect } from 'react'
import FaqsHelpHeader from './FaqsHelpHeader'
import {
	FAQAccordion, FAQContainer, FAQHeader
} from './FAQHelpStyles'
import FAQGeneral from './Faqs Sections/FAQGeneral'
import FAQMembership from './Faqs Sections/FAQMembership'
import FAQLineup from './Faqs Sections/FAQLineup'

const FAQs = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	})

	return (
		<>
			<FaqsHelpHeader />
			<FAQContainer>
				<FAQHeader>
					Frequently Asked Questions
				</FAQHeader>
				<FAQAccordion>
					<FAQGeneral />
					<FAQMembership />
					<FAQLineup />
				</FAQAccordion>
			</FAQContainer>
		</>
	)
}

export default FAQs