import React, { useEffect, useState } from 'react'
import {
	ModalBlueButton, ModalHeader, ModalIconSM, ModalTextEntry, WhiteClose, ModalContainer, ModalTitleBar, ModalContent,
	EditLineupLabel
} from '../ModalStyles'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setModalOpen } from '../modalSlice'
import Close from '../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import { DataUpdated } from '../../UserSettingsPages/components/SettingsStyles';
import { selectIsAnonymous, selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import { fetchUserLineups, putTokenLineup, selectLineups, selectNewLineupStatus, setNewLineupStatus } from '../../UserSettingsPages/SettingsState/lineupsSlice';
import { TooManyLineups } from '../../Global/GobalFunctions';
import { DataState } from '../../../interfaces/enums';
import Loading from '../../Global/Loading';

const ModalTokenEnter = () => {
	const [lineupToken, setLineupToken] = useState('');
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const userLineups = useAppSelector(selectLineups);
	const dispatch = useAppDispatch();
	const userId = useAppSelector(selectUserId);
	const isAnon = useAppSelector(selectIsAnonymous);
	const newLineupStatus = useAppSelector(selectNewLineupStatus)

	const handleAddLineup = () => {
		if (lineupToken === '') {
			setShowError(true);
			setErrorMessage('You must name the lineup')
			return;
		}

		// if (!TooManyLineups(userLineups.lineups.length, isAnon)) {
		// 	setShowError(true);
		// 	setErrorMessage('You have too many lineups, delete one or more to add this one')
		// 	return;
		// }

		setShowError(false);
		dispatch(putTokenLineup({ userId, lineupToken }))
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleAddLineup();
		}
	}

	const handleClose = () => {
		setLineupToken('');
		setShowError(false);
		dispatch(setModalOpen())
		dispatch(setNewLineupStatus(DataState.IDLE))
	}

	if (newLineupStatus === DataState.SUCCEEDED) {
		dispatch(fetchUserLineups(userId));
		handleClose();
	}

	useEffect(() => {
		dispatch(setNewLineupStatus(DataState.IDLE))

		return (() => {
			setLineupToken('');
			setShowError(false);
		})
	}, [])

	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				<WhiteClose onClick={() => handleClose()}>
					<ModalIconSM src={Close} alt='Close' /> Close
				</WhiteClose>
			</ModalTitleBar>
			{newLineupStatus === DataState.LOADING ?
				<ModalContent>
					<Loading />
				</ModalContent>
				:
				<ModalContent>
					<ModalHeader>Create a Linup with a Token</ModalHeader>
					<br />
					<div>This will create a new lineup based on one created by another user</div>
					<br />
					<EditLineupLabel htmlFor='lineuToken'>Lineup Token</EditLineupLabel>
					<ModalTextEntry id='lineuToken' value={lineupToken} onChange={(e) => setLineupToken(e.target.value)} onKeyDown={((e) => handleKeyDown(e))} autoFocus />
					{showError ? <DataUpdated>{errorMessage}</DataUpdated> : ''}
					<ModalBlueButton onClick={() => handleAddLineup()}>Add Lineup</ModalBlueButton>
				</ModalContent>
			}
		</ModalContainer>
	)
}

export default ModalTokenEnter