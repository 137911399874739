import {
	ModalTitleBar, ModalContent, ModalHeader, ModalTextEntry, ModalContainer, ModalBlueButton, ModalMainContent
} from "../ModalStyles";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { checkEmailValid } from "../../Global/GobalFunctions";
import { useEffect, useState } from "react";
import { DataError } from "../../UserSettingsPages/components/SettingsStyles";
import { postUsernameRecovery, selectUserError, selectUserErrorCode, selectUsernameRecoveryStatus, setUsernameRecoveryStatus } from "../../UserSettingsPages/SettingsState/userSlice";
import { DataState } from "../../../interfaces/enums";
import GridLoading from "../../GridPages/components/GridLoading";
import GridFailed from "../../GridPages/components/GridFailed";
import EmailSent from "./EmailSent";
import CloseButtonNewUser from "./CloseButtonNewUser";

const LoginNameRecovery = () => {

	const dispatch = useAppDispatch();
	const status = useAppSelector(selectUsernameRecoveryStatus);
	const userErrorMessage = useAppSelector(selectUserError);
	const userErrorCode = useAppSelector(selectUserErrorCode);
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('')

	const handleReset = () => {
		if (checkEmailValid(email)) {
			setErrorMessage('');
			dispatch(postUsernameRecovery(email));
		} else {
			setErrorMessage('Not a valid email');
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleReset();
		}
	}

	useEffect(() => {
		return (() => {
			setErrorMessage('');
			dispatch(setUsernameRecoveryStatus(DataState.IDLE))
		})
	}, []);

	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				<CloseButtonNewUser />
			</ModalTitleBar>
			{status === DataState.IDLE ?
				<ModalContent>
					<ModalHeader>User Name Recovery</ModalHeader>
					<ModalMainContent>
						Enter Your account email to get your username sent to you.
					</ModalMainContent>
					<DataError>{errorMessage}</DataError>
					<ModalTextEntry placeholder="Email" onChange={(e) => setEmail(e.target.value)} onKeyDown={((e) => handleKeyDown(e))} />
					<ModalBlueButton onClick={() => handleReset()}>Email Username</ModalBlueButton>
				</ModalContent>
				: status === DataState.LOADING ? <ModalContent><GridLoading /></ModalContent>
					: status === DataState.FAILED ? <ModalContent><GridFailed errorMessage={userErrorMessage} errorCode={userErrorCode} /></ModalContent>
						: <ModalContent><EmailSent /></ModalContent>}
		</ModalContainer>
	)
}

export default LoginNameRecovery